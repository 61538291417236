var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}("undefined" != typeof self ? self : exports, function () {
  return function (n) {
    var o = {};

    function r(e) {
      if (o[e]) return o[e].exports;
      var t = o[e] = {
        i: e,
        l: !1,
        exports: {}
      };
      return n[e].call(t.exports, t, t.exports, r), t.l = !0, t.exports;
    }

    return r.m = n, r.c = o, r.d = function (e, t, n) {
      r.o(e, t) || Object.defineProperty(e, t, {
        configurable: !1,
        enumerable: !0,
        get: n
      });
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "", r(r.s = 196);
  }([function (e, t, n) {
    var h = n(115),
        m = 3600000,
        p = 60000,
        v = /[T ]/,
        g = /:/,
        _ = /^(\d{2})$/,
        y = [/^([+-]\d{2})$/, /^([+-]\d{3})$/, /^([+-]\d{4})$/],
        b = /^(\d{4})/,
        x = [/^([+-]\d{4})/, /^([+-]\d{5})/, /^([+-]\d{6})/],
        M = /^-(\d{2})$/,
        k = /^-?(\d{3})$/,
        w = /^-?(\d{2})-?(\d{2})$/,
        D = /^-?W(\d{2})$/,
        S = /^-?W(\d{2})-?(\d{1})$/,
        j = /^(\d{2}([.,]\d*)?)$/,
        E = /^(\d{2}):?(\d{2}([.,]\d*)?)$/,
        A = /^(\d{2}):?(\d{2}):?(\d{2}([.,]\d*)?)$/,
        T = /([Z+-].*)$/,
        O = /^(Z)$/,
        Y = /^([+-])(\d{2})$/,
        H = /^([+-])(\d{2}):?(\d{2})$/;

    function X(e, t, n) {
      t = t || 0, n = n || 0;
      var o = new Date(0);
      o.setUTCFullYear(e, 0, 4);
      var r = 7 * t + n + 1 - (o.getUTCDay() || 7);
      return o.setUTCDate(o.getUTCDate() + r), o;
    }

    e.exports = function (e, t) {
      if (h(e)) return new Date(e.getTime());
      if ("string" != typeof e) return new Date(e);
      var n = (t || {}).additionalDigits;
      n = null == n ? 2 : Number(n);

      var o = function (e) {
        var t,
            n = {},
            o = e.split(v);

        if (g.test(o[0]) ? (n.date = null, t = o[0]) : (n.date = o[0], t = o[1]), t) {
          var r = T.exec(t);
          r ? (n.time = t.replace(r[1], ""), n.timezone = r[1]) : n.time = t;
        }

        return n;
      }(e),
          r = function (e, t) {
        var n,
            o = y[t],
            r = x[t];

        if (n = b.exec(e) || r.exec(e)) {
          var i = n[1];
          return {
            year: parseInt(i, 10),
            restDateString: e.slice(i.length)
          };
        }

        if (n = _.exec(e) || o.exec(e)) {
          var a = n[1];
          return {
            year: 100 * parseInt(a, 10),
            restDateString: e.slice(a.length)
          };
        }

        return {
          year: null
        };
      }(o.date, n),
          i = r.year,
          a = function (e, t) {
        if (null === t) return null;
        var n, o, r, i;
        if (0 === e.length) return (o = new Date(0)).setUTCFullYear(t), o;
        if (n = M.exec(e)) return o = new Date(0), r = parseInt(n[1], 10) - 1, o.setUTCFullYear(t, r), o;

        if (n = k.exec(e)) {
          o = new Date(0);
          var a = parseInt(n[1], 10);
          return o.setUTCFullYear(t, 0, a), o;
        }

        if (n = w.exec(e)) {
          o = new Date(0), r = parseInt(n[1], 10) - 1;
          var s = parseInt(n[2], 10);
          return o.setUTCFullYear(t, r, s), o;
        }

        if (n = D.exec(e)) return i = parseInt(n[1], 10) - 1, X(t, i);

        if (n = S.exec(e)) {
          i = parseInt(n[1], 10) - 1;
          var u = parseInt(n[2], 10) - 1;
          return X(t, i, u);
        }

        return null;
      }(r.restDateString, i);

      if (a) {
        var s,
            u = a.getTime(),
            c = 0;
        return o.time && (c = function (e) {
          var t, n, o;
          if (t = j.exec(e)) return (n = parseFloat(t[1].replace(",", "."))) % 24 * m;
          if (t = E.exec(e)) return n = parseInt(t[1], 10), o = parseFloat(t[2].replace(",", ".")), n % 24 * m + o * p;

          if (t = A.exec(e)) {
            n = parseInt(t[1], 10), o = parseInt(t[2], 10);
            var r = parseFloat(t[3].replace(",", "."));
            return n % 24 * m + o * p + 1000 * r;
          }

          return null;
        }(o.time)), o.timezone ? (l = o.timezone, s = (d = O.exec(l)) ? 0 : (d = Y.exec(l)) ? (f = 60 * parseInt(d[2], 10), "+" === d[1] ? -f : f) : (d = H.exec(l)) ? (f = 60 * parseInt(d[2], 10) + parseInt(d[3], 10), "+" === d[1] ? -f : f) : 0) : (s = new Date(u + c).getTimezoneOffset(), s = new Date(u + c + s * p).getTimezoneOffset()), new Date(u + c + s * p);
      }

      var l, d, f;
      return new Date(e);
    };
  }, function (e, t) {
    var r = ["M", "MM", "Q", "D", "DD", "DDD", "DDDD", "d", "E", "W", "WW", "YY", "YYYY", "GG", "GGGG", "H", "HH", "h", "hh", "m", "mm", "s", "ss", "S", "SS", "SSS", "Z", "ZZ", "X", "x"];

    e.exports = function (e) {
      var t = [];

      for (var n in e) e.hasOwnProperty(n) && t.push(n);

      var o = r.concat(t).sort().reverse();
      return new RegExp("(\\[[^\\[]*\\])|(\\\\)?(" + o.join("|") + "|.)", "g");
    };
  }, function (e, t, n) {
    var s = n(0),
        u = n(3);

    e.exports = function (e) {
      var t = s(e),
          n = t.getFullYear(),
          o = new Date(0);
      o.setFullYear(n + 1, 0, 4), o.setHours(0, 0, 0, 0);
      var r = u(o),
          i = new Date(0);
      i.setFullYear(n, 0, 4), i.setHours(0, 0, 0, 0);
      var a = u(i);
      return t.getTime() >= r.getTime() ? n + 1 : t.getTime() >= a.getTime() ? n : n - 1;
    };
  }, function (e, t, n) {
    var o = n(78);

    e.exports = function (e) {
      return o(e, {
        weekStartsOn: 1
      });
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setHours(0, 0, 0, 0), t;
    };
  }, function (e, t, n) {
    var o = n(10),
        r = n(11);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setDate(n.getDate() + o), n;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e).getTime(),
          o = Number(t);
      return new Date(n + o);
    };
  }, function (e, t, n) {
    var o = n(2),
        r = n(3);

    e.exports = function (e) {
      var t = o(e),
          n = new Date(0);
      return n.setFullYear(t, 0, 4), n.setHours(0, 0, 0, 0), r(n);
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e).getTime(),
          o = r(t).getTime();
      return n < o ? -1 : o < n ? 1 : 0;
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "less than a second",
          other: "less than {{count}} seconds"
        },
        xSeconds: {
          one: "1 second",
          other: "{{count}} seconds"
        },
        halfAMinute: "half a minute",
        lessThanXMinutes: {
          one: "less than a minute",
          other: "less than {{count}} minutes"
        },
        xMinutes: {
          one: "1 minute",
          other: "{{count}} minutes"
        },
        aboutXHours: {
          one: "about 1 hour",
          other: "about {{count}} hours"
        },
        xHours: {
          one: "1 hour",
          other: "{{count}} hours"
        },
        xDays: {
          one: "1 day",
          other: "{{count}} days"
        },
        aboutXMonths: {
          one: "about 1 month",
          other: "about {{count}} months"
        },
        xMonths: {
          one: "1 month",
          other: "{{count}} months"
        },
        aboutXYears: {
          one: "about 1 year",
          other: "about {{count}} years"
        },
        xYears: {
          one: "1 year",
          other: "{{count}} years"
        },
        overXYears: {
          one: "over 1 year",
          other: "over {{count}} years"
        },
        almostXYears: {
          one: "almost 1 year",
          other: "almost {{count}} years"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "in " + o : o + " ago" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          n = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          o = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          r = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          i = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return function (e) {
            var t = e % 100;
            if (20 < t || t < 10) switch (t % 10) {
              case 1:
                return e + "st";

              case 2:
                return e + "nd";

              case 3:
                return e + "rd";
            }
            return e + "th";
          }(t[n](e));
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "\u0623\u0642\u0644 \u0645\u0646 \u062B\u0627\u0646\u064A\u0629 \u0648\u0627\u062D\u062F\u0629",
          other: "\u0623\u0642\u0644 \u0645\u0646 {{count}} \u062B\u0648\u0627\u0646\u064A"
        },
        xSeconds: {
          one: "\u062B\u0627\u0646\u064A\u0629 \u0648\u0627\u062D\u062F\u0629",
          other: "{{count}} \u062B\u0648\u0627\u0646\u064A"
        },
        halfAMinute: "\u0646\u0635\u0641 \u062F\u0642\u064A\u0642\u0629",
        lessThanXMinutes: {
          one: "\u0623\u0642\u0644 \u0645\u0646 \u062F\u0642\u064A\u0642\u0629",
          other: "\u0623\u0642\u0644 \u0645\u0646 {{count}} \u062F\u0642\u064A\u0642\u0629"
        },
        xMinutes: {
          one: "\u062F\u0642\u064A\u0642\u0629 \u0648\u0627\u062D\u062F\u0629",
          other: "{{count}} \u062F\u0642\u0627\u0626\u0642"
        },
        aboutXHours: {
          one: "\u0633\u0627\u0639\u0629 \u0648\u0627\u062D\u062F\u0629 \u062A\u0642\u0631\u064A\u0628\u0627\u064B",
          other: "{{count}} \u0633\u0627\u0639\u0627\u062A \u062A\u0642\u0631\u064A\u0628\u0627\u064B"
        },
        xHours: {
          one: "\u0633\u0627\u0639\u0629 \u0648\u0627\u062D\u062F\u0629",
          other: "{{count}} \u0633\u0627\u0639\u0627\u062A"
        },
        xDays: {
          one: "\u064A\u0648\u0645 \u0648\u0627\u062D\u062F",
          other: "{{count}} \u0623\u064A\u0627\u0645"
        },
        aboutXMonths: {
          one: "\u0634\u0647\u0631 \u0648\u0627\u062D\u062F \u062A\u0642\u0631\u064A\u0628\u0627\u064B",
          other: "{{count}} \u0623\u0634\u0647\u0631 \u062A\u0642\u0631\u064A\u0628\u0627\u064B"
        },
        xMonths: {
          one: "\u0634\u0647\u0631 \u0648\u0627\u062D\u062F",
          other: "{{count}} \u0623\u0634\u0647\u0631"
        },
        aboutXYears: {
          one: "\u0639\u0627\u0645 \u0648\u0627\u062D\u062F \u062A\u0642\u0631\u064A\u0628\u0627\u064B",
          other: "{{count}} \u0623\u0639\u0648\u0627\u0645 \u062A\u0642\u0631\u064A\u0628\u0627\u064B"
        },
        xYears: {
          one: "\u0639\u0627\u0645 \u0648\u0627\u062D\u062F",
          other: "{{count}} \u0623\u0639\u0648\u0627\u0645"
        },
        overXYears: {
          one: "\u0623\u0643\u062B\u0631 \u0645\u0646 \u0639\u0627\u0645",
          other: "\u0623\u0643\u062B\u0631 \u0645\u0646 {{count}} \u0623\u0639\u0648\u0627\u0645"
        },
        almostXYears: {
          one: "\u0639\u0627\u0645 \u0648\u0627\u062D\u062F \u062A\u0642\u0631\u064A\u0628\u0627\u064B",
          other: "{{count}} \u0623\u0639\u0648\u0627\u0645 \u062A\u0642\u0631\u064A\u0628\u0627\u064B"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "\u0641\u064A \u062E\u0644\u0627\u0644 " + o : "\u0645\u0646\u0630 " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["\u064A\u0646\u0627\u064A\u0631", "\u0641\u0628\u0631\u0627\u064A\u0631", "\u0645\u0627\u0631\u0633", "\u0623\u0628\u0631\u064A\u0644", "\u0645\u0627\u064A\u0648", "\u064A\u0648\u0646\u064A\u0648", "\u064A\u0648\u0644\u064A\u0648", "\u0623\u063A\u0633\u0637\u0633", "\u0633\u0628\u062A\u0645\u0628\u0631", "\u0623\u0643\u062A\u0648\u0628\u0631", "\u0646\u0648\u0641\u0645\u0628\u0631", "\u062F\u064A\u0633\u0645\u0628\u0631"],
          n = ["\u0643\u0627\u0646\u0648\u0646 \u0627\u0644\u062B\u0627\u0646\u064A \u064A\u0646\u0627\u064A\u0631", "\u0634\u0628\u0627\u0637 \u0641\u0628\u0631\u0627\u064A\u0631", "\u0622\u0630\u0627\u0631 \u0645\u0627\u0631\u0633", "\u0646\u064A\u0633\u0627\u0646 \u0623\u0628\u0631\u064A\u0644", "\u0623\u064A\u0627\u0631 \u0645\u0627\u064A\u0648", "\u062D\u0632\u064A\u0631\u0627\u0646 \u064A\u0648\u0646\u064A\u0648", "\u062A\u0645\u0648\u0632 \u064A\u0648\u0644\u064A\u0648", "\u0622\u0628 \u0623\u063A\u0633\u0637\u0633", "\u0623\u064A\u0644\u0648\u0644 \u0633\u0628\u062A\u0645\u0628\u0631", "\u062A\u0634\u0631\u064A\u0646 \u0627\u0644\u0623\u0648\u0644 \u0623\u0643\u062A\u0648\u0628\u0631", "\u062A\u0634\u0631\u064A\u0646 \u0627\u0644\u062B\u0627\u0646\u064A \u0646\u0648\u0641\u0645\u0628\u0631", "\u0643\u0627\u0646\u0648\u0646 \u0627\u0644\u0623\u0648\u0644 \u062F\u064A\u0633\u0645\u0628\u0631"],
          o = ["\u062D", "\u0646", "\u062B", "\u0631", "\u062E", "\u062C", "\u0633"],
          r = ["\u0623\u062D\u062F", "\u0625\u062B\u0646\u064A\u0646", "\u062B\u0644\u0627\u062B\u0627\u0621", "\u0623\u0631\u0628\u0639\u0627\u0621", "\u062E\u0645\u064A\u0633", "\u062C\u0645\u0639\u0629", "\u0633\u0628\u062A"],
          i = ["\u0627\u0644\u0623\u062D\u062F", "\u0627\u0644\u0625\u062B\u0646\u064A\u0646", "\u0627\u0644\u062B\u0644\u0627\u062B\u0627\u0621", "\u0627\u0644\u0623\u0631\u0628\u0639\u0627\u0621", "\u0627\u0644\u062E\u0645\u064A\u0633", "\u0627\u0644\u062C\u0645\u0639\u0629", "\u0627\u0644\u0633\u0628\u062A"],
          a = ["\u0635\u0628\u0627\u062D", "\u0645\u0633\u0627\u0621"],
          s = ["\u0635", "\u0645"],
          u = ["\u0635\u0628\u0627\u062D\u0627\u064B", "\u0645\u0633\u0627\u0621\u0627\u064B"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (o) {
        e[o + "o"] = function (e, t) {
          return n = t[o](e), String(n);
          var n;
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "\u043F\u043E-\u043C\u0430\u043B\u043A\u043E \u043E\u0442 \u0441\u0435\u043A\u0443\u043D\u0434\u0430",
          other: "\u043F\u043E-\u043C\u0430\u043B\u043A\u043E \u043E\u0442 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0438"
        },
        xSeconds: {
          one: "1 \u0441\u0435\u043A\u0443\u043D\u0434\u0430",
          other: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0438"
        },
        halfAMinute: "\u043F\u043E\u043B\u043E\u0432\u0438\u043D \u043C\u0438\u043D\u0443\u0442\u0430",
        lessThanXMinutes: {
          one: "\u043F\u043E-\u043C\u0430\u043B\u043A\u043E \u043E\u0442 \u043C\u0438\u043D\u0443\u0442\u0430",
          other: "\u043F\u043E-\u043C\u0430\u043B\u043A\u043E \u043E\u0442 {{count}} \u043C\u0438\u043D\u0443\u0442\u0438"
        },
        xMinutes: {
          one: "1 \u043C\u0438\u043D\u0443\u0442\u0430",
          other: "{{count}} \u043C\u0438\u043D\u0443\u0442\u0438"
        },
        aboutXHours: {
          one: "\u043E\u043A\u043E\u043B\u043E \u0447\u0430\u0441",
          other: "\u043E\u043A\u043E\u043B\u043E {{count}} \u0447\u0430\u0441\u0430"
        },
        xHours: {
          one: "1 \u0447\u0430\u0441",
          other: "{{count}} \u0447\u0430\u0441\u0430"
        },
        xDays: {
          one: "1 \u0434\u0435\u043D",
          other: "{{count}} \u0434\u043D\u0438"
        },
        aboutXMonths: {
          one: "\u043E\u043A\u043E\u043B\u043E \u043C\u0435\u0441\u0435\u0446",
          other: "\u043E\u043A\u043E\u043B\u043E {{count}} \u043C\u0435\u0441\u0435\u0446\u0430"
        },
        xMonths: {
          one: "1 \u043C\u0435\u0441\u0435\u0446",
          other: "{{count}} \u043C\u0435\u0441\u0435\u0446\u0430"
        },
        aboutXYears: {
          one: "\u043E\u043A\u043E\u043B\u043E \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "\u043E\u043A\u043E\u043B\u043E {{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        },
        xYears: {
          one: "1 \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "{{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        },
        overXYears: {
          one: "\u043D\u0430\u0434 \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "\u043D\u0430\u0434 {{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        },
        almostXYears: {
          one: "\u043F\u043E\u0447\u0442\u0438 \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "\u043F\u043E\u0447\u0442\u0438 {{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "\u0441\u043B\u0435\u0434 " + o : "\u043F\u0440\u0435\u0434\u0438 " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var u = n(1);

    e.exports = function () {
      var t = ["\u044F\u043D\u0443", "\u0444\u0435\u0432", "\u043C\u0430\u0440", "\u0430\u043F\u0440", "\u043C\u0430\u0439", "\u044E\u043D\u0438", "\u044E\u043B\u0438", "\u0430\u0432\u0433", "\u0441\u0435\u043F", "\u043E\u043A\u0442", "\u043D\u043E\u0435", "\u0434\u0435\u043A"],
          n = ["\u044F\u043D\u0443\u0430\u0440\u0438", "\u0444\u0435\u0432\u0440\u0443\u0430\u0440\u0438", "\u043C\u0430\u0440\u0442", "\u0430\u043F\u0440\u0438\u043B", "\u043C\u0430\u0439", "\u044E\u043D\u0438", "\u044E\u043B\u0438", "\u0430\u0432\u0433\u0443\u0441\u0442", "\u0441\u0435\u043F\u0442\u0435\u043C\u0432\u0440\u0438", "\u043E\u043A\u0442\u043E\u043C\u0432\u0440\u0438", "\u043D\u043E\u0435\u043C\u0432\u0440\u0438", "\u0434\u0435\u043A\u0435\u043C\u0432\u0440\u0438"],
          o = ["\u043D\u0434", "\u043F\u043D", "\u0432\u0442", "\u0441\u0440", "\u0447\u0442", "\u043F\u0442", "\u0441\u0431"],
          r = ["\u043D\u0435\u0434", "\u043F\u043E\u043D", "\u0432\u0442\u043E", "\u0441\u0440\u044F", "\u0447\u0435\u0442", "\u043F\u0435\u0442", "\u0441\u044A\u0431"],
          i = ["\u043D\u0435\u0434\u0435\u043B\u044F", "\u043F\u043E\u043D\u0435\u0434\u0435\u043B\u043D\u0438\u043A", "\u0432\u0442\u043E\u0440\u043D\u0438\u043A", "\u0441\u0440\u044F\u0434\u0430", "\u0447\u0435\u0442\u0432\u044A\u0440\u0442\u044A\u043A", "\u043F\u0435\u0442\u044A\u043A", "\u0441\u044A\u0431\u043E\u0442\u0430"],
          a = ["\u0441\u0443\u0442\u0440\u0438\u043D\u0442\u0430", "\u043D\u0430 \u043E\u0431\u044F\u0434", "\u0441\u043B\u0435\u0434\u043E\u0431\u0435\u0434", "\u0432\u0435\u0447\u0435\u0440\u0442\u0430"],
          e = function (e) {
        var t = e.getHours();
        return 4 <= t && t < 12 ? a[0] : 12 <= t && t < 14 ? a[1] : 14 <= t && t < 17 ? a[2] : a[3];
      },
          s = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: e,
        a: e,
        aa: e
      };

      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        s[n + "o"] = function (e, t) {
          return function (e) {
            var t = e % 100;
            if (20 < t || t < 10) switch (t % 10) {
              case 1:
                return e + "-\u0432\u0438";

              case 2:
                return e + "-\u0440\u0438";
            }
            return e + "-\u0438";
          }(t[n](e));
        };
      }), {
        formatters: s,
        formattingTokensRegExp: u(s)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "menys d'un segon",
          other: "menys de {{count}} segons"
        },
        xSeconds: {
          one: "1 segon",
          other: "{{count}} segons"
        },
        halfAMinute: "mig minut",
        lessThanXMinutes: {
          one: "menys d'un minut",
          other: "menys de {{count}} minuts"
        },
        xMinutes: {
          one: "1 minut",
          other: "{{count}} minuts"
        },
        aboutXHours: {
          one: "aproximadament una hora",
          other: "aproximadament {{count}} hores"
        },
        xHours: {
          one: "1 hora",
          other: "{{count}} hores"
        },
        xDays: {
          one: "1 dia",
          other: "{{count}} dies"
        },
        aboutXMonths: {
          one: "aproximadament un mes",
          other: "aproximadament {{count}} mesos"
        },
        xMonths: {
          one: "1 mes",
          other: "{{count}} mesos"
        },
        aboutXYears: {
          one: "aproximadament un any",
          other: "aproximadament {{count}} anys"
        },
        xYears: {
          one: "1 any",
          other: "{{count}} anys"
        },
        overXYears: {
          one: "m\xE9s d'un any",
          other: "m\xE9s de {{count}} anys"
        },
        almostXYears: {
          one: "gaireb\xE9 un any",
          other: "gaireb\xE9 {{count}} anys"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "en " + o : "fa " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["gen", "feb", "mar", "abr", "mai", "jun", "jul", "ago", "set", "oct", "nov", "des"],
          n = ["gener", "febrer", "mar\xE7", "abril", "maig", "juny", "juliol", "agost", "setembre", "octobre", "novembre", "desembre"],
          o = ["dg", "dl", "dt", "dc", "dj", "dv", "ds"],
          r = ["dge", "dls", "dts", "dcs", "djs", "dvs", "dss"],
          i = ["diumenge", "dilluns", "dimarts", "dimecres", "dijous", "divendres", "dissabte"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return function (e) {
            switch (e) {
              case 1:
                return "1r";

              case 2:
                return "2n";

              case 3:
                return "3r";

              case 4:
                return "4t";

              default:
                return e + "\xE8";
            }
          }(t[n](e));
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    function c(e, t, n) {
      var o,
          r,
          i = (o = e, 1 === (r = t) ? o.one : 2 <= r && r <= 4 ? o.twoFour : o.other);
      return (i[n] || i).replace("{{count}}", t);
    }

    function l(e) {
      var t = "";
      return "almost" === e && (t = "skoro"), "about" === e && (t = "p\u0159ibli\u017En\u011B"), 0 < t.length ? t + " " : "";
    }

    function d(e) {
      var t = "";
      return "lessThan" === e && (t = "m\xE9n\u011B ne\u017E"), "over" === e && (t = "v\xEDce ne\u017E"), 0 < t.length ? t + " " : "";
    }

    e.exports = function () {
      var u = {
        xSeconds: {
          one: {
            regular: "vte\u0159ina",
            past: "vte\u0159inou",
            future: "vte\u0159inu"
          },
          twoFour: {
            regular: "{{count}} vte\u0159iny",
            past: "{{count}} vte\u0159inami",
            future: "{{count}} vte\u0159iny"
          },
          other: {
            regular: "{{count}} vte\u0159in",
            past: "{{count}} vte\u0159inami",
            future: "{{count}} vte\u0159in"
          }
        },
        halfAMinute: {
          other: {
            regular: "p\u016Fl minuty",
            past: "p\u016Fl minutou",
            future: "p\u016Fl minuty"
          }
        },
        xMinutes: {
          one: {
            regular: "minuta",
            past: "minutou",
            future: "minutu"
          },
          twoFour: {
            regular: "{{count}} minuty",
            past: "{{count}} minutami",
            future: "{{count}} minuty"
          },
          other: {
            regular: "{{count}} minut",
            past: "{{count}} minutami",
            future: "{{count}} minut"
          }
        },
        xHours: {
          one: {
            regular: "hodina",
            past: "hodinou",
            future: "hodinu"
          },
          twoFour: {
            regular: "{{count}} hodiny",
            past: "{{count}} hodinami",
            future: "{{count}} hodiny"
          },
          other: {
            regular: "{{count}} hodin",
            past: "{{count}} hodinami",
            future: "{{count}} hodin"
          }
        },
        xDays: {
          one: {
            regular: "den",
            past: "dnem",
            future: "den"
          },
          twoFour: {
            regular: "{{count}} dni",
            past: "{{count}} dny",
            future: "{{count}} dni"
          },
          other: {
            regular: "{{count}} dn\xED",
            past: "{{count}} dny",
            future: "{{count}} dn\xED"
          }
        },
        xMonths: {
          one: {
            regular: "m\u011Bs\xEDc",
            past: "m\u011Bs\xEDcem",
            future: "m\u011Bs\xEDc"
          },
          twoFour: {
            regular: "{{count}} m\u011Bs\xEDce",
            past: "{{count}} m\u011Bs\xEDci",
            future: "{{count}} m\u011Bs\xEDce"
          },
          other: {
            regular: "{{count}} m\u011Bs\xEDc\u016F",
            past: "{{count}} m\u011Bs\xEDci",
            future: "{{count}} m\u011Bs\xEDc\u016F"
          }
        },
        xYears: {
          one: {
            regular: "rok",
            past: "rokem",
            future: "rok"
          },
          twoFour: {
            regular: "{{count}} roky",
            past: "{{count}} roky",
            future: "{{count}} roky"
          },
          other: {
            regular: "{{count}} rok\u016F",
            past: "{{count}} roky",
            future: "{{count}} rok\u016F"
          }
        }
      };
      return {
        localize: function (e, t, n) {
          n = n || {};
          var o,
              r,
              i = (o = e, ["lessThan", "about", "over", "almost"].filter(function (e) {
            return !!o.match(new RegExp("^" + e));
          })[0] || ""),
              a = (r = e.substring(i.length)).charAt(0).toLowerCase() + r.slice(1),
              s = u[a];
          return n.addSuffix ? 0 < n.comparison ? l(i) + "za " + d(i) + c(s, t, "future") : l(i) + "p\u0159ed " + d(i) + c(s, t, "past") : l(i) + d(i) + c(s, t, "regular");
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["led", "\xFAno", "b\u0159e", "dub", "kv\u011B", "\u010Dvn", "\u010Dvc", "srp", "z\xE1\u0159", "\u0159\xEDj", "lis", "pro"],
          n = ["leden", "\xFAnor", "b\u0159ezen", "duben", "kv\u011Bten", "\u010Derven", "\u010Dervenec", "srpen", "z\xE1\u0159\xED", "\u0159\xEDjen", "listopad", "prosinec"],
          o = ["ne", "po", "\xFAt", "st", "\u010Dt", "p\xE1", "so"],
          r = ["ned", "pon", "\xFAte", "st\u0159", "\u010Dtv", "p\xE1t", "sob"],
          i = ["ned\u011Ble", "pond\u011Bl\xED", "\xFAter\xFD", "st\u0159eda", "\u010Dtvrtek", "p\xE1tek", "sobota"],
          a = ["DOP.", "ODP."],
          s = ["dop.", "odp."],
          u = ["dopoledne", "odpoledne"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + ".";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "mindre end et sekund",
          other: "mindre end {{count}} sekunder"
        },
        xSeconds: {
          one: "1 sekund",
          other: "{{count}} sekunder"
        },
        halfAMinute: "et halvt minut",
        lessThanXMinutes: {
          one: "mindre end et minut",
          other: "mindre end {{count}} minutter"
        },
        xMinutes: {
          one: "1 minut",
          other: "{{count}} minutter"
        },
        aboutXHours: {
          one: "cirka 1 time",
          other: "cirka {{count}} timer"
        },
        xHours: {
          one: "1 time",
          other: "{{count}} timer"
        },
        xDays: {
          one: "1 dag",
          other: "{{count}} dage"
        },
        aboutXMonths: {
          one: "cirka 1 m\xE5ned",
          other: "cirka {{count}} m\xE5neder"
        },
        xMonths: {
          one: "1 m\xE5ned",
          other: "{{count}} m\xE5neder"
        },
        aboutXYears: {
          one: "cirka 1 \xE5r",
          other: "cirka {{count}} \xE5r"
        },
        xYears: {
          one: "1 \xE5r",
          other: "{{count}} \xE5r"
        },
        overXYears: {
          one: "over 1 \xE5r",
          other: "over {{count}} \xE5r"
        },
        almostXYears: {
          one: "n\xE6sten 1 \xE5r",
          other: "n\xE6sten {{count}} \xE5r"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "om " + o : o + " siden" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
          n = ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"],
          o = ["s\xF8", "ma", "ti", "on", "to", "fr", "l\xF8"],
          r = ["s\xF8n", "man", "tir", "ons", "tor", "fre", "l\xF8r"],
          i = ["s\xF8ndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "l\xF8rdag"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + ".";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var i = {
        lessThanXSeconds: {
          standalone: {
            one: "weniger als eine Sekunde",
            other: "weniger als {{count}} Sekunden"
          },
          withPreposition: {
            one: "weniger als einer Sekunde",
            other: "weniger als {{count}} Sekunden"
          }
        },
        xSeconds: {
          standalone: {
            one: "eine Sekunde",
            other: "{{count}} Sekunden"
          },
          withPreposition: {
            one: "einer Sekunde",
            other: "{{count}} Sekunden"
          }
        },
        halfAMinute: {
          standalone: "eine halbe Minute",
          withPreposition: "einer halben Minute"
        },
        lessThanXMinutes: {
          standalone: {
            one: "weniger als eine Minute",
            other: "weniger als {{count}} Minuten"
          },
          withPreposition: {
            one: "weniger als einer Minute",
            other: "weniger als {{count}} Minuten"
          }
        },
        xMinutes: {
          standalone: {
            one: "eine Minute",
            other: "{{count}} Minuten"
          },
          withPreposition: {
            one: "einer Minute",
            other: "{{count}} Minuten"
          }
        },
        aboutXHours: {
          standalone: {
            one: "etwa eine Stunde",
            other: "etwa {{count}} Stunden"
          },
          withPreposition: {
            one: "etwa einer Stunde",
            other: "etwa {{count}} Stunden"
          }
        },
        xHours: {
          standalone: {
            one: "eine Stunde",
            other: "{{count}} Stunden"
          },
          withPreposition: {
            one: "einer Stunde",
            other: "{{count}} Stunden"
          }
        },
        xDays: {
          standalone: {
            one: "ein Tag",
            other: "{{count}} Tage"
          },
          withPreposition: {
            one: "einem Tag",
            other: "{{count}} Tagen"
          }
        },
        aboutXMonths: {
          standalone: {
            one: "etwa ein Monat",
            other: "etwa {{count}} Monate"
          },
          withPreposition: {
            one: "etwa einem Monat",
            other: "etwa {{count}} Monaten"
          }
        },
        xMonths: {
          standalone: {
            one: "ein Monat",
            other: "{{count}} Monate"
          },
          withPreposition: {
            one: "einem Monat",
            other: "{{count}} Monaten"
          }
        },
        aboutXYears: {
          standalone: {
            one: "etwa ein Jahr",
            other: "etwa {{count}} Jahre"
          },
          withPreposition: {
            one: "etwa einem Jahr",
            other: "etwa {{count}} Jahren"
          }
        },
        xYears: {
          standalone: {
            one: "ein Jahr",
            other: "{{count}} Jahre"
          },
          withPreposition: {
            one: "einem Jahr",
            other: "{{count}} Jahren"
          }
        },
        overXYears: {
          standalone: {
            one: "mehr als ein Jahr",
            other: "mehr als {{count}} Jahre"
          },
          withPreposition: {
            one: "mehr als einem Jahr",
            other: "mehr als {{count}} Jahren"
          }
        },
        almostXYears: {
          standalone: {
            one: "fast ein Jahr",
            other: "fast {{count}} Jahre"
          },
          withPreposition: {
            one: "fast einem Jahr",
            other: "fast {{count}} Jahren"
          }
        }
      };
      return {
        localize: function (e, t, n) {
          var o,
              r = (n = n || {}).addSuffix ? i[e].withPreposition : i[e].standalone;
          return o = "string" == typeof r ? r : 1 === t ? r.one : r.other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "in " + o : "vor " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["Jan", "Feb", "M\xE4r", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
          n = ["Januar", "Februar", "M\xE4rz", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
          o = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          r = ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
          i = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + ".";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "\u03BB\u03B9\u03B3\u03CC\u03C4\u03B5\u03C1\u03BF \u03B1\u03C0\u03CC \u03AD\u03BD\u03B1 \u03B4\u03B5\u03C5\u03C4\u03B5\u03C1\u03CC\u03BB\u03B5\u03C0\u03C4\u03BF",
          other: "\u03BB\u03B9\u03B3\u03CC\u03C4\u03B5\u03C1\u03BF \u03B1\u03C0\u03CC {{count}} \u03B4\u03B5\u03C5\u03C4\u03B5\u03C1\u03CC\u03BB\u03B5\u03C0\u03C4\u03B1"
        },
        xSeconds: {
          one: "1 \u03B4\u03B5\u03C5\u03C4\u03B5\u03C1\u03CC\u03BB\u03B5\u03C0\u03C4\u03BF",
          other: "{{count}} \u03B4\u03B5\u03C5\u03C4\u03B5\u03C1\u03CC\u03BB\u03B5\u03C0\u03C4\u03B1"
        },
        halfAMinute: "\u03BC\u03B9\u03C3\u03CC \u03BB\u03B5\u03C0\u03C4\u03CC",
        lessThanXMinutes: {
          one: "\u03BB\u03B9\u03B3\u03CC\u03C4\u03B5\u03C1\u03BF \u03B1\u03C0\u03CC \u03AD\u03BD\u03B1 \u03BB\u03B5\u03C0\u03C4\u03CC",
          other: "\u03BB\u03B9\u03B3\u03CC\u03C4\u03B5\u03C1\u03BF \u03B1\u03C0\u03CC {{count}} \u03BB\u03B5\u03C0\u03C4\u03AC"
        },
        xMinutes: {
          one: "1 \u03BB\u03B5\u03C0\u03C4\u03CC",
          other: "{{count}} \u03BB\u03B5\u03C0\u03C4\u03AC"
        },
        aboutXHours: {
          one: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 1 \u03CE\u03C1\u03B1",
          other: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 {{count}} \u03CE\u03C1\u03B5\u03C2"
        },
        xHours: {
          one: "1 \u03CE\u03C1\u03B1",
          other: "{{count}} \u03CE\u03C1\u03B5\u03C2"
        },
        xDays: {
          one: "1 \u03B7\u03BC\u03AD\u03C1\u03B1",
          other: "{{count}} \u03B7\u03BC\u03AD\u03C1\u03B5\u03C2"
        },
        aboutXMonths: {
          one: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 1 \u03BC\u03AE\u03BD\u03B1\u03C2",
          other: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 {{count}} \u03BC\u03AE\u03BD\u03B5\u03C2"
        },
        xMonths: {
          one: "1 \u03BC\u03AE\u03BD\u03B1\u03C2",
          other: "{{count}} \u03BC\u03AE\u03BD\u03B5\u03C2"
        },
        aboutXYears: {
          one: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 1 \u03C7\u03C1\u03CC\u03BD\u03BF",
          other: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 {{count}} \u03C7\u03C1\u03CC\u03BD\u03B9\u03B1"
        },
        xYears: {
          one: "1 \u03C7\u03C1\u03CC\u03BD\u03BF",
          other: "{{count}} \u03C7\u03C1\u03CC\u03BD\u03B9\u03B1"
        },
        overXYears: {
          one: "\u03C0\u03AC\u03BD\u03C9 \u03B1\u03C0\u03CC 1 \u03C7\u03C1\u03CC\u03BD\u03BF",
          other: "\u03C0\u03AC\u03BD\u03C9 \u03B1\u03C0\u03CC {{count}} \u03C7\u03C1\u03CC\u03BD\u03B9\u03B1"
        },
        almostXYears: {
          one: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 1 \u03C7\u03C1\u03CC\u03BD\u03BF",
          other: "\u03C0\u03B5\u03C1\u03AF\u03C0\u03BF\u03C5 {{count}} \u03C7\u03C1\u03CC\u03BD\u03B9\u03B1"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "\u03C3\u03B5 " + o : o + " \u03C0\u03C1\u03AF\u03BD" : o;
        }
      };
    };
  }, function (e, t, n) {
    var f = n(1);

    e.exports = function () {
      var t = ["\u0399\u03B1\u03BD", "\u03A6\u03B5\u03B2", "\u039C\u03B1\u03C1", "\u0391\u03C0\u03C1", "\u039C\u03B1\u03CA", "\u0399\u03BF\u03C5\u03BD", "\u0399\u03BF\u03C5\u03BB", "\u0391\u03C5\u03B3", "\u03A3\u03B5\u03C0", "\u039F\u03BA\u03C4", "\u039D\u03BF\u03B5", "\u0394\u03B5\u03BA"],
          n = ["\u0399\u03B1\u03BD\u03BF\u03C5\u03AC\u03C1\u03B9\u03BF\u03C2", "\u03A6\u03B5\u03B2\u03C1\u03BF\u03C5\u03AC\u03C1\u03B9\u03BF\u03C2", "\u039C\u03AC\u03C1\u03C4\u03B9\u03BF\u03C2", "\u0391\u03C0\u03C1\u03AF\u03BB\u03B9\u03BF\u03C2", "\u039C\u03AC\u03B9\u03BF\u03C2", "\u0399\u03BF\u03CD\u03BD\u03B9\u03BF\u03C2", "\u0399\u03BF\u03CD\u03BB\u03B9\u03BF\u03C2", "\u0391\u03CD\u03B3\u03BF\u03C5\u03C3\u03C4\u03BF\u03C2", "\u03A3\u03B5\u03C0\u03C4\u03AD\u03BC\u03B2\u03C1\u03B9\u03BF\u03C2", "\u039F\u03BA\u03C4\u03CE\u03B2\u03C1\u03B9\u03BF\u03C2", "\u039D\u03BF\u03AD\u03BC\u03B2\u03C1\u03B9\u03BF\u03C2", "\u0394\u03B5\u03BA\u03AD\u03BC\u03B2\u03C1\u03B9\u03BF\u03C2"],
          o = ["\u0399\u03B1\u03BD\u03BF\u03C5\u03B1\u03C1\u03AF\u03BF\u03C5", "\u03A6\u03B5\u03B2\u03C1\u03BF\u03C5\u03B1\u03C1\u03AF\u03BF\u03C5", "\u039C\u03B1\u03C1\u03C4\u03AF\u03BF\u03C5", "\u0391\u03C0\u03C1\u03B9\u03BB\u03AF\u03BF\u03C5", "\u039C\u03B1\u0390\u03BF\u03C5", "\u0399\u03BF\u03C5\u03BD\u03AF\u03BF\u03C5", "\u0399\u03BF\u03C5\u03BB\u03AF\u03BF\u03C5", "\u0391\u03C5\u03B3\u03BF\u03CD\u03C3\u03C4\u03BF\u03C5", "\u03A3\u03B5\u03C0\u03C4\u03B5\u03BC\u03B2\u03C1\u03AF\u03BF\u03C5", "\u039F\u03BA\u03C4\u03C9\u03B2\u03C1\u03AF\u03BF\u03C5", "\u039D\u03BF\u03B5\u03BC\u03B2\u03C1\u03AF\u03BF\u03C5", "\u0394\u03B5\u03BA\u03B5\u03BC\u03B2\u03C1\u03AF\u03BF\u03C5"],
          r = ["\u039A\u03C5", "\u0394\u03B5", "\u03A4\u03C1", "\u03A4\u03B5", "\u03A0\u03AD", "\u03A0\u03B1", "\u03A3\u03AC"],
          i = ["\u039A\u03C5\u03C1", "\u0394\u03B5\u03C5", "\u03A4\u03C1\u03AF", "\u03A4\u03B5\u03C4", "\u03A0\u03AD\u03BC", "\u03A0\u03B1\u03C1", "\u03A3\u03AC\u03B2"],
          a = ["\u039A\u03C5\u03C1\u03B9\u03B1\u03BA\u03AE", "\u0394\u03B5\u03C5\u03C4\u03AD\u03C1\u03B1", "\u03A4\u03C1\u03AF\u03C4\u03B7", "\u03A4\u03B5\u03C4\u03AC\u03C1\u03C4\u03B7", "\u03A0\u03AD\u03BC\u03C0\u03C4\u03B7", "\u03A0\u03B1\u03C1\u03B1\u03C3\u03BA\u03B5\u03C5\u03AE", "\u03A3\u03AC\u03B2\u03B2\u03B1\u03C4\u03BF"],
          s = ["\u03A0\u039C", "\u039C\u039C"],
          u = ["\u03C0\u03BC", "\u03BC\u03BC"],
          c = ["\u03C0.\u03BC.", "\u03BC.\u03BC."],
          l = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return r[e.getDay()];
        },
        ddd: function (e) {
          return i[e.getDay()];
        },
        dddd: function (e) {
          return a[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? c[1] : c[0];
        }
      },
          d = {
        M: "\u03BF\u03C2",
        D: "\u03B7",
        DDD: "\u03B7",
        d: "\u03B7",
        Q: "\u03BF",
        W: "\u03B7"
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        l[n + "o"] = function (e, t) {
          return t[n](e) + d[n];
        };
      }), ["D", "Do", "DD"].forEach(function (n) {
        l[n + " MMMM"] = function (e, t) {
          return (l[n] || t[n])(e, t) + " " + o[e.getMonth()];
        };
      }), {
        formatters: l,
        formattingTokensRegExp: f(l)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "malpli ol sekundo",
          other: "malpli ol {{count}} sekundoj"
        },
        xSeconds: {
          one: "1 sekundo",
          other: "{{count}} sekundoj"
        },
        halfAMinute: "duonminuto",
        lessThanXMinutes: {
          one: "malpli ol minuto",
          other: "malpli ol {{count}} minutoj"
        },
        xMinutes: {
          one: "1 minuto",
          other: "{{count}} minutoj"
        },
        aboutXHours: {
          one: "proksimume 1 horo",
          other: "proksimume {{count}} horoj"
        },
        xHours: {
          one: "1 horo",
          other: "{{count}} horoj"
        },
        xDays: {
          one: "1 tago",
          other: "{{count}} tagoj"
        },
        aboutXMonths: {
          one: "proksimume 1 monato",
          other: "proksimume {{count}} monatoj"
        },
        xMonths: {
          one: "1 monato",
          other: "{{count}} monatoj"
        },
        aboutXYears: {
          one: "proksimume 1 jaro",
          other: "proksimume {{count}} jaroj"
        },
        xYears: {
          one: "1 jaro",
          other: "{{count}} jaroj"
        },
        overXYears: {
          one: "pli ol 1 jaro",
          other: "pli ol {{count}} jaroj"
        },
        almostXYears: {
          one: "preska\u016D 1 jaro",
          other: "preska\u016D {{count}} jaroj"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "post " + o : "anta\u016D " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "a\u016Dg", "sep", "okt", "nov", "dec"],
          n = ["januaro", "februaro", "marto", "aprilo", "majo", "junio", "julio", "a\u016Dgusto", "septembro", "oktobro", "novembro", "decembro"],
          o = ["di", "lu", "ma", "me", "\u0135a", "ve", "sa"],
          r = ["dim", "lun", "mar", "mer", "\u0135a\u016D", "ven", "sab"],
          i = ["diman\u0109o", "lundo", "mardo", "merkredo", "\u0135a\u016Ddo", "vendredo", "sabato"],
          a = ["A.T.M.", "P.T.M."],
          s = ["a.t.m.", "p.t.m."],
          u = ["anta\u016Dtagmeze", "posttagmeze"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + "-a";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "menos de un segundo",
          other: "menos de {{count}} segundos"
        },
        xSeconds: {
          one: "1 segundo",
          other: "{{count}} segundos"
        },
        halfAMinute: "medio minuto",
        lessThanXMinutes: {
          one: "menos de un minuto",
          other: "menos de {{count}} minutos"
        },
        xMinutes: {
          one: "1 minuto",
          other: "{{count}} minutos"
        },
        aboutXHours: {
          one: "alrededor de 1 hora",
          other: "alrededor de {{count}} horas"
        },
        xHours: {
          one: "1 hora",
          other: "{{count}} horas"
        },
        xDays: {
          one: "1 d\xEDa",
          other: "{{count}} d\xEDas"
        },
        aboutXMonths: {
          one: "alrededor de 1 mes",
          other: "alrededor de {{count}} meses"
        },
        xMonths: {
          one: "1 mes",
          other: "{{count}} meses"
        },
        aboutXYears: {
          one: "alrededor de 1 a\xF1o",
          other: "alrededor de {{count}} a\xF1os"
        },
        xYears: {
          one: "1 a\xF1o",
          other: "{{count}} a\xF1os"
        },
        overXYears: {
          one: "m\xE1s de 1 a\xF1o",
          other: "m\xE1s de {{count}} a\xF1os"
        },
        almostXYears: {
          one: "casi 1 a\xF1o",
          other: "casi {{count}} a\xF1os"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "en " + o : "hace " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
          n = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
          o = ["do", "lu", "ma", "mi", "ju", "vi", "sa"],
          r = ["dom", "lun", "mar", "mi\xE9", "jue", "vie", "s\xE1b"],
          i = ["domingo", "lunes", "martes", "mi\xE9rcoles", "jueves", "viernes", "s\xE1bado"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + "\xBA";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      function e(e) {
        return e.replace(/sekuntia?/, "sekunnin");
      }

      function t(e) {
        return e.replace(/minuuttia?/, "minuutin");
      }

      function n(e) {
        return e.replace(/tuntia?/, "tunnin");
      }

      function o(e) {
        return e.replace(/(kuukausi|kuukautta)/, "kuukauden");
      }

      function r(e) {
        return e.replace(/(vuosi|vuotta)/, "vuoden");
      }

      var i = {
        lessThanXSeconds: {
          one: "alle sekunti",
          other: "alle {{count}} sekuntia",
          futureTense: e
        },
        xSeconds: {
          one: "sekunti",
          other: "{{count}} sekuntia",
          futureTense: e
        },
        halfAMinute: {
          one: "puoli minuuttia",
          other: "puoli minuuttia",
          futureTense: function (e) {
            return "puolen minuutin";
          }
        },
        lessThanXMinutes: {
          one: "alle minuutti",
          other: "alle {{count}} minuuttia",
          futureTense: t
        },
        xMinutes: {
          one: "minuutti",
          other: "{{count}} minuuttia",
          futureTense: t
        },
        aboutXHours: {
          one: "noin tunti",
          other: "noin {{count}} tuntia",
          futureTense: n
        },
        xHours: {
          one: "tunti",
          other: "{{count}} tuntia",
          futureTense: n
        },
        xDays: {
          one: "p\xE4iv\xE4",
          other: "{{count}} p\xE4iv\xE4\xE4",
          futureTense: function (e) {
            return e.replace(/päivää?/, "p\xE4iv\xE4n");
          }
        },
        aboutXMonths: {
          one: "noin kuukausi",
          other: "noin {{count}} kuukautta",
          futureTense: o
        },
        xMonths: {
          one: "kuukausi",
          other: "{{count}} kuukautta",
          futureTense: o
        },
        aboutXYears: {
          one: "noin vuosi",
          other: "noin {{count}} vuotta",
          futureTense: r
        },
        xYears: {
          one: "vuosi",
          other: "{{count}} vuotta",
          futureTense: r
        },
        overXYears: {
          one: "yli vuosi",
          other: "yli {{count}} vuotta",
          futureTense: r
        },
        almostXYears: {
          one: "l\xE4hes vuosi",
          other: "l\xE4hes {{count}} vuotta",
          futureTense: r
        }
      };
      return {
        localize: function (e, t, n) {
          n = n || {};
          var o = i[e],
              r = 1 === t ? o.one : o.other.replace("{{count}}", t);
          return n.addSuffix ? 0 < n.comparison ? o.futureTense(r) + " kuluttua" : r + " sitten" : r;
        }
      };
    };
  }, function (e, t, n) {
    var a = n(1);

    e.exports = function () {
      var t = ["tammi", "helmi", "maalis", "huhti", "touko", "kes\xE4", "hein\xE4", "elo", "syys", "loka", "marras", "joulu"],
          n = ["tammikuu", "helmikuu", "maaliskuu", "huhtikuu", "toukokuu", "kes\xE4kuu", "hein\xE4kuu", "elokuu", "syyskuu", "lokakuu", "marraskuu", "joulukuu"],
          o = ["su", "ma", "ti", "ke", "to", "pe", "la"],
          r = ["sunnuntai", "maanantai", "tiistai", "keskiviikko", "torstai", "perjantai", "lauantai"];

      function e(e) {
        return e.getHours() < 12 ? "AP" : "IP";
      }

      var i = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return o[e.getDay()];
        },
        dddd: function (e) {
          return r[e.getDay()];
        },
        A: e,
        a: e,
        aa: e
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        i[n + "o"] = function (e, t) {
          return t[n](e).toString() + ".";
        };
      }), {
        formatters: i,
        formattingTokensRegExp: a(i)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "mas maliit sa isang segundo",
          other: "mas maliit sa {{count}} segundo"
        },
        xSeconds: {
          one: "1 segundo",
          other: "{{count}} segundo"
        },
        halfAMinute: "kalahating minuto",
        lessThanXMinutes: {
          one: "mas maliit sa isang minuto",
          other: "mas maliit sa {{count}} minuto"
        },
        xMinutes: {
          one: "1 minuto",
          other: "{{count}} minuto"
        },
        aboutXHours: {
          one: "mga 1 oras",
          other: "mga {{count}} oras"
        },
        xHours: {
          one: "1 oras",
          other: "{{count}} oras"
        },
        xDays: {
          one: "1 araw",
          other: "{{count}} araw"
        },
        aboutXMonths: {
          one: "mga 1 buwan",
          other: "mga {{count}} buwan"
        },
        xMonths: {
          one: "1 buwan",
          other: "{{count}} buwan"
        },
        aboutXYears: {
          one: "mga 1 taon",
          other: "mga {{count}} taon"
        },
        xYears: {
          one: "1 taon",
          other: "{{count}} taon"
        },
        overXYears: {
          one: "higit sa 1 taon",
          other: "higit sa {{count}} taon"
        },
        almostXYears: {
          one: "halos 1 taon",
          other: "halos {{count}} taon"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "sa loob ng " + o : o + " ang nakalipas" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["Ene", "Peb", "Mar", "Abr", "May", "Hun", "Hul", "Ago", "Set", "Okt", "Nob", "Dis"],
          n = ["Enero", "Pebrero", "Marso", "Abril", "Mayo", "Hunyo", "Hulyo", "Agosto", "Setyembre", "Oktubre", "Nobyembre", "Disyembre"],
          o = ["Li", "Lu", "Ma", "Mi", "Hu", "Bi", "Sa"],
          r = ["Lin", "Lun", "Mar", "Miy", "Huw", "Biy", "Sab"],
          i = ["Linggo", "Lunes", "Martes", "Miyerkules", "Huwebes", "Biyernes", "Sabado"],
          a = ["NU", "NT", "NH", "NG"],
          s = ["nu", "nt", "nh", "ng"],
          u = ["ng umaga", "ng tanghali", "ng hapon", "ng gabi"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 12 < e.getHours() ? e.getHours() % 12 < 6 ? a[2] : a[3] : e.getHours() < 12 ? a[0] : a[1];
        },
        a: function (e) {
          return 12 < e.getHours() ? e.getHours() % 12 < 6 ? s[2] : s[3] : e.getHours() < 12 ? s[0] : s[1];
        },
        aa: function (e) {
          return 12 < e.getHours() ? e.getHours() % 12 < 6 ? u[2] : u[3] : e.getHours() < 12 ? u[0] : u[1];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return "ika-" + t[n](e);
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "moins d\u2019une seconde",
          other: "moins de {{count}} secondes"
        },
        xSeconds: {
          one: "1 seconde",
          other: "{{count}} secondes"
        },
        halfAMinute: "30 secondes",
        lessThanXMinutes: {
          one: "moins d\u2019une minute",
          other: "moins de {{count}} minutes"
        },
        xMinutes: {
          one: "1 minute",
          other: "{{count}} minutes"
        },
        aboutXHours: {
          one: "environ 1 heure",
          other: "environ {{count}} heures"
        },
        xHours: {
          one: "1 heure",
          other: "{{count}} heures"
        },
        xDays: {
          one: "1 jour",
          other: "{{count}} jours"
        },
        aboutXMonths: {
          one: "environ 1 mois",
          other: "environ {{count}} mois"
        },
        xMonths: {
          one: "1 mois",
          other: "{{count}} mois"
        },
        aboutXYears: {
          one: "environ 1 an",
          other: "environ {{count}} ans"
        },
        xYears: {
          one: "1 an",
          other: "{{count}} ans"
        },
        overXYears: {
          one: "plus d\u2019un an",
          other: "plus de {{count}} ans"
        },
        almostXYears: {
          one: "presqu\u2019un an",
          other: "presque {{count}} ans"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "dans " + o : "il y a " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var l = n(1);

    e.exports = function () {
      var t = ["janv.", "f\xE9vr.", "mars", "avr.", "mai", "juin", "juill.", "ao\xFBt", "sept.", "oct.", "nov.", "d\xE9c."],
          n = ["janvier", "f\xE9vrier", "mars", "avril", "mai", "juin", "juillet", "ao\xFBt", "septembre", "octobre", "novembre", "d\xE9cembre"],
          o = ["di", "lu", "ma", "me", "je", "ve", "sa"],
          r = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
          i = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["du matin", "de l\u2019apr\xE8s-midi", "du soir"],
          c = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          var t = e.getHours();
          return t <= 12 ? u[0] : t <= 16 ? u[1] : u[2];
        },
        Wo: function (e, t) {
          return 1 === (n = t.W(e)) ? "1re" : n + "e";
          var n;
        }
      };
      return ["M", "D", "DDD", "d", "Q"].forEach(function (o) {
        c[o + "o"] = function (e, t) {
          return 1 === (n = t[o](e)) ? "1er" : n + "e";
          var n;
        };
      }), ["MMM", "MMMM"].forEach(function (o) {
        c["Do " + o] = function (e, t) {
          var n = 1 === e.getDate() ? "Do" : "D";
          return (c[n] || t[n])(e, t) + " " + c[o](e);
        };
      }), {
        formatters: c,
        formattingTokensRegExp: l(c)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: {
            standalone: "manje od 1 sekunde",
            withPrepositionAgo: "manje od 1 sekunde",
            withPrepositionIn: "manje od 1 sekundu"
          },
          dual: "manje od {{count}} sekunde",
          other: "manje od {{count}} sekundi"
        },
        xSeconds: {
          one: {
            standalone: "1 sekunda",
            withPrepositionAgo: "1 sekunde",
            withPrepositionIn: "1 sekundu"
          },
          dual: "{{count}} sekunde",
          other: "{{count}} sekundi"
        },
        halfAMinute: "pola minute",
        lessThanXMinutes: {
          one: {
            standalone: "manje od 1 minute",
            withPrepositionAgo: "manje od 1 minute",
            withPrepositionIn: "manje od 1 minutu"
          },
          dual: "manje od {{count}} minute",
          other: "manje od {{count}} minuta"
        },
        xMinutes: {
          one: {
            standalone: "1 minuta",
            withPrepositionAgo: "1 minute",
            withPrepositionIn: "1 minutu"
          },
          dual: "{{count}} minute",
          other: "{{count}} minuta"
        },
        aboutXHours: {
          one: {
            standalone: "oko 1 sat",
            withPrepositionAgo: "oko 1 sat",
            withPrepositionIn: "oko 1 sat"
          },
          dual: "oko {{count}} sata",
          other: "oko {{count}} sati"
        },
        xHours: {
          one: {
            standalone: "1 sat",
            withPrepositionAgo: "1 sat",
            withPrepositionIn: "1 sat"
          },
          dual: "{{count}} sata",
          other: "{{count}} sati"
        },
        xDays: {
          one: {
            standalone: "1 dan",
            withPrepositionAgo: "1 dan",
            withPrepositionIn: "1 dan"
          },
          dual: "{{count}} dana",
          other: "{{count}} dana"
        },
        aboutXMonths: {
          one: {
            standalone: "oko 1 mjesec",
            withPrepositionAgo: "oko 1 mjesec",
            withPrepositionIn: "oko 1 mjesec"
          },
          dual: "oko {{count}} mjeseca",
          other: "oko {{count}} mjeseci"
        },
        xMonths: {
          one: {
            standalone: "1 mjesec",
            withPrepositionAgo: "1 mjesec",
            withPrepositionIn: "1 mjesec"
          },
          dual: "{{count}} mjeseca",
          other: "{{count}} mjeseci"
        },
        aboutXYears: {
          one: {
            standalone: "oko 1 godinu",
            withPrepositionAgo: "oko 1 godinu",
            withPrepositionIn: "oko 1 godinu"
          },
          dual: "oko {{count}} godine",
          other: "oko {{count}} godina"
        },
        xYears: {
          one: {
            standalone: "1 godina",
            withPrepositionAgo: "1 godine",
            withPrepositionIn: "1 godinu"
          },
          dual: "{{count}} godine",
          other: "{{count}} godina"
        },
        overXYears: {
          one: {
            standalone: "preko 1 godinu",
            withPrepositionAgo: "preko 1 godinu",
            withPrepositionIn: "preko 1 godinu"
          },
          dual: "preko {{count}} godine",
          other: "preko {{count}} godina"
        },
        almostXYears: {
          one: {
            standalone: "gotovo 1 godinu",
            withPrepositionAgo: "gotovo 1 godinu",
            withPrepositionIn: "gotovo 1 godinu"
          },
          dual: "gotovo {{count}} godine",
          other: "gotovo {{count}} godina"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? n.addSuffix ? 0 < n.comparison ? r[e].one.withPrepositionIn : r[e].one.withPrepositionAgo : r[e].one.standalone : 1 < t % 10 && t % 10 < 5 && "1" !== String(t).substr(-2, 1) ? r[e].dual.replace("{{count}}", t) : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "za " + o : "prije " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var d = n(1);

    e.exports = function () {
      var t = ["sij", "velj", "o\u017Eu", "tra", "svi", "lip", "srp", "kol", "ruj", "lis", "stu", "pro"],
          n = ["sije\u010Danj", "velja\u010Da", "o\u017Eujak", "travanj", "svibanj", "lipanj", "srpanj", "kolovoz", "rujan", "listopad", "studeni", "prosinac"],
          o = ["sije\u010Dnja", "velja\u010De", "o\u017Eujka", "travnja", "svibnja", "lipnja", "srpnja", "kolovoza", "rujna", "listopada", "studenog", "prosinca"],
          r = ["ne", "po", "ut", "sr", "\u010De", "pe", "su"],
          i = ["ned", "pon", "uto", "sri", "\u010Det", "pet", "sub"],
          a = ["nedjelja", "ponedjeljak", "utorak", "srijeda", "\u010Detvrtak", "petak", "subota"],
          s = ["ujutro", "popodne"],
          u = ["ujutro", "popodne"],
          c = ["ujutro", "popodne"],
          l = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return r[e.getDay()];
        },
        ddd: function (e) {
          return i[e.getDay()];
        },
        dddd: function (e) {
          return a[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? c[1] : c[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        l[n + "o"] = function (e, t) {
          return t[n](e) + ".";
        };
      }), ["D", "Do", "DD"].forEach(function (n) {
        l[n + " MMM"] = function (e, t) {
          return (l[n] || t[n])(e, t) + " " + o[e.getMonth()];
        };
      }), {
        formatters: l,
        formattingTokensRegExp: d(l)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "kevesebb, mint egy m\xE1sodperce",
          other: "kevesebb, mint {{count}} m\xE1sodperce"
        },
        xSeconds: {
          one: "1 m\xE1sodperce",
          other: "{{count}} m\xE1sodperce"
        },
        halfAMinute: "f\xE9l perce",
        lessThanXMinutes: {
          one: "kevesebb, mint egy perce",
          other: "kevesebb, mint {{count}} perce"
        },
        xMinutes: {
          one: "1 perce",
          other: "{{count}} perce"
        },
        aboutXHours: {
          one: "k\xF6zel 1 \xF3r\xE1ja",
          other: "k\xF6zel {{count}} \xF3r\xE1ja"
        },
        xHours: {
          one: "1 \xF3r\xE1ja",
          other: "{{count}} \xF3r\xE1ja"
        },
        xDays: {
          one: "1 napja",
          other: "{{count}} napja"
        },
        aboutXMonths: {
          one: "k\xF6zel 1 h\xF3napja",
          other: "k\xF6zel {{count}} h\xF3napja"
        },
        xMonths: {
          one: "1 h\xF3napja",
          other: "{{count}} h\xF3napja"
        },
        aboutXYears: {
          one: "k\xF6zel 1 \xE9ve",
          other: "k\xF6zel {{count}} \xE9ve"
        },
        xYears: {
          one: "1 \xE9ve",
          other: "{{count}} \xE9ve"
        },
        overXYears: {
          one: "t\xF6bb, mint 1 \xE9ve",
          other: "t\xF6bb, mint {{count}} \xE9ve"
        },
        almostXYears: {
          one: "majdnem 1 \xE9ve",
          other: "majdnem {{count}} \xE9ve"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "" + o : o + "" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["Jan", "Feb", "M\xE1r", "\xC1pr", "M\xE1j", "J\xFAn", "J\xFAl", "Aug", "Sze", "Okt", "Nov", "Dec"],
          n = ["Janu\xE1r", "Febru\xE1r", "M\xE1rcius", "\xC1prilis", "M\xE1jus", "J\xFAnius", "J\xFAlius", "Augusztus", "Szeptember", "Okt\xF3ber", "November", "December"],
          o = ["Va", "H\xE9", "Ke", "Sze", "Cs", "P\xE9", "Szo"],
          r = ["Vas", "H\xE9t", "Ked", "Sze", "Cs\xFC", "P\xE9n", "Szo"],
          i = ["Vas\xE1rnap", "H\xE9tf\u0151", "Kedd", "Szerda", "Cs\xFCt\xF6rt\xF6k", "P\xE9ntek", "Szombat"],
          a = ["DE", "DU"],
          s = ["de", "du"],
          u = ["d\xE9lel\u0151tt", "d\xE9lut\xE1n"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return function (e) {
            var t = e % 100;
            if (20 < t || t < 10) switch (t % 10) {
              case 1:
                return e + "st";

              case 2:
                return e + "nd";

              case 3:
                return e + "rd";
            }
            return e + "th";
          }(t[n](e));
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "kurang dari 1 detik",
          other: "kurang dari {{count}} detik"
        },
        xSeconds: {
          one: "1 detik",
          other: "{{count}} detik"
        },
        halfAMinute: "setengah menit",
        lessThanXMinutes: {
          one: "kurang dari 1 menit",
          other: "kurang dari {{count}} menit"
        },
        xMinutes: {
          one: "1 menit",
          other: "{{count}} menit"
        },
        aboutXHours: {
          one: "sekitar 1 jam",
          other: "sekitar {{count}} jam"
        },
        xHours: {
          one: "1 jam",
          other: "{{count}} jam"
        },
        xDays: {
          one: "1 hari",
          other: "{{count}} hari"
        },
        aboutXMonths: {
          one: "sekitar 1 bulan",
          other: "sekitar {{count}} bulan"
        },
        xMonths: {
          one: "1 bulan",
          other: "{{count}} bulan"
        },
        aboutXYears: {
          one: "sekitar 1 tahun",
          other: "sekitar {{count}} tahun"
        },
        xYears: {
          one: "1 tahun",
          other: "{{count}} tahun"
        },
        overXYears: {
          one: "lebih dari 1 tahun",
          other: "lebih dari {{count}} tahun"
        },
        almostXYears: {
          one: "hampir 1 tahun",
          other: "hampir {{count}} tahun"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "dalam waktu " + o : o + " yang lalu" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"],
          n = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
          o = ["Mi", "Sn", "Sl", "Ra", "Ka", "Ju", "Sa"],
          r = ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
          i = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return function (e) {
            switch (e) {
              case 1:
                return "pertama";

              case 2:
                return "kedua";

              case 3:
                return "ketiga";

              default:
                return "ke-" + e;
            }
          }(t[n](e));
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "minna en 1 sek\xFAnda",
          other: "minna en {{count}} sek\xFAndur"
        },
        xSeconds: {
          one: "1 sek\xFAnda",
          other: "{{count}} sek\xFAndur"
        },
        halfAMinute: "h\xE1lf m\xEDn\xFAta",
        lessThanXMinutes: {
          one: "minna en 1 m\xEDn\xFAta",
          other: "minna en {{count}} m\xEDn\xFAtur"
        },
        xMinutes: {
          one: "1 m\xEDn\xFAta",
          other: "{{count}} m\xEDn\xFAtur"
        },
        aboutXHours: {
          one: "u.\xFE.b. 1 klukkustund",
          other: "u.\xFE.b. {{count}} klukkustundir"
        },
        xHours: {
          one: "1 klukkustund",
          other: "{{count}} klukkustundir"
        },
        xDays: {
          one: "1 dagur",
          other: "{{count}} dagar"
        },
        aboutXMonths: {
          one: "u.\xFE.b. 1 m\xE1nu\xF0ur",
          other: "u.\xFE.b. {{count}} m\xE1nu\xF0ir"
        },
        xMonths: {
          one: "1 m\xE1nu\xF0ur",
          other: "{{count}} m\xE1nu\xF0ir"
        },
        aboutXYears: {
          one: "u.\xFE.b. 1 \xE1r",
          other: "u.\xFE.b. {{count}} \xE1r"
        },
        xYears: {
          one: "1 \xE1r",
          other: "{{count}} \xE1r"
        },
        overXYears: {
          one: "meira en 1 \xE1r",
          other: "meira en {{count}} \xE1r"
        },
        almostXYears: {
          one: "n\xE6stum 1 \xE1r",
          other: "n\xE6stum {{count}} \xE1r"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "\xED " + o : o + " s\xED\xF0an" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan", "feb", "mar", "apr", "ma\xED", "j\xFAn", "j\xFAl", "\xE1g\xFA", "sep", "okt", "n\xF3v", "des"],
          n = ["jan\xFAar", "febr\xFAar", "mars", "apr\xEDl", "ma\xED", "j\xFAn\xED", "j\xFAl\xED", "\xE1g\xFAst", "september", "okt\xF3ber", "n\xF3vember", "desember"],
          o = ["su", "m\xE1", "\xFEr", "mi", "fi", "f\xF6", "la"],
          r = ["sun", "m\xE1n", "\xFEri", "mi\xF0", "fim", "f\xF6s", "lau"],
          i = ["sunnudaginn", "m\xE1nudaginn", "\xFEri\xF0judaginn", "mi\xF0vikudaginn", "fimmtudaginn", "f\xF6studaginn", "laugardaginn"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return "" + t[n](e);
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "meno di un secondo",
          other: "meno di {{count}} secondi"
        },
        xSeconds: {
          one: "un secondo",
          other: "{{count}} secondi"
        },
        halfAMinute: "alcuni secondi",
        lessThanXMinutes: {
          one: "meno di un minuto",
          other: "meno di {{count}} minuti"
        },
        xMinutes: {
          one: "un minuto",
          other: "{{count}} minuti"
        },
        aboutXHours: {
          one: "circa un'ora",
          other: "circa {{count}} ore"
        },
        xHours: {
          one: "un'ora",
          other: "{{count}} ore"
        },
        xDays: {
          one: "un giorno",
          other: "{{count}} giorni"
        },
        aboutXMonths: {
          one: "circa un mese",
          other: "circa {{count}} mesi"
        },
        xMonths: {
          one: "un mese",
          other: "{{count}} mesi"
        },
        aboutXYears: {
          one: "circa un anno",
          other: "circa {{count}} anni"
        },
        xYears: {
          one: "un anno",
          other: "{{count}} anni"
        },
        overXYears: {
          one: "pi\xF9 di un anno",
          other: "pi\xF9 di {{count}} anni"
        },
        almostXYears: {
          one: "quasi un anno",
          other: "quasi {{count}} anni"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "tra " + o : o + " fa" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"],
          n = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
          o = ["do", "lu", "ma", "me", "gi", "ve", "sa"],
          r = ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
          i = ["domenica", "luned\xEC", "marted\xEC", "mercoled\xEC", "gioved\xEC", "venerd\xEC", "sabato"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + "\xBA";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "1\u79D2\u4EE5\u4E0B",
          other: "{{count}}\u79D2\u4EE5\u4E0B"
        },
        xSeconds: {
          one: "1\u79D2",
          other: "{{count}}\u79D2"
        },
        halfAMinute: "30\u79D2\u3050\u3089\u3044",
        lessThanXMinutes: {
          one: "1\u5206\u4EE5\u4E0B",
          other: "{{count}}\u5206\u4EE5\u4E0B"
        },
        xMinutes: {
          one: "1\u5206",
          other: "{{count}}\u5206"
        },
        aboutXHours: {
          one: "1\u6642\u9593\u3050\u3089\u3044",
          other: "{{count}}\u6642\u9593\u3050\u3089\u3044"
        },
        xHours: {
          one: "1\u6642\u9593",
          other: "{{count}}\u6642\u9593"
        },
        xDays: {
          one: "1\u65E5",
          other: "{{count}}\u65E5"
        },
        aboutXMonths: {
          one: "1\u30F6\u6708\u3050\u3089\u3044",
          other: "{{count}}\u30F6\u6708\u3050\u3089\u3044"
        },
        xMonths: {
          one: "1\u30F6\u6708",
          other: "{{count}}\u30F6\u6708"
        },
        aboutXYears: {
          one: "1\u5E74\u3050\u3089\u3044",
          other: "{{count}}\u5E74\u3050\u3089\u3044"
        },
        xYears: {
          one: "1\u5E74",
          other: "{{count}}\u5E74"
        },
        overXYears: {
          one: "1\u5E74\u4EE5\u4E0A",
          other: "{{count}}\u5E74\u4EE5\u4E0A"
        },
        almostXYears: {
          one: "1\u5E74\u4EE5\u4E0B",
          other: "{{count}}\u5E74\u4EE5\u4E0B"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? o + "\u5F8C" : o + "\u524D" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"],
          n = ["\u4E00\u6708", "\u4E8C\u6708", "\u4E09\u6708", "\u56DB\u6708", "\u4E94\u6708", "\u516D\u6708", "\u4E03\u6708", "\u516B\u6708", "\u4E5D\u6708", "\u5341\u6708", "\u5341\u4E00\u6708", "\u5341\u4E8C\u6708"],
          o = ["\u65E5", "\u6708", "\u706B", "\u6C34", "\u6728", "\u91D1", "\u571F"],
          r = ["\u65E5\u66DC", "\u6708\u66DC", "\u706B\u66DC", "\u6C34\u66DC", "\u6728\u66DC", "\u91D1\u66DC", "\u571F\u66DC"],
          i = ["\u65E5\u66DC\u65E5", "\u6708\u66DC\u65E5", "\u706B\u66DC\u65E5", "\u6C34\u66DC\u65E5", "\u6728\u66DC\u65E5", "\u91D1\u66DC\u65E5", "\u571F\u66DC\u65E5"],
          a = ["\u5348\u524D", "\u5348\u5F8C"],
          s = ["\u5348\u524D", "\u5348\u5F8C"],
          u = ["\u5348\u524D", "\u5348\u5F8C"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + "\u65E5";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "1\uCD08 \uBBF8\uB9CC",
          other: "{{count}}\uCD08 \uBBF8\uB9CC"
        },
        xSeconds: {
          one: "1\uCD08",
          other: "{{count}}\uCD08"
        },
        halfAMinute: "30\uCD08",
        lessThanXMinutes: {
          one: "1\uBD84 \uBBF8\uB9CC",
          other: "{{count}}\uBD84 \uBBF8\uB9CC"
        },
        xMinutes: {
          one: "1\uBD84",
          other: "{{count}}\uBD84"
        },
        aboutXHours: {
          one: "\uC57D 1\uC2DC\uAC04",
          other: "\uC57D {{count}}\uC2DC\uAC04"
        },
        xHours: {
          one: "1\uC2DC\uAC04",
          other: "{{count}}\uC2DC\uAC04"
        },
        xDays: {
          one: "1\uC77C",
          other: "{{count}}\uC77C"
        },
        aboutXMonths: {
          one: "\uC57D 1\uAC1C\uC6D4",
          other: "\uC57D {{count}}\uAC1C\uC6D4"
        },
        xMonths: {
          one: "1\uAC1C\uC6D4",
          other: "{{count}}\uAC1C\uC6D4"
        },
        aboutXYears: {
          one: "\uC57D 1\uB144",
          other: "\uC57D {{count}}\uB144"
        },
        xYears: {
          one: "1\uB144",
          other: "{{count}}\uB144"
        },
        overXYears: {
          one: "1\uB144 \uC774\uC0C1",
          other: "{{count}}\uB144 \uC774\uC0C1"
        },
        almostXYears: {
          one: "\uAC70\uC758 1\uB144",
          other: "\uAC70\uC758 {{count}}\uB144"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? o + " \uD6C4" : o + " \uC804" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["1\uC6D4", "2\uC6D4", "3\uC6D4", "4\uC6D4", "5\uC6D4", "6\uC6D4", "7\uC6D4", "8\uC6D4", "9\uC6D4", "10\uC6D4", "11\uC6D4", "12\uC6D4"],
          n = ["1\uC6D4", "2\uC6D4", "3\uC6D4", "4\uC6D4", "5\uC6D4", "6\uC6D4", "7\uC6D4", "8\uC6D4", "9\uC6D4", "10\uC6D4", "11\uC6D4", "12\uC6D4"],
          o = ["\uC77C", "\uC6D4", "\uD654", "\uC218", "\uBAA9", "\uAE08", "\uD1A0"],
          r = ["\uC77C", "\uC6D4", "\uD654", "\uC218", "\uBAA9", "\uAE08", "\uD1A0"],
          i = ["\uC77C\uC694\uC77C", "\uC6D4\uC694\uC77C", "\uD654\uC694\uC77C", "\uC218\uC694\uC77C", "\uBAA9\uC694\uC77C", "\uAE08\uC694\uC77C", "\uD1A0\uC694\uC77C"],
          a = ["\uC624\uC804", "\uC624\uD6C4"],
          s = ["\uC624\uC804", "\uC624\uD6C4"],
          u = ["\uC624\uC804", "\uC624\uD6C4"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + "\uC77C";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "\u043F\u043E\u043C\u0430\u043B\u043A\u0443 \u043E\u0434 \u0441\u0435\u043A\u0443\u043D\u0434\u0430",
          other: "\u043F\u043E\u043C\u0430\u043B\u043A\u0443 \u043E\u0434 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0438"
        },
        xSeconds: {
          one: "1 \u0441\u0435\u043A\u0443\u043D\u0434\u0430",
          other: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0438"
        },
        halfAMinute: "\u043F\u043E\u043B\u043E\u0432\u0438\u043D\u0430 \u043C\u0438\u043D\u0443\u0442\u0430",
        lessThanXMinutes: {
          one: "\u043F\u043E\u043C\u0430\u043B\u043A\u0443 \u043E\u0434 \u043C\u0438\u043D\u0443\u0442\u0430",
          other: "\u043F\u043E\u043C\u0430\u043B\u043A\u0443 \u043E\u0434 {{count}} \u043C\u0438\u043D\u0443\u0442\u0438"
        },
        xMinutes: {
          one: "1 \u043C\u0438\u043D\u0443\u0442\u0430",
          other: "{{count}} \u043C\u0438\u043D\u0443\u0442\u0438"
        },
        aboutXHours: {
          one: "\u043E\u043A\u043E\u043B\u0443 1 \u0447\u0430\u0441",
          other: "\u043E\u043A\u043E\u043B\u0443 {{count}} \u0447\u0430\u0441\u0430"
        },
        xHours: {
          one: "1 \u0447\u0430\u0441",
          other: "{{count}} \u0447\u0430\u0441\u0430"
        },
        xDays: {
          one: "1 \u0434\u0435\u043D",
          other: "{{count}} \u0434\u0435\u043D\u0430"
        },
        aboutXMonths: {
          one: "\u043E\u043A\u043E\u043B\u0443 1 \u043C\u0435\u0441\u0435\u0446",
          other: "\u043E\u043A\u043E\u043B\u0443 {{count}} \u043C\u0435\u0441\u0435\u0446\u0438"
        },
        xMonths: {
          one: "1 \u043C\u0435\u0441\u0435\u0446",
          other: "{{count}} \u043C\u0435\u0441\u0435\u0446\u0438"
        },
        aboutXYears: {
          one: "\u043E\u043A\u043E\u043B\u0443 1 \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "\u043E\u043A\u043E\u043B\u0443 {{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        },
        xYears: {
          one: "1 \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "{{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        },
        overXYears: {
          one: "\u043F\u043E\u0432\u0435\u045C\u0435 \u043E\u0434 1 \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "\u043F\u043E\u0432\u0435\u045C\u0435 \u043E\u0434 {{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        },
        almostXYears: {
          one: "\u0431\u0435\u0437\u043C\u0430\u043B\u043A\u0443 1 \u0433\u043E\u0434\u0438\u043D\u0430",
          other: "\u0431\u0435\u0437\u043C\u0430\u043B\u043A\u0443 {{count}} \u0433\u043E\u0434\u0438\u043D\u0438"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "\u0437\u0430 " + o : "\u043F\u0440\u0435\u0434 " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var s = n(1);

    e.exports = function () {
      var t = ["\u0458\u0430\u043D", "\u0444\u0435\u0432", "\u043C\u0430\u0440", "\u0430\u043F\u0440", "\u043C\u0430\u0458", "\u0458\u0443\u043D", "\u0458\u0443\u043B", "\u0430\u0432\u0433", "\u0441\u0435\u043F", "\u043E\u043A\u0442", "\u043D\u043E\u0435", "\u0434\u0435\u043A"],
          n = ["\u0458\u0430\u043D\u0443\u0430\u0440\u0438", "\u0444\u0435\u0432\u0440\u0443\u0430\u0440\u0438", "\u043C\u0430\u0440\u0442", "\u0430\u043F\u0440\u0438\u043B", "\u043C\u0430\u0458", "\u0458\u0443\u043D\u0438", "\u0458\u0443\u043B\u0438", "\u0430\u0432\u0433\u0443\u0441\u0442", "\u0441\u0435\u043F\u0442\u0435\u043C\u0432\u0440\u0438", "\u043E\u043A\u0442\u043E\u043C\u0432\u0440\u0438", "\u043D\u043E\u0435\u043C\u0432\u0440\u0438", "\u0434\u0435\u043A\u0435\u043C\u0432\u0440\u0438"],
          o = ["\u043D\u0435", "\u043F\u043E", "\u0432\u0442", "\u0441\u0440", "\u0447\u0435", "\u043F\u0435", "\u0441\u0430"],
          r = ["\u043D\u0435\u0434", "\u043F\u043E\u043D", "\u0432\u0442\u043E", "\u0441\u0440\u0435", "\u0447\u0435\u0442", "\u043F\u0435\u0442", "\u0441\u0430\u0431"],
          i = ["\u043D\u0435\u0434\u0435\u043B\u0430", "\u043F\u043E\u043D\u0435\u0434\u0435\u043B\u043D\u0438\u043A", "\u0432\u0442\u043E\u0440\u043D\u0438\u043A", "\u0441\u0440\u0435\u0434\u0430", "\u0447\u0435\u0442\u0432\u0440\u0442\u043E\u043A", "\u043F\u0435\u0442\u043E\u043A", "\u0441\u0430\u0431\u043E\u0442\u0430"],
          a = ["\u043F\u0440\u0435\u0442\u043F\u043B\u0430\u0434\u043D\u0435", "\u043F\u043E\u043F\u043B\u0430\u0434\u043D\u0435"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return function (e) {
            var t = e % 100;
            if (20 < t || t < 10) switch (t % 10) {
              case 1:
                return e + "-\u0432\u0438";

              case 2:
                return e + "-\u0440\u0438";

              case 7:
              case 8:
                return e + "-\u043C\u0438";
            }
            return e + "-\u0442\u0438";
          }(t[n](e));
        };
      }), {
        formatters: e,
        formattingTokensRegExp: s(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "mindre enn ett sekund",
          other: "mindre enn {{count}} sekunder"
        },
        xSeconds: {
          one: "ett sekund",
          other: "{{count}} sekunder"
        },
        halfAMinute: "et halvt minutt",
        lessThanXMinutes: {
          one: "mindre enn ett minutt",
          other: "mindre enn {{count}} minutter"
        },
        xMinutes: {
          one: "ett minutt",
          other: "{{count}} minutter"
        },
        aboutXHours: {
          one: "rundt en time",
          other: "rundt {{count}} timer"
        },
        xHours: {
          one: "en time",
          other: "{{count}} timer"
        },
        xDays: {
          one: "en dag",
          other: "{{count}} dager"
        },
        aboutXMonths: {
          one: "rundt en m\xE5ned",
          other: "rundt {{count}} m\xE5neder"
        },
        xMonths: {
          one: "en m\xE5ned",
          other: "{{count}} m\xE5neder"
        },
        aboutXYears: {
          one: "rundt ett \xE5r",
          other: "rundt {{count}} \xE5r"
        },
        xYears: {
          one: "ett \xE5r",
          other: "{{count}} \xE5r"
        },
        overXYears: {
          one: "over ett \xE5r",
          other: "over {{count}} \xE5r"
        },
        almostXYears: {
          one: "nesten ett \xE5r",
          other: "nesten {{count}} \xE5r"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "om " + o : o + " siden" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan.", "feb.", "mars", "april", "mai", "juni", "juli", "aug.", "sep.", "okt.", "nov.", "des."],
          n = ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"],
          o = ["s\xF8", "ma", "ti", "on", "to", "fr", "l\xF8"],
          r = ["s\xF8.", "ma.", "ti.", "on.", "to.", "fr.", "l\xF8."],
          i = ["s\xF8ndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "l\xF8rdag"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + ".";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "minder dan een seconde",
          other: "minder dan {{count}} seconden"
        },
        xSeconds: {
          one: "1 seconde",
          other: "{{count}} seconden"
        },
        halfAMinute: "een halve minuut",
        lessThanXMinutes: {
          one: "minder dan een minuut",
          other: "minder dan {{count}} minuten"
        },
        xMinutes: {
          one: "een minuut",
          other: "{{count}} minuten"
        },
        aboutXHours: {
          one: "ongeveer 1 uur",
          other: "ongeveer {{count}} uur"
        },
        xHours: {
          one: "1 uur",
          other: "{{count}} uur"
        },
        xDays: {
          one: "1 dag",
          other: "{{count}} dagen"
        },
        aboutXMonths: {
          one: "ongeveer 1 maand",
          other: "ongeveer {{count}} maanden"
        },
        xMonths: {
          one: "1 maand",
          other: "{{count}} maanden"
        },
        aboutXYears: {
          one: "ongeveer 1 jaar",
          other: "ongeveer {{count}} jaar"
        },
        xYears: {
          one: "1 jaar",
          other: "{{count}} jaar"
        },
        overXYears: {
          one: "meer dan 1 jaar",
          other: "meer dan {{count}} jaar"
        },
        almostXYears: {
          one: "bijna 1 jaar",
          other: "bijna {{count}} jaar"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "over " + o : o + " geleden" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
          n = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
          o = ["zo", "ma", "di", "wo", "do", "vr", "za"],
          r = ["zon", "maa", "din", "woe", "don", "vri", "zat"],
          i = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + "e";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    function i(e, t, n) {
      n = n || "regular";

      var o = function (e, t) {
        if (1 === t) return e.one;
        var n = t % 100;
        if (n <= 20 && 10 < n) return e.other;
        var o = n % 10;
        return 2 <= o && o <= 4 ? e.twoFour : e.other;
      }(e, t);

      return (o[n] || o).replace("{{count}}", t);
    }

    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: {
            regular: "mniej ni\u017C sekunda",
            past: "mniej ni\u017C sekund\u0119",
            future: "mniej ni\u017C sekund\u0119"
          },
          twoFour: "mniej ni\u017C {{count}} sekundy",
          other: "mniej ni\u017C {{count}} sekund"
        },
        xSeconds: {
          one: {
            regular: "sekunda",
            past: "sekund\u0119",
            future: "sekund\u0119"
          },
          twoFour: "{{count}} sekundy",
          other: "{{count}} sekund"
        },
        halfAMinute: {
          one: "p\xF3\u0142 minuty",
          twoFour: "p\xF3\u0142 minuty",
          other: "p\xF3\u0142 minuty"
        },
        lessThanXMinutes: {
          one: {
            regular: "mniej ni\u017C minuta",
            past: "mniej ni\u017C minut\u0119",
            future: "mniej ni\u017C minut\u0119"
          },
          twoFour: "mniej ni\u017C {{count}} minuty",
          other: "mniej ni\u017C {{count}} minut"
        },
        xMinutes: {
          one: {
            regular: "minuta",
            past: "minut\u0119",
            future: "minut\u0119"
          },
          twoFour: "{{count}} minuty",
          other: "{{count}} minut"
        },
        aboutXHours: {
          one: {
            regular: "oko\u0142o godzina",
            past: "oko\u0142o godziny",
            future: "oko\u0142o godzin\u0119"
          },
          twoFour: "oko\u0142o {{count}} godziny",
          other: "oko\u0142o {{count}} godzin"
        },
        xHours: {
          one: {
            regular: "godzina",
            past: "godzin\u0119",
            future: "godzin\u0119"
          },
          twoFour: "{{count}} godziny",
          other: "{{count}} godzin"
        },
        xDays: {
          one: {
            regular: "dzie\u0144",
            past: "dzie\u0144",
            future: "1 dzie\u0144"
          },
          twoFour: "{{count}} dni",
          other: "{{count}} dni"
        },
        aboutXMonths: {
          one: "oko\u0142o miesi\u0105c",
          twoFour: "oko\u0142o {{count}} miesi\u0105ce",
          other: "oko\u0142o {{count}} miesi\u0119cy"
        },
        xMonths: {
          one: "miesi\u0105c",
          twoFour: "{{count}} miesi\u0105ce",
          other: "{{count}} miesi\u0119cy"
        },
        aboutXYears: {
          one: "oko\u0142o rok",
          twoFour: "oko\u0142o {{count}} lata",
          other: "oko\u0142o {{count}} lat"
        },
        xYears: {
          one: "rok",
          twoFour: "{{count}} lata",
          other: "{{count}} lat"
        },
        overXYears: {
          one: "ponad rok",
          twoFour: "ponad {{count}} lata",
          other: "ponad {{count}} lat"
        },
        almostXYears: {
          one: "prawie rok",
          twoFour: "prawie {{count}} lata",
          other: "prawie {{count}} lat"
        }
      };
      return {
        localize: function (e, t, n) {
          var o = r[e];
          return (n = n || {}).addSuffix ? 0 < n.comparison ? "za " + i(o, t, "future") : i(o, t, "past") + " temu" : i(o, t);
        }
      };
    };
  }, function (e, t, n) {
    var s = n(1);

    e.exports = function () {
      var t = ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "pa\u017A", "lis", "gru"],
          n = ["stycze\u0144", "luty", "marzec", "kwiecie\u0144", "maj", "czerwiec", "lipiec", "sierpie\u0144", "wrzesie\u0144", "pa\u017Adziernik", "listopad", "grudzie\u0144"],
          o = ["nd", "pn", "wt", "\u015Br", "cz", "pt", "sb"],
          r = ["niedz.", "pon.", "wt.", "\u015Br.", "czw.", "pi\u0105t.", "sob."],
          i = ["niedziela", "poniedzia\u0142ek", "wtorek", "\u015Broda", "czwartek", "pi\u0105tek", "sobota"],
          a = ["w nocy", "rano", "po po\u0142udniu", "wieczorem"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          var t = e.getHours();
          return 17 <= t ? a[3] : 12 <= t ? a[2] : 4 <= t ? a[1] : a[0];
        }
      };
      return e.a = e.A, e.aa = e.A, ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e).toString();
        };
      }), {
        formatters: e,
        formattingTokensRegExp: s(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "menos de um segundo",
          other: "menos de {{count}} segundos"
        },
        xSeconds: {
          one: "1 segundo",
          other: "{{count}} segundos"
        },
        halfAMinute: "meio minuto",
        lessThanXMinutes: {
          one: "menos de um minuto",
          other: "menos de {{count}} minutos"
        },
        xMinutes: {
          one: "1 minuto",
          other: "{{count}} minutos"
        },
        aboutXHours: {
          one: "aproximadamente 1 hora",
          other: "aproximadamente {{count}} horas"
        },
        xHours: {
          one: "1 hora",
          other: "{{count}} horas"
        },
        xDays: {
          one: "1 dia",
          other: "{{count}} dias"
        },
        aboutXMonths: {
          one: "aproximadamente 1 m\xEAs",
          other: "aproximadamente {{count}} meses"
        },
        xMonths: {
          one: "1 m\xEAs",
          other: "{{count}} meses"
        },
        aboutXYears: {
          one: "aproximadamente 1 ano",
          other: "aproximadamente {{count}} anos"
        },
        xYears: {
          one: "1 ano",
          other: "{{count}} anos"
        },
        overXYears: {
          one: "mais de 1 ano",
          other: "mais de {{count}} anos"
        },
        almostXYears: {
          one: "quase 1 ano",
          other: "quase {{count}} anos"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "daqui a " + o : "h\xE1 " + o : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
          n = ["janeiro", "fevereiro", "mar\xE7o", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
          o = ["do", "se", "te", "qa", "qi", "se", "sa"],
          r = ["dom", "seg", "ter", "qua", "qui", "sex", "s\xE1b"],
          i = ["domingo", "segunda-feira", "ter\xE7a-feira", "quarta-feira", "quinta-feira", "sexta-feira", "s\xE1bado"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + "\xBA";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "mai pu\u021Bin de o secund\u0103",
          other: "mai pu\u021Bin de {{count}} secunde"
        },
        xSeconds: {
          one: "1 secund\u0103",
          other: "{{count}} secunde"
        },
        halfAMinute: "jum\u0103tate de minut",
        lessThanXMinutes: {
          one: "mai pu\u021Bin de un minut",
          other: "mai pu\u021Bin de {{count}} minute"
        },
        xMinutes: {
          one: "1 minut",
          other: "{{count}} minute"
        },
        aboutXHours: {
          one: "circa 1 or\u0103",
          other: "circa {{count}} ore"
        },
        xHours: {
          one: "1 or\u0103",
          other: "{{count}} ore"
        },
        xDays: {
          one: "1 zi",
          other: "{{count}} zile"
        },
        aboutXMonths: {
          one: "circa 1 lun\u0103",
          other: "circa {{count}} luni"
        },
        xMonths: {
          one: "1 lun\u0103",
          other: "{{count}} luni"
        },
        aboutXYears: {
          one: "circa 1 an",
          other: "circa {{count}} ani"
        },
        xYears: {
          one: "1 an",
          other: "{{count}} ani"
        },
        overXYears: {
          one: "peste 1 an",
          other: "peste {{count}} ani"
        },
        almostXYears: {
          one: "aproape 1 an",
          other: "aproape {{count}} ani"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "\xEEn " + o : o + " \xEEn urm\u0103" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["ian", "feb", "mar", "apr", "mai", "iun", "iul", "aug", "sep", "oct", "noi", "dec"],
          n = ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"],
          o = ["du", "lu", "ma", "mi", "jo", "vi", "s\xE2"],
          r = ["dum", "lun", "mar", "mie", "joi", "vin", "s\xE2m"],
          i = ["duminic\u0103", "luni", "mar\u021Bi", "miercuri", "joi", "vineri", "s\xE2mb\u0103ta"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e).toString();
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    function o(e, t) {
      if (void 0 !== e.one && 1 === t) return e.one;
      var n = t % 10,
          o = t % 100;
      return 1 === n && 11 !== o ? e.singularNominative.replace("{{count}}", t) : 2 <= n && n <= 4 && (o < 10 || 20 < o) ? e.singularGenitive.replace("{{count}}", t) : e.pluralGenitive.replace("{{count}}", t);
    }

    function n(n) {
      return function (e, t) {
        return t.addSuffix ? 0 < t.comparison ? n.future ? o(n.future, e) : "\u0447\u0435\u0440\u0435\u0437 " + o(n.regular, e) : n.past ? o(n.past, e) : o(n.regular, e) + " \u043D\u0430\u0437\u0430\u0434" : o(n.regular, e);
      };
    }

    e.exports = function () {
      var o = {
        lessThanXSeconds: n({
          regular: {
            one: "\u043C\u0435\u043D\u044C\u0448\u0435 \u0441\u0435\u043A\u0443\u043D\u0434\u044B",
            singularNominative: "\u043C\u0435\u043D\u044C\u0448\u0435 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u044B",
            singularGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434",
            pluralGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434"
          },
          future: {
            one: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 \u0441\u0435\u043A\u0443\u043D\u0434\u0443",
            singularNominative: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0443",
            singularGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u044B",
            pluralGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434"
          }
        }),
        xSeconds: n({
          regular: {
            singularNominative: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0430",
            singularGenitive: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u044B",
            pluralGenitive: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434"
          },
          past: {
            singularNominative: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0443 \u043D\u0430\u0437\u0430\u0434",
            singularGenitive: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u044B \u043D\u0430\u0437\u0430\u0434",
            pluralGenitive: "{{count}} \u0441\u0435\u043A\u0443\u043D\u0434 \u043D\u0430\u0437\u0430\u0434"
          },
          future: {
            singularNominative: "\u0447\u0435\u0440\u0435\u0437 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u0443",
            singularGenitive: "\u0447\u0435\u0440\u0435\u0437 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434\u044B",
            pluralGenitive: "\u0447\u0435\u0440\u0435\u0437 {{count}} \u0441\u0435\u043A\u0443\u043D\u0434"
          }
        }),
        halfAMinute: function (e, t) {
          return t.addSuffix ? 0 < t.comparison ? "\u0447\u0435\u0440\u0435\u0437 \u043F\u043E\u043B\u043C\u0438\u043D\u0443\u0442\u044B" : "\u043F\u043E\u043B\u043C\u0438\u043D\u0443\u0442\u044B \u043D\u0430\u0437\u0430\u0434" : "\u043F\u043E\u043B\u043C\u0438\u043D\u0443\u0442\u044B";
        },
        lessThanXMinutes: n({
          regular: {
            one: "\u043C\u0435\u043D\u044C\u0448\u0435 \u043C\u0438\u043D\u0443\u0442\u044B",
            singularNominative: "\u043C\u0435\u043D\u044C\u0448\u0435 {{count}} \u043C\u0438\u043D\u0443\u0442\u044B",
            singularGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435 {{count}} \u043C\u0438\u043D\u0443\u0442",
            pluralGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435 {{count}} \u043C\u0438\u043D\u0443\u0442"
          },
          future: {
            one: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 \u043C\u0438\u043D\u0443\u0442\u0443",
            singularNominative: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0438\u043D\u0443\u0442\u0443",
            singularGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0438\u043D\u0443\u0442\u044B",
            pluralGenitive: "\u043C\u0435\u043D\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0438\u043D\u0443\u0442"
          }
        }),
        xMinutes: n({
          regular: {
            singularNominative: "{{count}} \u043C\u0438\u043D\u0443\u0442\u0430",
            singularGenitive: "{{count}} \u043C\u0438\u043D\u0443\u0442\u044B",
            pluralGenitive: "{{count}} \u043C\u0438\u043D\u0443\u0442"
          },
          past: {
            singularNominative: "{{count}} \u043C\u0438\u043D\u0443\u0442\u0443 \u043D\u0430\u0437\u0430\u0434",
            singularGenitive: "{{count}} \u043C\u0438\u043D\u0443\u0442\u044B \u043D\u0430\u0437\u0430\u0434",
            pluralGenitive: "{{count}} \u043C\u0438\u043D\u0443\u0442 \u043D\u0430\u0437\u0430\u0434"
          },
          future: {
            singularNominative: "\u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0438\u043D\u0443\u0442\u0443",
            singularGenitive: "\u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0438\u043D\u0443\u0442\u044B",
            pluralGenitive: "\u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0438\u043D\u0443\u0442"
          }
        }),
        aboutXHours: n({
          regular: {
            singularNominative: "\u043E\u043A\u043E\u043B\u043E {{count}} \u0447\u0430\u0441\u0430",
            singularGenitive: "\u043E\u043A\u043E\u043B\u043E {{count}} \u0447\u0430\u0441\u043E\u0432",
            pluralGenitive: "\u043E\u043A\u043E\u043B\u043E {{count}} \u0447\u0430\u0441\u043E\u0432"
          },
          future: {
            singularNominative: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u0447\u0430\u0441",
            singularGenitive: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u0447\u0430\u0441\u0430",
            pluralGenitive: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u0447\u0430\u0441\u043E\u0432"
          }
        }),
        xHours: n({
          regular: {
            singularNominative: "{{count}} \u0447\u0430\u0441",
            singularGenitive: "{{count}} \u0447\u0430\u0441\u0430",
            pluralGenitive: "{{count}} \u0447\u0430\u0441\u043E\u0432"
          }
        }),
        xDays: n({
          regular: {
            singularNominative: "{{count}} \u0434\u0435\u043D\u044C",
            singularGenitive: "{{count}} \u0434\u043D\u044F",
            pluralGenitive: "{{count}} \u0434\u043D\u0435\u0439"
          }
        }),
        aboutXMonths: n({
          regular: {
            singularNominative: "\u043E\u043A\u043E\u043B\u043E {{count}} \u043C\u0435\u0441\u044F\u0446\u0430",
            singularGenitive: "\u043E\u043A\u043E\u043B\u043E {{count}} \u043C\u0435\u0441\u044F\u0446\u0435\u0432",
            pluralGenitive: "\u043E\u043A\u043E\u043B\u043E {{count}} \u043C\u0435\u0441\u044F\u0446\u0435\u0432"
          },
          future: {
            singularNominative: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0435\u0441\u044F\u0446",
            singularGenitive: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0435\u0441\u044F\u0446\u0430",
            pluralGenitive: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u043C\u0435\u0441\u044F\u0446\u0435\u0432"
          }
        }),
        xMonths: n({
          regular: {
            singularNominative: "{{count}} \u043C\u0435\u0441\u044F\u0446",
            singularGenitive: "{{count}} \u043C\u0435\u0441\u044F\u0446\u0430",
            pluralGenitive: "{{count}} \u043C\u0435\u0441\u044F\u0446\u0435\u0432"
          }
        }),
        aboutXYears: n({
          regular: {
            singularNominative: "\u043E\u043A\u043E\u043B\u043E {{count}} \u0433\u043E\u0434\u0430",
            singularGenitive: "\u043E\u043A\u043E\u043B\u043E {{count}} \u043B\u0435\u0442",
            pluralGenitive: "\u043E\u043A\u043E\u043B\u043E {{count}} \u043B\u0435\u0442"
          },
          future: {
            singularNominative: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u0433\u043E\u0434",
            singularGenitive: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u0433\u043E\u0434\u0430",
            pluralGenitive: "\u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0447\u0435\u0440\u0435\u0437 {{count}} \u043B\u0435\u0442"
          }
        }),
        xYears: n({
          regular: {
            singularNominative: "{{count}} \u0433\u043E\u0434",
            singularGenitive: "{{count}} \u0433\u043E\u0434\u0430",
            pluralGenitive: "{{count}} \u043B\u0435\u0442"
          }
        }),
        overXYears: n({
          regular: {
            singularNominative: "\u0431\u043E\u043B\u044C\u0448\u0435 {{count}} \u0433\u043E\u0434\u0430",
            singularGenitive: "\u0431\u043E\u043B\u044C\u0448\u0435 {{count}} \u043B\u0435\u0442",
            pluralGenitive: "\u0431\u043E\u043B\u044C\u0448\u0435 {{count}} \u043B\u0435\u0442"
          },
          future: {
            singularNominative: "\u0431\u043E\u043B\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u0433\u043E\u0434",
            singularGenitive: "\u0431\u043E\u043B\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u0433\u043E\u0434\u0430",
            pluralGenitive: "\u0431\u043E\u043B\u044C\u0448\u0435, \u0447\u0435\u043C \u0447\u0435\u0440\u0435\u0437 {{count}} \u043B\u0435\u0442"
          }
        }),
        almostXYears: n({
          regular: {
            singularNominative: "\u043F\u043E\u0447\u0442\u0438 {{count}} \u0433\u043E\u0434",
            singularGenitive: "\u043F\u043E\u0447\u0442\u0438 {{count}} \u0433\u043E\u0434\u0430",
            pluralGenitive: "\u043F\u043E\u0447\u0442\u0438 {{count}} \u043B\u0435\u0442"
          },
          future: {
            singularNominative: "\u043F\u043E\u0447\u0442\u0438 \u0447\u0435\u0440\u0435\u0437 {{count}} \u0433\u043E\u0434",
            singularGenitive: "\u043F\u043E\u0447\u0442\u0438 \u0447\u0435\u0440\u0435\u0437 {{count}} \u0433\u043E\u0434\u0430",
            pluralGenitive: "\u043F\u043E\u0447\u0442\u0438 \u0447\u0435\u0440\u0435\u0437 {{count}} \u043B\u0435\u0442"
          }
        })
      };
      return {
        localize: function (e, t, n) {
          return n = n || {}, o[e](t, n);
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["\u044F\u043D\u0432.", "\u0444\u0435\u0432.", "\u043C\u0430\u0440\u0442", "\u0430\u043F\u0440.", "\u043C\u0430\u0439", "\u0438\u044E\u043D\u044C", "\u0438\u044E\u043B\u044C", "\u0430\u0432\u0433.", "\u0441\u0435\u043D\u0442.", "\u043E\u043A\u0442.", "\u043D\u043E\u044F\u0431.", "\u0434\u0435\u043A."],
          n = ["\u044F\u043D\u0432\u0430\u0440\u044C", "\u0444\u0435\u0432\u0440\u0430\u043B\u044C", "\u043C\u0430\u0440\u0442", "\u0430\u043F\u0440\u0435\u043B\u044C", "\u043C\u0430\u0439", "\u0438\u044E\u043D\u044C", "\u0438\u044E\u043B\u044C", "\u0430\u0432\u0433\u0443\u0441\u0442", "\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u044C", "\u043E\u043A\u0442\u044F\u0431\u0440\u044C", "\u043D\u043E\u044F\u0431\u0440\u044C", "\u0434\u0435\u043A\u0430\u0431\u0440\u044C"],
          o = ["\u044F\u043D\u0432\u0430\u0440\u044F", "\u0444\u0435\u0432\u0440\u0430\u043B\u044F", "\u043C\u0430\u0440\u0442\u0430", "\u0430\u043F\u0440\u0435\u043B\u044F", "\u043C\u0430\u044F", "\u0438\u044E\u043D\u044F", "\u0438\u044E\u043B\u044F", "\u0430\u0432\u0433\u0443\u0441\u0442\u0430", "\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u044F", "\u043E\u043A\u0442\u044F\u0431\u0440\u044F", "\u043D\u043E\u044F\u0431\u0440\u044F", "\u0434\u0435\u043A\u0430\u0431\u0440\u044F"],
          r = ["\u0432\u0441", "\u043F\u043D", "\u0432\u0442", "\u0441\u0440", "\u0447\u0442", "\u043F\u0442", "\u0441\u0431"],
          i = ["\u0432\u0441\u043A", "\u043F\u043D\u0434", "\u0432\u0442\u0440", "\u0441\u0440\u0434", "\u0447\u0442\u0432", "\u043F\u0442\u043D", "\u0441\u0443\u0431"],
          a = ["\u0432\u043E\u0441\u043A\u0440\u0435\u0441\u0435\u043D\u044C\u0435", "\u043F\u043E\u043D\u0435\u0434\u0435\u043B\u044C\u043D\u0438\u043A", "\u0432\u0442\u043E\u0440\u043D\u0438\u043A", "\u0441\u0440\u0435\u0434\u0430", "\u0447\u0435\u0442\u0432\u0435\u0440\u0433", "\u043F\u044F\u0442\u043D\u0438\u0446\u0430", "\u0441\u0443\u0431\u0431\u043E\u0442\u0430"],
          s = ["\u043D\u043E\u0447\u0438", "\u0443\u0442\u0440\u0430", "\u0434\u043D\u044F", "\u0432\u0435\u0447\u0435\u0440\u0430"],
          u = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return r[e.getDay()];
        },
        ddd: function (e) {
          return i[e.getDay()];
        },
        dddd: function (e) {
          return a[e.getDay()];
        },
        A: function (e) {
          var t = e.getHours();
          return 17 <= t ? s[3] : 12 <= t ? s[2] : 4 <= t ? s[1] : s[0];
        },
        Do: function (e, t) {
          return t.D(e) + "-\u0435";
        },
        Wo: function (e, t) {
          return t.W(e) + "-\u044F";
        }
      };
      return u.a = u.A, u.aa = u.A, ["M", "DDD", "d", "Q"].forEach(function (n) {
        u[n + "o"] = function (e, t) {
          return t[n](e) + "-\u0439";
        };
      }), ["D", "Do", "DD"].forEach(function (n) {
        u[n + " MMMM"] = function (e, t) {
          return (u[n] || t[n])(e, t) + " " + o[e.getMonth()];
        };
      }), {
        formatters: u,
        formattingTokensRegExp: c(u)
      };
    };
  }, function (e, t) {
    function c(e, t, n) {
      var o,
          r,
          i = (o = e, 1 === (r = t) ? o.one : 2 <= r && r <= 4 ? o.twoFour : o.other);
      return (i[n] || i).replace("{{count}}", t);
    }

    function l(e) {
      var t = "";
      return "almost" === e && (t = "takmer"), "about" === e && (t = "pribli\u017Ene"), 0 < t.length ? t + " " : "";
    }

    function d(e) {
      var t = "";
      return "lessThan" === e && (t = "menej ne\u017E"), "over" === e && (t = "viac ne\u017E"), 0 < t.length ? t + " " : "";
    }

    e.exports = function () {
      var u = {
        xSeconds: {
          one: {
            regular: "sekunda",
            past: "sekundou",
            future: "sekundu"
          },
          twoFour: {
            regular: "{{count}} sekundy",
            past: "{{count}} sekundami",
            future: "{{count}} sekundy"
          },
          other: {
            regular: "{{count}} sek\xFAnd",
            past: "{{count}} sekundami",
            future: "{{count}} sek\xFAnd"
          }
        },
        halfAMinute: {
          other: {
            regular: "pol min\xFAty",
            past: "pol min\xFAtou",
            future: "pol min\xFAty"
          }
        },
        xMinutes: {
          one: {
            regular: "min\xFAta",
            past: "min\xFAtou",
            future: "min\xFAtu"
          },
          twoFour: {
            regular: "{{count}} min\xFAty",
            past: "{{count}} min\xFAtami",
            future: "{{count}} min\xFAty"
          },
          other: {
            regular: "{{count}} min\xFAt",
            past: "{{count}} min\xFAtami",
            future: "{{count}} min\xFAt"
          }
        },
        xHours: {
          one: {
            regular: "hodina",
            past: "hodinou",
            future: "hodinu"
          },
          twoFour: {
            regular: "{{count}} hodiny",
            past: "{{count}} hodinami",
            future: "{{count}} hodiny"
          },
          other: {
            regular: "{{count}} hod\xEDn",
            past: "{{count}} hodinami",
            future: "{{count}} hod\xEDn"
          }
        },
        xDays: {
          one: {
            regular: "de\u0148",
            past: "d\u0148om",
            future: "de\u0148"
          },
          twoFour: {
            regular: "{{count}} dni",
            past: "{{count}} d\u0148ami",
            future: "{{count}} dni"
          },
          other: {
            regular: "{{count}} dn\xED",
            past: "{{count}} d\u0148ami",
            future: "{{count}} dn\xED"
          }
        },
        xMonths: {
          one: {
            regular: "mesiac",
            past: "mesiacom",
            future: "mesiac"
          },
          twoFour: {
            regular: "{{count}} mesiace",
            past: "{{count}} mesiacmi",
            future: "{{count}} mesiace"
          },
          other: {
            regular: "{{count}} mesiacov",
            past: "{{count}} mesiacmi",
            future: "{{count}} mesiacov"
          }
        },
        xYears: {
          one: {
            regular: "rok",
            past: "rokom",
            future: "rok"
          },
          twoFour: {
            regular: "{{count}} roky",
            past: "{{count}} rokmi",
            future: "{{count}} roky"
          },
          other: {
            regular: "{{count}} rokov",
            past: "{{count}} rokmi",
            future: "{{count}} rokov"
          }
        }
      };
      return {
        localize: function (e, t, n) {
          n = n || {};
          var o,
              r,
              i = (o = e, ["lessThan", "about", "over", "almost"].filter(function (e) {
            return !!o.match(new RegExp("^" + e));
          })[0] || ""),
              a = (r = e.substring(i.length)).charAt(0).toLowerCase() + r.slice(1),
              s = u[a];
          return n.addSuffix ? 0 < n.comparison ? l(i) + "za " + d(i) + c(s, t, "future") : l(i) + "pred " + d(i) + c(s, t, "past") : l(i) + d(i) + c(s, t, "regular");
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan", "feb", "mar", "apr", "m\xE1j", "j\xFAn", "j\xFAl", "aug", "sep", "okt", "nov", "dec"],
          n = ["janu\xE1r", "febru\xE1r", "marec", "apr\xEDl", "m\xE1j", "j\xFAn", "j\xFAl", "august", "september", "okt\xF3ber", "november", "december"],
          o = ["ne", "po", "ut", "st", "\u0161t", "pi", "so"],
          r = ["ne\u010F", "pon", "uto", "str", "\u0161tv", "pia", "sob"],
          i = ["nede\u013Ea", "pondelok", "utorok", "streda", "\u0161tvrtok", "piatok", "sobota"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + ".";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "manj kot sekunda",
          two: "manj kot 2 sekundi",
          three: "manj kot {{count}} sekunde",
          other: "manj kot {{count}} sekund"
        },
        xSeconds: {
          one: "1 sekunda",
          two: "2 sekundi",
          three: "{{count}} sekunde",
          other: "{{count}} sekund"
        },
        halfAMinute: "pol minute",
        lessThanXMinutes: {
          one: "manj kot minuta",
          two: "manj kot 2 minuti",
          three: "manj kot {{count}} minute",
          other: "manj kot {{count}} minut"
        },
        xMinutes: {
          one: "1 minuta",
          two: "2 minuti",
          three: "{{count}} minute",
          other: "{{count}} minut"
        },
        aboutXHours: {
          one: "pribli\u017Eno 1 ura",
          two: "pribli\u017Eno 2 uri",
          three: "pribli\u017Eno {{count}} ure",
          other: "pribli\u017Eno {{count}} ur"
        },
        xHours: {
          one: "1 ura",
          two: "2 uri",
          three: "{{count}} ure",
          other: "{{count}} ur"
        },
        xDays: {
          one: "1 dan",
          two: "2 dni",
          three: "{{count}} dni",
          other: "{{count}} dni"
        },
        aboutXMonths: {
          one: "pribli\u017Eno 1 mesec",
          two: "pribli\u017Eno 2 meseca",
          three: "pribli\u017Eno {{count}} mesece",
          other: "pribli\u017Eno {{count}} mesecev"
        },
        xMonths: {
          one: "1 mesec",
          two: "2 meseca",
          three: "{{count}} meseci",
          other: "{{count}} mesecev"
        },
        aboutXYears: {
          one: "pribli\u017Eno 1 leto",
          two: "pribli\u017Eno 2 leti",
          three: "pribli\u017Eno {{count}} leta",
          other: "pribli\u017Eno {{count}} let"
        },
        xYears: {
          one: "1 leto",
          two: "2 leti",
          three: "{{count}} leta",
          other: "{{count}} let"
        },
        overXYears: {
          one: "ve\u010D kot 1 leto",
          two: "ve\u010D kot 2 leti",
          three: "ve\u010D kot {{count}} leta",
          other: "ve\u010D kot {{count}} let"
        },
        almostXYears: {
          one: "skoraj 1 leto",
          two: "skoraj 2 leti",
          three: "skoraj {{count}} leta",
          other: "skoraj {{count}} let"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : 2 === t ? r[e].two : 3 === t || 4 === t ? r[e].three.replace("{{count}}", t) : r[e].other.replace("{{count}}", t), n.addSuffix ? (o = o.replace(/(minut|sekund|ur)(a)/, "$1o"), "xMonths" === e && (o = o.replace(/(mesec)(i)/, "$1e")), 0 < n.comparison ? "\u010Dez " + o : o + " nazaj") : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "avg", "sep", "okt", "nov", "dec"],
          n = ["januar", "februar", "marec", "april", "maj", "junij", "julij", "avgust", "september", "oktober", "november", "december"],
          o = ["ne", "po", "to", "sr", "\u010De", "pe", "so"],
          r = ["ned", "pon", "tor", "sre", "\u010Det", "pet", "sob"],
          i = ["nedelja", "ponedeljek", "torek", "sreda", "\u010Detrtek", "petek", "sobota"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["a.m.", "p.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e) + ".";
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var i = {
        lessThanXSeconds: {
          singular: "mindre \xE4n en sekund",
          plural: "mindre \xE4n {{count}} sekunder"
        },
        xSeconds: {
          singular: "en sekund",
          plural: "{{count}} sekunder"
        },
        halfAMinute: "en halv minut",
        lessThanXMinutes: {
          singular: "mindre \xE4n en minut",
          plural: "mindre \xE4n {{count}} minuter"
        },
        xMinutes: {
          singular: "en minut",
          plural: "{{count}} minuter"
        },
        aboutXHours: {
          singular: "ungef\xE4r en timme",
          plural: "ungef\xE4r {{count}} timmar"
        },
        xHours: {
          singular: "en timme",
          plural: "{{count}} timmar"
        },
        xDays: {
          singular: "en dag",
          plural: "{{count}} dagar"
        },
        aboutXMonths: {
          singular: "ungef\xE4r en m\xE5nad",
          plural: "ungef\xE4r {{count}} m\xE5nader"
        },
        xMonths: {
          singular: "en m\xE5nad",
          plural: "{{count}} m\xE5nader"
        },
        aboutXYears: {
          singular: "ungef\xE4r ett \xE5r",
          plural: "ungef\xE4r {{count}} \xE5r"
        },
        xYears: {
          singular: "ett \xE5r",
          plural: "{{count}} \xE5r"
        },
        overXYears: {
          singular: "\xF6ver ett \xE5r",
          plural: "\xF6ver {{count}} \xE5r"
        },
        almostXYears: {
          singular: "n\xE4stan ett \xE5r",
          plural: "n\xE4stan {{count}} \xE5r"
        }
      },
          a = ["noll", "en", "tv\xE5", "tre", "fyra", "fem", "sex", "sju", "\xE5tta", "nio", "tio", "elva", "tolv"];
      return {
        localize: function (e, t, n) {
          n = n || {};
          var o,
              r = i[e];
          return o = "string" == typeof r ? r : 0 === t || 1 < t ? r.plural.replace("{{count}}", t < 13 ? a[t] : t) : r.singular, n.addSuffix ? 0 < n.comparison ? "om " + o : o + " sedan" : o;
        }
      };
    };
  }, function (e, t, n) {
    var s = n(1);

    e.exports = function () {
      var t = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
          n = ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"],
          o = ["s\xF6", "m\xE5", "ti", "on", "to", "fr", "l\xF6"],
          r = ["s\xF6n", "m\xE5n", "tis", "ons", "tor", "fre", "l\xF6r"],
          i = ["s\xF6ndag", "m\xE5ndag", "tisdag", "onsdag", "torsdag", "fredag", "l\xF6rdag"],
          a = ["f.m.", "e.m."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        }
      };
      return e.A = e.aa, e.a = e.aa, ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return function (e) {
            var t = e % 100;
            if (20 < t || t < 10) switch (t % 10) {
              case 1:
              case 2:
                return e + ":a";
            }
            return e + ":e";
          }(t[n](e));
        };
      }), {
        formatters: e,
        formattingTokensRegExp: s(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "\u0E19\u0E49\u0E2D\u0E22\u0E01\u0E27\u0E48\u0E32 1 \u0E27\u0E34\u0E19\u0E32\u0E17\u0E35",
          other: "\u0E19\u0E49\u0E2D\u0E22\u0E01\u0E27\u0E48\u0E32 {{count}} \u0E27\u0E34\u0E19\u0E32\u0E17\u0E35"
        },
        xSeconds: {
          one: "1 \u0E27\u0E34\u0E19\u0E32\u0E17\u0E35",
          other: "{{count}} \u0E27\u0E34\u0E19\u0E32\u0E17\u0E35"
        },
        halfAMinute: "\u0E04\u0E23\u0E36\u0E48\u0E07\u0E19\u0E32\u0E17\u0E35",
        lessThanXMinutes: {
          one: "\u0E19\u0E49\u0E2D\u0E22\u0E01\u0E27\u0E48\u0E32 1 \u0E19\u0E32\u0E17\u0E35",
          other: "\u0E19\u0E49\u0E2D\u0E22\u0E01\u0E27\u0E48\u0E32 {{count}} \u0E19\u0E32\u0E17\u0E35"
        },
        xMinutes: {
          one: "1 \u0E19\u0E32\u0E17\u0E35",
          other: "{{count}} \u0E19\u0E32\u0E17\u0E35"
        },
        aboutXHours: {
          one: "\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13 1 \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07",
          other: "\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13 {{count}} \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07"
        },
        xHours: {
          one: "1 \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07",
          other: "{{count}} \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07"
        },
        xDays: {
          one: "1 \u0E27\u0E31\u0E19",
          other: "{{count}} \u0E27\u0E31\u0E19"
        },
        aboutXMonths: {
          one: "\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13 1 \u0E40\u0E14\u0E37\u0E2D\u0E19",
          other: "\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13 {{count}} \u0E40\u0E14\u0E37\u0E2D\u0E19"
        },
        xMonths: {
          one: "1 \u0E40\u0E14\u0E37\u0E2D\u0E19",
          other: "{{count}} \u0E40\u0E14\u0E37\u0E2D\u0E19"
        },
        aboutXYears: {
          one: "\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13 1 \u0E1B\u0E35",
          other: "\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13 {{count}} \u0E1B\u0E35"
        },
        xYears: {
          one: "1 \u0E1B\u0E35",
          other: "{{count}} \u0E1B\u0E35"
        },
        overXYears: {
          one: "\u0E21\u0E32\u0E01\u0E01\u0E27\u0E48\u0E32 1 \u0E1B\u0E35",
          other: "\u0E21\u0E32\u0E01\u0E01\u0E27\u0E48\u0E32 {{count}} \u0E1B\u0E35"
        },
        almostXYears: {
          one: "\u0E40\u0E01\u0E37\u0E2D\u0E1A 1 \u0E1B\u0E35",
          other: "\u0E40\u0E01\u0E37\u0E2D\u0E1A {{count}} \u0E1B\u0E35"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? "halfAMinute" === e ? "\u0E43\u0E19" + o : "\u0E43\u0E19 " + o : o + "\u0E17\u0E35\u0E48\u0E1C\u0E48\u0E32\u0E19\u0E21\u0E32" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["\u0E21.\u0E04.", "\u0E01.\u0E1E.", "\u0E21\u0E35.\u0E04.", "\u0E40\u0E21.\u0E22.", "\u0E1E.\u0E04.", "\u0E21\u0E34.\u0E22.", "\u0E01.\u0E04.", "\u0E2A.\u0E04.", "\u0E01.\u0E22.", "\u0E15.\u0E04.", "\u0E1E.\u0E22.", "\u0E18.\u0E04."],
          n = ["\u0E21\u0E01\u0E23\u0E32\u0E04\u0E32\u0E21", "\u0E01\u0E38\u0E21\u0E20\u0E32\u0E1E\u0E31\u0E19\u0E18\u0E4C", "\u0E21\u0E35\u0E19\u0E32\u0E04\u0E21", "\u0E40\u0E21\u0E29\u0E32\u0E22\u0E19", "\u0E1E\u0E24\u0E29\u0E20\u0E32\u0E04\u0E21", "\u0E21\u0E34\u0E16\u0E38\u0E19\u0E32\u0E22\u0E19", "\u0E01\u0E23\u0E01\u0E0E\u0E32\u0E04\u0E21", "\u0E2A\u0E34\u0E07\u0E2B\u0E32\u0E04\u0E21", "\u0E01\u0E31\u0E19\u0E22\u0E32\u0E22\u0E19", "\u0E15\u0E38\u0E25\u0E32\u0E04\u0E21", "\u0E1E\u0E24\u0E28\u0E08\u0E34\u0E01\u0E32\u0E22\u0E19", "\u0E18\u0E31\u0E19\u0E27\u0E32\u0E04\u0E21"],
          o = ["\u0E2D\u0E32.", "\u0E08.", "\u0E2D.", "\u0E1E.", "\u0E1E\u0E24.", "\u0E28.", "\u0E2A."],
          r = ["\u0E2D\u0E32.", "\u0E08.", "\u0E2D.", "\u0E1E.", "\u0E1E\u0E24.", "\u0E28.", "\u0E2A."],
          i = ["\u0E2D\u0E32\u0E17\u0E34\u0E15\u0E22\u0E4C", "\u0E08\u0E31\u0E19\u0E17\u0E23\u0E4C", "\u0E2D\u0E31\u0E07\u0E04\u0E32\u0E23", "\u0E1E\u0E38\u0E18", "\u0E1E\u0E24\u0E2B\u0E31\u0E2A\u0E1A\u0E14\u0E35", "\u0E28\u0E38\u0E01\u0E23\u0E4C", "\u0E40\u0E2A\u0E32\u0E23\u0E4C"],
          a = ["\u0E19."],
          s = ["\u0E19."],
          u = ["\u0E19\u0E32\u0E2C\u0E34\u0E01\u0E32"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return a[0];
        },
        a: function (e) {
          return s[0];
        },
        aa: function (e) {
          return u[0];
        }
      };
      return {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var i = {
        lessThanXSeconds: {
          one: "bir saniyeden az",
          other: "{{count}} saniyeden az"
        },
        xSeconds: {
          one: "1 saniye",
          other: "{{count}} saniye"
        },
        halfAMinute: "yar\u0131m dakika",
        lessThanXMinutes: {
          one: "bir dakikadan az",
          other: "{{count}} dakikadan az"
        },
        xMinutes: {
          one: "1 dakika",
          other: "{{count}} dakika"
        },
        aboutXHours: {
          one: "yakla\u015F\u0131k 1 saat",
          other: "yakla\u015F\u0131k {{count}} saat"
        },
        xHours: {
          one: "1 saat",
          other: "{{count}} saat"
        },
        xDays: {
          one: "1 g\xFCn",
          other: "{{count}} g\xFCn"
        },
        aboutXMonths: {
          one: "yakla\u015F\u0131k 1 ay",
          other: "yakla\u015F\u0131k {{count}} ay"
        },
        xMonths: {
          one: "1 ay",
          other: "{{count}} ay"
        },
        aboutXYears: {
          one: "yakla\u015F\u0131k 1 y\u0131l",
          other: "yakla\u015F\u0131k {{count}} y\u0131l"
        },
        xYears: {
          one: "1 y\u0131l",
          other: "{{count}} y\u0131l"
        },
        overXYears: {
          one: "1 y\u0131ldan fazla",
          other: "{{count}} y\u0131ldan fazla"
        },
        almostXYears: {
          one: "neredeyse 1 y\u0131l",
          other: "neredeyse {{count}} y\u0131l"
        }
      },
          a = ["lessThanXSeconds", "lessThanXMinutes", "overXYears"];
      return {
        localize: function (e, t, n) {
          var o;

          if (n = n || {}, o = "string" == typeof i[e] ? i[e] : 1 === t ? i[e].one : i[e].other.replace("{{count}}", t), n.addSuffix) {
            var r = "";
            return -1 < a.indexOf(e) && (r = " bir s\xFCre"), 0 < n.comparison ? o + r + " i\xE7inde" : o + r + " \xF6nce";
          }

          return o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["Oca", "\u015Eub", "Mar", "Nis", "May", "Haz", "Tem", "A\u011Fu", "Eyl", "Eki", "Kas", "Ara"],
          n = ["Ocak", "\u015Eubat", "Mart", "Nisan", "May\u0131s", "Haziran", "Temmuz", "A\u011Fustos", "Eyl\xFCl", "Ekim", "Kas\u0131m", "Aral\u0131k"],
          o = ["Pz", "Pt", "Sa", "\xC7a", "Pe", "Cu", "Ct"],
          r = ["Paz", "Pts", "Sal", "\xC7ar", "Per", "Cum", "Cts"],
          i = ["Pazar", "Pazartesi", "Sal\u0131", "\xC7ar\u015Famba", "Per\u015Fembe", "Cuma", "Cumartesi"],
          a = ["\xD6\xD6", "\xD6S"],
          s = ["\xF6\xF6", "\xF6s"],
          u = ["\xF6.\xF6.", "\xF6.s."],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return function (e) {
            var t = {
              1: "'inci",
              2: "'inci",
              3: "'\xFCnc\xFC",
              4: "'\xFCnc\xFC",
              5: "'inci",
              6: "'\u0131nc\u0131",
              7: "'inci",
              8: "'inci",
              9: "'uncu",
              10: "'uncu",
              20: "'inci",
              30: "'uncu",
              50: "'inci",
              60: "'\u0131nc\u0131",
              70: "'inci",
              80: "'inci",
              90: "'\u0131nc\u0131",
              100: "'\xFCnc\xFC"
            };
            if (0 === e) return "0'\u0131nc\u0131";
            var n = e % 10,
                o = e % 100 - n,
                r = 100 <= e ? 100 : null;
            return e + (t[n] || t[o] || t[r]);
          }(t[n](e));
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "\u4E0D\u5230 1 \u79D2",
          other: "\u4E0D\u5230 {{count}} \u79D2"
        },
        xSeconds: {
          one: "1 \u79D2",
          other: "{{count}} \u79D2"
        },
        halfAMinute: "\u534A\u5206\u949F",
        lessThanXMinutes: {
          one: "\u4E0D\u5230 1 \u5206\u949F",
          other: "\u4E0D\u5230 {{count}} \u5206\u949F"
        },
        xMinutes: {
          one: "1 \u5206\u949F",
          other: "{{count}} \u5206\u949F"
        },
        xHours: {
          one: "1 \u5C0F\u65F6",
          other: "{{count}} \u5C0F\u65F6"
        },
        aboutXHours: {
          one: "\u5927\u7EA6 1 \u5C0F\u65F6",
          other: "\u5927\u7EA6 {{count}} \u5C0F\u65F6"
        },
        xDays: {
          one: "1 \u5929",
          other: "{{count}} \u5929"
        },
        aboutXMonths: {
          one: "\u5927\u7EA6 1 \u4E2A\u6708",
          other: "\u5927\u7EA6 {{count}} \u4E2A\u6708"
        },
        xMonths: {
          one: "1 \u4E2A\u6708",
          other: "{{count}} \u4E2A\u6708"
        },
        aboutXYears: {
          one: "\u5927\u7EA6 1 \u5E74",
          other: "\u5927\u7EA6 {{count}} \u5E74"
        },
        xYears: {
          one: "1 \u5E74",
          other: "{{count}} \u5E74"
        },
        overXYears: {
          one: "\u8D85\u8FC7 1 \u5E74",
          other: "\u8D85\u8FC7 {{count}} \u5E74"
        },
        almostXYears: {
          one: "\u5C06\u8FD1 1 \u5E74",
          other: "\u5C06\u8FD1 {{count}} \u5E74"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? o + "\u5185" : o + "\u524D" : o;
        }
      };
    };
  }, function (e, t, n) {
    var s = n(1);

    e.exports = function () {
      var t = ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"],
          n = ["\u4E00\u6708", "\u4E8C\u6708", "\u4E09\u6708", "\u56DB\u6708", "\u4E94\u6708", "\u516D\u6708", "\u4E03\u6708", "\u516B\u6708", "\u4E5D\u6708", "\u5341\u6708", "\u5341\u4E00\u6708", "\u5341\u4E8C\u6708"],
          o = ["\u65E5", "\u4E00", "\u4E8C", "\u4E09", "\u56DB", "\u4E94", "\u516D"],
          r = ["\u5468\u65E5", "\u5468\u4E00", "\u5468\u4E8C", "\u5468\u4E09", "\u5468\u56DB", "\u5468\u4E94", "\u5468\u516D"],
          i = ["\u661F\u671F\u65E5", "\u661F\u671F\u4E00", "\u661F\u671F\u4E8C", "\u661F\u671F\u4E09", "\u661F\u671F\u56DB", "\u661F\u671F\u4E94", "\u661F\u671F\u516D"],
          a = ["\u4E0A\u5348", "\u4E0B\u5348"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        }
      };
      return e.a = e.aa = e.A = function (e) {
        return 1 <= e.getHours() / 12 ? a[1] : a[0];
      }, ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e).toString();
        };
      }), {
        formatters: e,
        formattingTokensRegExp: s(e)
      };
    };
  }, function (e, t) {
    e.exports = function () {
      var r = {
        lessThanXSeconds: {
          one: "\u5C11\u65BC 1 \u79D2",
          other: "\u5C11\u65BC {{count}} \u79D2"
        },
        xSeconds: {
          one: "1 \u79D2",
          other: "{{count}} \u79D2"
        },
        halfAMinute: "\u534A\u5206\u9418",
        lessThanXMinutes: {
          one: "\u5C11\u65BC 1 \u5206\u9418",
          other: "\u5C11\u65BC {{count}} \u5206\u9418"
        },
        xMinutes: {
          one: "1 \u5206\u9418",
          other: "{{count}} \u5206\u9418"
        },
        xHours: {
          one: "1 \u5C0F\u6642",
          other: "{{count}} \u5C0F\u6642"
        },
        aboutXHours: {
          one: "\u5927\u7D04 1 \u5C0F\u6642",
          other: "\u5927\u7D04 {{count}} \u5C0F\u6642"
        },
        xDays: {
          one: "1 \u5929",
          other: "{{count}} \u5929"
        },
        aboutXMonths: {
          one: "\u5927\u7D04 1 \u500B\u6708",
          other: "\u5927\u7D04 {{count}} \u500B\u6708"
        },
        xMonths: {
          one: "1 \u500B\u6708",
          other: "{{count}} \u500B\u6708"
        },
        aboutXYears: {
          one: "\u5927\u7D04 1 \u5E74",
          other: "\u5927\u7D04 {{count}} \u5E74"
        },
        xYears: {
          one: "1 \u5E74",
          other: "{{count}} \u5E74"
        },
        overXYears: {
          one: "\u8D85\u904E 1 \u5E74",
          other: "\u8D85\u904E {{count}} \u5E74"
        },
        almostXYears: {
          one: "\u5C07\u8FD1 1 \u5E74",
          other: "\u5C07\u8FD1 {{count}} \u5E74"
        }
      };
      return {
        localize: function (e, t, n) {
          var o;
          return n = n || {}, o = "string" == typeof r[e] ? r[e] : 1 === t ? r[e].one : r[e].other.replace("{{count}}", t), n.addSuffix ? 0 < n.comparison ? o + "\u5167" : o + "\u524D" : o;
        }
      };
    };
  }, function (e, t, n) {
    var c = n(1);

    e.exports = function () {
      var t = ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"],
          n = ["\u4E00\u6708", "\u4E8C\u6708", "\u4E09\u6708", "\u56DB\u6708", "\u4E94\u6708", "\u516D\u6708", "\u4E03\u6708", "\u516B\u6708", "\u4E5D\u6708", "\u5341\u6708", "\u5341\u4E00\u6708", "\u5341\u4E8C\u6708"],
          o = ["\u65E5", "\u4E00", "\u4E8C", "\u4E09", "\u56DB", "\u4E94", "\u516D"],
          r = ["\u5468\u65E5", "\u5468\u4E00", "\u5468\u4E8C", "\u5468\u4E09", "\u5468\u56DB", "\u5468\u4E94", "\u5468\u516D"],
          i = ["\u661F\u671F\u65E5", "\u661F\u671F\u4E00", "\u661F\u671F\u4E8C", "\u661F\u671F\u4E09", "\u661F\u671F\u56DB", "\u661F\u671F\u4E94", "\u661F\u671F\u516D"],
          a = ["AM", "PM"],
          s = ["am", "pm"],
          u = ["\u4E0A\u5348", "\u4E0B\u5348"],
          e = {
        MMM: function (e) {
          return t[e.getMonth()];
        },
        MMMM: function (e) {
          return n[e.getMonth()];
        },
        dd: function (e) {
          return o[e.getDay()];
        },
        ddd: function (e) {
          return r[e.getDay()];
        },
        dddd: function (e) {
          return i[e.getDay()];
        },
        A: function (e) {
          return 1 <= e.getHours() / 12 ? a[1] : a[0];
        },
        a: function (e) {
          return 1 <= e.getHours() / 12 ? s[1] : s[0];
        },
        aa: function (e) {
          return 1 <= e.getHours() / 12 ? u[1] : u[0];
        }
      };
      return ["M", "D", "DDD", "d", "Q", "W"].forEach(function (n) {
        e[n + "o"] = function (e, t) {
          return t[n](e).toString();
        };
      }), {
        formatters: e,
        formattingTokensRegExp: c(e)
      };
    };
  }, function (e, t, n) {
    var a = n(0);

    e.exports = function (e, t) {
      var n = t && Number(t.weekStartsOn) || 0,
          o = a(e),
          r = o.getDay(),
          i = (r < n ? 7 : 0) + r - n;
      return o.setDate(o.getDate() - i), o.setHours(0, 0, 0, 0), o;
    };
  }, function (e, t, n) {
    var a = n(4);

    e.exports = function (e, t) {
      var n = a(e),
          o = a(t),
          r = n.getTime() - 60000 * n.getTimezoneOffset(),
          i = o.getTime() - 60000 * o.getTimezoneOffset();
      return Math.round((r - i) / 86400000);
    };
  }, function (e, t, n) {
    var s = n(0),
        u = n(116);

    e.exports = function (e, t) {
      var n = s(e),
          o = Number(t),
          r = n.getMonth() + o,
          i = new Date(0);
      i.setFullYear(n.getFullYear(), r, 1), i.setHours(0, 0, 0, 0);
      var a = u(i);
      return n.setMonth(r, Math.min(a, n.getDate())), n;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() - o.getTime();
    };
  }, function (e, t, n) {
    var o = n(12),
        r = n(13);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(14),
        r = n(15);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(16),
        r = n(17);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(18),
        r = n(19);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(20),
        r = n(21);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(22),
        r = n(23);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(24),
        r = n(25);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(26),
        r = n(27);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(28),
        r = n(29);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(30),
        r = n(31);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(32),
        r = n(33);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(34),
        r = n(35);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(36),
        r = n(37);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(38),
        r = n(39);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(40),
        r = n(41);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(42),
        r = n(43);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(44),
        r = n(45);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(46),
        r = n(47);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(48),
        r = n(49);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(50),
        r = n(51);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(52),
        r = n(53);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(54),
        r = n(55);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(56),
        r = n(57);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(58),
        r = n(59);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(60),
        r = n(61);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(62),
        r = n(63);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(64),
        r = n(65);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(66),
        r = n(67);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(68),
        r = n(69);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(70),
        r = n(71);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(72),
        r = n(73);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(74),
        r = n(75);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t, n) {
    var o = n(76),
        r = n(77);
    e.exports = {
      distanceInWords: o(),
      format: r()
    };
  }, function (e, t) {
    e.exports = function (e) {
      return e instanceof Date;
    };
  }, function (e, t, n) {
    var i = n(0);

    e.exports = function (e) {
      var t = i(e),
          n = t.getFullYear(),
          o = t.getMonth(),
          r = new Date(0);
      return r.setFullYear(n, o + 1, 0), r.setHours(0, 0, 0, 0), r.getDate();
    };
  }, function (e, t, n) {
    var o = n(6);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, 7 * n);
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e).getTime(),
          o = r(t).getTime();
      return o < n ? -1 : n < o ? 1 : 0;
    };
  }, function (e, t, n) {
    var a = n(0),
        s = n(133),
        u = n(9);

    e.exports = function (e, t) {
      var n = a(e),
          o = a(t),
          r = u(n, o),
          i = Math.abs(s(n, o));
      return n.setMonth(n.getMonth() - r * i), r * (i - (u(n, o) === -r));
    };
  }, function (e, t, n) {
    var o = n(81);

    e.exports = function (e, t) {
      var n = o(e, t) / 1000;
      return 0 < n ? Math.floor(n) : Math.ceil(n);
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setHours(23, 59, 59, 999), t;
    };
  }, function (e, t, n) {
    var o = n(0),
        r = n(3),
        i = n(8);

    e.exports = function (e) {
      var t = o(e),
          n = r(t).getTime() - i(t).getTime();
      return Math.round(n / 604800000) + 1;
    };
  }, function (e, t, n) {
    var i = n(78);

    e.exports = function (e, t, n) {
      var o = i(e, n),
          r = i(t, n);
      return o.getTime() === r.getTime();
    };
  }, function (e, t, n) {
    e.exports = {
      addDays: n(6),
      addHours: n(125),
      addISOYears: n(126),
      addMilliseconds: n(7),
      addMinutes: n(128),
      addMonths: n(80),
      addQuarters: n(129),
      addSeconds: n(130),
      addWeeks: n(117),
      addYears: n(131),
      areRangesOverlapping: n(202),
      closestIndexTo: n(203),
      closestTo: n(204),
      compareAsc: n(9),
      compareDesc: n(118),
      differenceInCalendarDays: n(79),
      differenceInCalendarISOWeeks: n(205),
      differenceInCalendarISOYears: n(132),
      differenceInCalendarMonths: n(133),
      differenceInCalendarQuarters: n(206),
      differenceInCalendarWeeks: n(207),
      differenceInCalendarYears: n(135),
      differenceInDays: n(136),
      differenceInHours: n(208),
      differenceInISOYears: n(209),
      differenceInMilliseconds: n(81),
      differenceInMinutes: n(210),
      differenceInMonths: n(119),
      differenceInQuarters: n(211),
      differenceInSeconds: n(120),
      differenceInWeeks: n(212),
      differenceInYears: n(213),
      distanceInWords: n(138),
      distanceInWordsStrict: n(214),
      distanceInWordsToNow: n(215),
      eachDay: n(216),
      endOfDay: n(121),
      endOfHour: n(217),
      endOfISOWeek: n(218),
      endOfISOYear: n(219),
      endOfMinute: n(220),
      endOfMonth: n(140),
      endOfQuarter: n(221),
      endOfSecond: n(222),
      endOfToday: n(223),
      endOfTomorrow: n(224),
      endOfWeek: n(139),
      endOfYear: n(225),
      endOfYesterday: n(226),
      format: n(227),
      getDate: n(228),
      getDay: n(229),
      getDayOfYear: n(141),
      getDaysInMonth: n(116),
      getDaysInYear: n(230),
      getHours: n(231),
      getISODay: n(145),
      getISOWeek: n(122),
      getISOWeeksInYear: n(232),
      getISOYear: n(2),
      getMilliseconds: n(233),
      getMinutes: n(234),
      getMonth: n(235),
      getOverlappingDaysInRanges: n(236),
      getQuarter: n(134),
      getSeconds: n(237),
      getTime: n(238),
      getYear: n(239),
      isAfter: n(240),
      isBefore: n(241),
      isDate: n(115),
      isEqual: n(242),
      isFirstDayOfMonth: n(243),
      isFriday: n(244),
      isFuture: n(245),
      isLastDayOfMonth: n(246),
      isLeapYear: n(144),
      isMonday: n(247),
      isPast: n(248),
      isSameDay: n(249),
      isSameHour: n(146),
      isSameISOWeek: n(148),
      isSameISOYear: n(149),
      isSameMinute: n(150),
      isSameMonth: n(152),
      isSameQuarter: n(153),
      isSameSecond: n(155),
      isSameWeek: n(123),
      isSameYear: n(157),
      isSaturday: n(250),
      isSunday: n(251),
      isThisHour: n(252),
      isThisISOWeek: n(253),
      isThisISOYear: n(254),
      isThisMinute: n(255),
      isThisMonth: n(256),
      isThisQuarter: n(257),
      isThisSecond: n(258),
      isThisWeek: n(259),
      isThisYear: n(260),
      isThursday: n(261),
      isToday: n(262),
      isTomorrow: n(263),
      isTuesday: n(264),
      isValid: n(143),
      isWednesday: n(265),
      isWeekend: n(266),
      isWithinRange: n(267),
      isYesterday: n(268),
      lastDayOfISOWeek: n(269),
      lastDayOfISOYear: n(270),
      lastDayOfMonth: n(271),
      lastDayOfQuarter: n(272),
      lastDayOfWeek: n(158),
      lastDayOfYear: n(273),
      max: n(274),
      min: n(275),
      parse: n(0),
      setDate: n(276),
      setDay: n(277),
      setDayOfYear: n(278),
      setHours: n(279),
      setISODay: n(280),
      setISOWeek: n(281),
      setISOYear: n(127),
      setMilliseconds: n(282),
      setMinutes: n(283),
      setMonth: n(159),
      setQuarter: n(284),
      setSeconds: n(285),
      setYear: n(286),
      startOfDay: n(4),
      startOfHour: n(147),
      startOfISOWeek: n(3),
      startOfISOYear: n(8),
      startOfMinute: n(151),
      startOfMonth: n(287),
      startOfQuarter: n(154),
      startOfSecond: n(156),
      startOfToday: n(288),
      startOfTomorrow: n(289),
      startOfWeek: n(78),
      startOfYear: n(142),
      startOfYesterday: n(290),
      subDays: n(291),
      subHours: n(292),
      subISOYears: n(137),
      subMilliseconds: n(293),
      subMinutes: n(294),
      subMonths: n(295),
      subQuarters: n(296),
      subSeconds: n(297),
      subWeeks: n(298),
      subYears: n(299)
    };
  }, function (e, t, n) {
    var o = n(7);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, 3600000 * n);
    };
  }, function (e, t, n) {
    var o = n(2),
        r = n(127);

    e.exports = function (e, t) {
      var n = Number(t);
      return r(e, o(e) + n);
    };
  }, function (e, t, n) {
    var a = n(0),
        s = n(8),
        u = n(79);

    e.exports = function (e, t) {
      var n = a(e),
          o = Number(t),
          r = u(n, s(n)),
          i = new Date(0);
      return i.setFullYear(o, 0, 4), i.setHours(0, 0, 0, 0), (n = s(i)).setDate(n.getDate() + r), n;
    };
  }, function (e, t, n) {
    var o = n(7);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, 60000 * n);
    };
  }, function (e, t, n) {
    var o = n(80);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, 3 * n);
    };
  }, function (e, t, n) {
    var o = n(7);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, 1000 * n);
    };
  }, function (e, t, n) {
    var o = n(80);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, 12 * n);
    };
  }, function (e, t, n) {
    var o = n(2);

    e.exports = function (e, t) {
      return o(e) - o(t);
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return 12 * (n.getFullYear() - o.getFullYear()) + (n.getMonth() - o.getMonth());
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return Math.floor(t.getMonth() / 3) + 1;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getFullYear() - o.getFullYear();
    };
  }, function (e, t, n) {
    var a = n(0),
        s = n(79),
        u = n(9);

    e.exports = function (e, t) {
      var n = a(e),
          o = a(t),
          r = u(n, o),
          i = Math.abs(s(n, o));
      return n.setDate(n.getDate() - r * i), r * (i - (u(n, o) === -r));
    };
  }, function (e, t, n) {
    var o = n(126);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var v = n(118),
        g = n(0),
        _ = n(120),
        y = n(119),
        b = n(5);

    e.exports = function (e, t, n) {
      var o = n || {},
          r = v(e, t),
          i = o.locale,
          a = b.distanceInWords.localize;
      i && i.distanceInWords && i.distanceInWords.localize && (a = i.distanceInWords.localize);
      var s,
          u,
          c = {
        addSuffix: Boolean(o.addSuffix),
        comparison: r
      };
      0 < r ? (s = g(e), u = g(t)) : (s = g(t), u = g(e));

      var l,
          d = _(u, s),
          f = u.getTimezoneOffset() - s.getTimezoneOffset(),
          h = Math.round(d / 60) - f;

      if (h < 2) return o.includeSeconds ? d < 5 ? a("lessThanXSeconds", 5, c) : d < 10 ? a("lessThanXSeconds", 10, c) : d < 20 ? a("lessThanXSeconds", 20, c) : d < 40 ? a("halfAMinute", null, c) : a(d < 60 ? "lessThanXMinutes" : "xMinutes", 1, c) : 0 === h ? a("lessThanXMinutes", 1, c) : a("xMinutes", h, c);
      if (h < 45) return a("xMinutes", h, c);
      if (h < 90) return a("aboutXHours", 1, c);
      if (h < 1440) return a("aboutXHours", Math.round(h / 60), c);
      if (h < 2520) return a("xDays", 1, c);
      if (h < 43200) return a("xDays", Math.round(h / 1440), c);
      if (h < 86400) return a("aboutXMonths", l = Math.round(h / 43200), c);
      if ((l = y(u, s)) < 12) return a("xMonths", Math.round(h / 43200), c);
      var m = l % 12,
          p = Math.floor(l / 12);
      return m < 3 ? a("aboutXYears", p, c) : m < 9 ? a("overXYears", p, c) : a("almostXYears", p + 1, c);
    };
  }, function (e, t, n) {
    var a = n(0);

    e.exports = function (e, t) {
      var n = t && Number(t.weekStartsOn) || 0,
          o = a(e),
          r = o.getDay(),
          i = 6 + (r < n ? -7 : 0) - (r - n);
      return o.setDate(o.getDate() + i), o.setHours(23, 59, 59, 999), o;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e),
          n = t.getMonth();
      return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(23, 59, 59, 999), t;
    };
  }, function (e, t, n) {
    var o = n(0),
        r = n(142),
        i = n(79);

    e.exports = function (e) {
      var t = o(e);
      return i(t, r(t)) + 1;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e),
          n = new Date(0);
      return n.setFullYear(t.getFullYear(), 0, 1), n.setHours(0, 0, 0, 0), n;
    };
  }, function (e, t, n) {
    var o = n(115);

    e.exports = function (e) {
      if (o(e)) return !isNaN(e);
      throw new TypeError(toString.call(e) + " is not an instance of Date");
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e).getFullYear();
      return t % 400 == 0 || t % 4 == 0 && t % 100 != 0;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e).getDay();
      return 0 === t && (t = 7), t;
    };
  }, function (e, t, n) {
    var r = n(147);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() === o.getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setMinutes(0, 0, 0), t;
    };
  }, function (e, t, n) {
    var o = n(123);

    e.exports = function (e, t) {
      return o(e, t, {
        weekStartsOn: 1
      });
    };
  }, function (e, t, n) {
    var r = n(8);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() === o.getTime();
    };
  }, function (e, t, n) {
    var r = n(151);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() === o.getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setSeconds(0, 0), t;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getFullYear() === o.getFullYear() && n.getMonth() === o.getMonth();
    };
  }, function (e, t, n) {
    var r = n(154);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() === o.getTime();
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e) {
      var t = r(e),
          n = t.getMonth(),
          o = n - n % 3;
      return t.setMonth(o, 1), t.setHours(0, 0, 0, 0), t;
    };
  }, function (e, t, n) {
    var r = n(156);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() === o.getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setMilliseconds(0), t;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getFullYear() === o.getFullYear();
    };
  }, function (e, t, n) {
    var a = n(0);

    e.exports = function (e, t) {
      var n = t && Number(t.weekStartsOn) || 0,
          o = a(e),
          r = o.getDay(),
          i = 6 + (r < n ? -7 : 0) - (r - n);
      return o.setHours(0, 0, 0, 0), o.setDate(o.getDate() + i), o;
    };
  }, function (e, t, n) {
    var u = n(0),
        c = n(116);

    e.exports = function (e, t) {
      var n = u(e),
          o = Number(t),
          r = n.getFullYear(),
          i = n.getDate(),
          a = new Date(0);
      a.setFullYear(r, o, 15), a.setHours(0, 0, 0, 0);
      var s = c(a);
      return n.setMonth(o, Math.min(i, s)), n;
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t) {
    e.exports = {
      typings: "../../typings.d.ts"
    };
  }, function (e, t, n) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var o = n(197),
        r = n(199),
        i = n(305),
        a = n(308),
        s = n(311),
        u = n(314),
        c = n(317),
        l = n(320);
    t.default = {
      bulmaAccordion: o.a,
      bulmaCalendar: r.a,
      bulmaCarousel: i.a,
      bulmaIconpicker: a.a,
      bulmaQuickview: s.a,
      bulmaSlider: u.a,
      bulmaSteps: c.a,
      bulmaTagsinput: l.a
    };
  }, function (e, t, n) {
    "use strict";

    var r = n(198),
        i = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var a = Symbol("onBulmaAccordionClick"),
        o = function (e) {
      function o(e) {
        1 < arguments.length && void 0 !== arguments[1] && arguments[1];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, o);

        var t = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (o.__proto__ || Object.getPrototypeOf(o)).call(this || _global));

        if (t.element = "string" == typeof e ? document.querySelector(e) : e, !t.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return t._clickEvents = ["click"], t[a] = t[a].bind(t), t.init(), t;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(o, r["a"]), i(o, [{
        key: "init",
        value: function () {
          (this || _global).items = (this || _global).element.querySelectorAll(".accordion") || [], this._bindEvents();
        }
      }, {
        key: "destroy",
        value: function () {
          var n = this || _global;

          (this || _global).items.forEach(function (t) {
            n._clickEvents.forEach(function (e) {
              t.removeEventListener(e, n[a], !1);
            });
          });
        }
      }, {
        key: "_bindEvents",
        value: function () {
          var n = this || _global;

          (this || _global).items.forEach(function (t) {
            n._clickEvents.forEach(function (e) {
              t.addEventListener(e, n[a], !1);
            });
          });
        }
      }, {
        key: a,
        value: function (e) {
          e.preventDefault();
          var t = e.currentTarget.closest(".accordion") || e.currentTarget;
          if (t.classList.contains("is-active")) t.classList.remove("is-active");else {
            var n = (this || _global).element.querySelector(".accordion.is-active");

            n && n.classList.remove("is-active"), t.classList.add("is-active");
          }
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : ".accordions",
              t = new Array(),
              n = document.querySelectorAll(e);
          return [].forEach.call(n, function (e) {
            setTimeout(function () {
              t.push(new o(e));
            }, 100);
          }), t;
        }
      }]), o;
    }();

    t.a = o;
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    var o = n(200),
        u = n(201),
        c = n(124),
        i = (n.n(c), n(300)),
        a = n(301),
        s = n(302),
        l = n(303),
        d = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }

      return e;
    },
        f = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var h = Symbol("onToggleDatePicker"),
        m = Symbol("onCloseDatePicker"),
        p = Symbol("onPreviousDatePicker"),
        v = Symbol("onNextDatePicker"),
        g = Symbol("onSelectMonthDatePicker"),
        _ = Symbol("onMonthClickDatePicker"),
        y = Symbol("onSelectYearDatePicker"),
        b = Symbol("onYearClickDatePicker"),
        x = Symbol("onDateClickDatePicker"),
        M = Symbol("onDocumentClickDatePicker"),
        k = Symbol("onValidateClickDatePicker"),
        w = Symbol("onTodayClickDatePicker"),
        D = Symbol("onClearClickDatePicker"),
        S = Symbol("onCancelClickDatePicker"),
        j = !1;

    try {
      var r = Object.defineProperty({}, "passive", {
        get: function () {
          j = !0;
        }
      });
      window.addEventListener("testPassive", null, r), window.removeEventListener("testPassive", null, r);
    } catch (e) {}

    var E = function (e) {
      function r(e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, r);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (r.__proto__ || Object.getPrototypeOf(r)).call(this || _global));

        if (n.element = u.a(e) ? document.querySelector(e) : e, !n.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return n._clickEvents = ["click", "touch"], n.options = d({}, a.a, t), n[h] = n[h].bind(n), n[m] = n[m].bind(n), n[p] = n[p].bind(n), n[v] = n[v].bind(n), n[g] = n[g].bind(n), n[_] = n[_].bind(n), n[y] = n[y].bind(n), n[b] = n[b].bind(n), n[x] = n[x].bind(n), n[M] = n[M].bind(n), n[k] = n[k].bind(n), n[w] = n[w].bind(n), n[D] = n[D].bind(n), n[S] = n[S].bind(n), n._init(), n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(r, i["a"]), f(r, [{
        key: "isRange",
        value: function () {
          return (this || _global).options.isRange;
        }
      }, {
        key: "isOpen",
        value: function () {
          return (this || _global)._open;
        }
      }, {
        key: "value",
        value: function () {
          if (!(0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null)) {
            var e = "";
            return (this || _global).options.isRange ? (this || _global).startDate && this._isValidDate((this || _global).startDate) && (this || _global).endDate && this._isValidDate((this || _global).endDate) && (e = c.format((this || _global).startDate, (this || _global).dateFormat, {
              locale: (this || _global).locale
            }) + " - " + c.format((this || _global).endDate, (this || _global).dateFormat, {
              locale: (this || _global).locale
            })) : (this || _global).startDate && this._isValidDate((this || _global).startDate) && (e = c.format((this || _global).startDate, (this || _global)._dateFormat, {
              locale: (this || _global).locale
            })), this.emit("date:selected", (this || _global).date, this || _global), e;
          }

          if ((this || _global).options.isRange) {
            var t = (this || _global).element.value.split(" - ");

            t.length && ((this || _global).startDate = new Date(t[0])), 2 === t.length && ((this || _global).endDate = new Date(t[1]));
          } else (this || _global).startDate = new Date((this || _global).element.value);
        }
      }, {
        key: "clear",
        value: function () {
          this._clear();
        }
      }, {
        key: "show",
        value: function () {
          (this || _global)._snapshots = [], this._snapshot(), (this || _global).element.value && this.value((this || _global).element.value), (this || _global)._visibleDate = this._isValidDate((this || _global).startDate, (this || _global).minDate, (this || _global).maxDate) ? (this || _global).startDate : (this || _global)._visibleDate, this._refreshCalendar(), (this || _global)._ui.body.dates.classList.add("is-active"), (this || _global)._ui.body.months.classList.remove("is-active"), (this || _global)._ui.body.years.classList.remove("is-active"), (this || _global)._ui.navigation.previous.removeAttribute("disabled"), (this || _global)._ui.navigation.next.removeAttribute("disabled"), (this || _global)._ui.container.classList.add("is-active"), "default" === (this || _global).options.displayMode && this._adjustPosition(), (this || _global)._open = !0, (this || _global)._focus = !0, this.emit("show", this || _global);
        }
      }, {
        key: "hide",
        value: function () {
          (this || _global)._open = !1, (this || _global)._focus = !1, (this || _global)._ui.container.classList.remove("is-active"), this.emit("hide", this || _global);
        }
      }, {
        key: "destroy",
        value: function () {
          (this || _global)._ui.container.remove();
        }
      }, {
        key: M,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), "inline" !== (this || _global).options.displayMode && (this || _global)._open && this[m](e);
        }
      }, {
        key: h,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), (this || _global)._open ? this.hide() : this.show();
        }
      }, {
        key: k,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), this[m](e);
        }
      }, {
        key: w,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), (this || _global).options.isRange ? this._setStartAndEnd(new Date()) : (this || _global).startDate = new Date(), (this || _global)._visibleDate = (this || _global).startDate, (this || _global).element.value = this.value(), this._refreshCalendar();
        }
      }, {
        key: D,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), this._clear();
        }
      }, {
        key: S,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), (this || _global)._snapshots.length && ((this || _global).startDate = (this || _global)._snapshots[0].start, (this || _global).endDate = (this || _global)._snapshots[0].end), (this || _global).element.value = this.value(), this[m](e);
        }
      }, {
        key: m,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), this.hide();
        }
      }, {
        key: p,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation();
          var t = c.lastDayOfMonth(c.subMonths(new Date(c.getYear((this || _global)._visibleDate), c.getMonth((this || _global)._visibleDate)), 1)),
              n = Math.min(c.getDaysInMonth(t), c.getDate((this || _global)._visibleDate));
          (this || _global)._visibleDate = (this || _global).minDate ? c.max(c.setDate(t, n), (this || _global).minDate) : c.setDate(t, n), this._refreshCalendar();
        }
      }, {
        key: v,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation();
          var t = c.addMonths((this || _global)._visibleDate, 1),
              n = Math.min(c.getDaysInMonth(t), c.getDate((this || _global)._visibleDate));
          (this || _global)._visibleDate = (this || _global).maxDate ? c.min(c.setDate(t, n), (this || _global).maxDate) : c.setDate(t, n), this._refreshCalendar();
        }
      }, {
        key: x,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation(), e.currentTarget.classList.contains("is-disabled") || (this._setStartAndEnd(e.currentTarget.dataset.date), this._refreshCalendar(), ("inline" === (this || _global).options.displayMode || (this || _global).options.closeOnSelect) && ((this || _global).element.value = this.value()), (!(this || _global).options.isRange || (this || _global).startDate && this._isValidDate((this || _global).startDate) && (this || _global).endDate && this._isValidDate((this || _global).endDate)) && (this || _global).options.closeOnSelect && this.hide());
        }
      }, {
        key: g,
        value: function (e) {
          e.stopPropagation(), (this || _global)._ui.body.dates.classList.remove("is-active"), (this || _global)._ui.body.years.classList.remove("is-active"), (this || _global)._ui.body.months.classList.add("is-active"), (this || _global)._ui.navigation.previous.setAttribute("disabled", "disabled"), (this || _global)._ui.navigation.next.setAttribute("disabled", "disabled");
        }
      }, {
        key: y,
        value: function (e) {
          e.stopPropagation(), (this || _global)._ui.body.dates.classList.remove("is-active"), (this || _global)._ui.body.months.classList.remove("is-active"), (this || _global)._ui.body.years.classList.add("is-active"), (this || _global)._ui.navigation.previous.setAttribute("disabled", "disabled"), (this || _global)._ui.navigation.next.setAttribute("disabled", "disabled");

          var t = (this || _global)._ui.body.years.querySelector(".calendar-year.is-active");

          t && ((this || _global)._ui.body.years.scrollTop = t.offsetTop - (this || _global)._ui.body.years.offsetTop - (this || _global)._ui.body.years.clientHeight / 2);
        }
      }, {
        key: _,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation();
          var t = c.setMonth((this || _global)._visibleDate, parseInt(e.currentTarget.dataset.month) - 1);
          (this || _global)._visibleDate = (this || _global).minDate ? c.max(t, (this || _global).minDate) : t, (this || _global)._visibleDate = (this || _global).maxDate ? c.min((this || _global)._visibleDate, (this || _global).maxDate) : (this || _global)._visibleDate, this._refreshCalendar();
        }
      }, {
        key: b,
        value: function (e) {
          j || e.preventDefault(), e.stopPropagation();
          var t = c.setYear((this || _global)._visibleDate, parseInt(e.currentTarget.dataset.year));
          (this || _global)._visibleDate = (this || _global).minDate ? c.max(t, (this || _global).minDate) : t, (this || _global)._visibleDate = (this || _global).maxDate ? c.min((this || _global)._visibleDate, (this || _global).maxDate) : (this || _global)._visibleDate, this._refreshCalendar();
        }
      }, {
        key: "_init",
        value: function () {
          var i = this || _global;
          (this || _global)._id = o.a("datePicker"), (this || _global)._snapshots = [], "date" === (this || _global).element.getAttribute("type").toLowerCase() && (this || _global).element.setAttribute("type", "text");
          var e = (this || _global).element.dataset ? Object.keys((this || _global).element.dataset).filter(function (e) {
            return Object.keys(a.a).includes(e);
          }).reduce(function (e, t) {
            return d({}, e, (n = {}, o = t, r = i.element.dataset[t], o in n ? Object.defineProperty(n, o, {
              value: r,
              enumerable: !0,
              configurable: !0,
              writable: !0
            }) : n[o] = r, n));
            var n, o, r;
          }, {}) : {};
          return (this || _global).options = d({}, (this || _global).options, e), (this || _global).lang = (this || _global).options.lang, (this || _global).dateFormat = (this || _global).options.dateFormat || "MM/DD/YYYY", (this || _global)._date = {
            start: void 0,
            end: void 0
          }, (this || _global)._open = !1, "inline" !== (this || _global).options.displayMode && window.matchMedia("screen and (max-width: 768px)").matches && ((this || _global).options.displayMode = "dialog"), this._initDates(), this._build(), this._bindEvents(), this.emit("ready", this || _global), this || _global;
        }
      }, {
        key: "_initDates",
        value: function () {
          (this || _global).minDate = (this || _global).options.minDate, (this || _global).maxDate = (this || _global).options.maxDate;
          var e = new Date(),
              t = this._isValidDate(e, (this || _global).options.minDate, (this || _global).options.maxDate) ? e : (this || _global).options.minDate;
          if ((this || _global).startDate = (this || _global).options.startDate, (this || _global).endDate = (this || _global).options.isRange ? (this || _global).options.endDate : void 0, (this || _global).element.value) if ((this || _global).options.isRange) {
            var n = (this || _global).element.value.split(" - ");

            n.length && ((this || _global).startDate = new Date(n[0])), 2 === n.length && ((this || _global).endDate = new Date(n[1]));
          } else (this || _global).startDate = new Date((this || _global).element.value);

          if ((this || _global)._visibleDate = this._isValidDate((this || _global).startDate) ? (this || _global).startDate : t, (this || _global).options.disabledDates) {
            Array.isArray((this || _global).options.disabledDates) || ((this || _global).options.disabledDates = [(this || _global).options.disabledDates]);

            for (var o = 0; o < (this || _global).options.disabledDates.length; o++) (this || _global).options.disabledDates[o] = c.format((this || _global).options.disabledDates[o], (this || _global).options.dateFormat, {
              locale: (this || _global).locale
            });
          }

          this._snapshot();
        }
      }, {
        key: "_build",
        value: function () {
          var n = this || _global,
              e = new Array(7).fill(c.startOfWeek((this || _global)._visibleDate)).map(function (e, t) {
            return c.format(c.addDays(e, t + n.options.weekStart), "ddd", {
              locale: n.locale
            });
          }),
              t = new Array(12).fill(c.startOfWeek((this || _global)._visibleDate)).map(function (e, t) {
            return c.format(c.addMonths(e, t), "MM", {
              locale: n.locale
            });
          }),
              o = new Array(100).fill(c.subYears((this || _global)._visibleDate, 50)).map(function (e, t) {
            return c.format(c.addYears(e, t), "YYYY", {
              locale: n.locale
            });
          }),
              r = document.createRange().createContextualFragment(Object(s.a)(d({}, (this || _global).options, {
            id: (this || _global).id,
            date: (this || _global).date,
            locale: (this || _global).locale,
            visibleDate: (this || _global)._visibleDate,
            labels: {
              from: (this || _global).options.labelFrom,
              to: (this || _global).options.labelTo,
              weekdays: e
            },
            months: t,
            years: o,
            isRange: (this || _global).options.isRange,
            month: c.format((this || _global).month, "MM", {
              locale: (this || _global).locale
            })
          }))),
              i = r.querySelector("#" + (this || _global).id);

          if ((this || _global)._ui = {
            container: i,
            calendar: i.querySelector(".calendar"),
            overlay: "dialog" === (this || _global).options.displayMode ? {
              background: i.querySelector(".modal-background"),
              close: i.querySelector(".modal-close")
            } : void 0,
            header: {
              container: i.querySelector(".calendar-header"),
              start: {
                container: i.querySelector(".calendar-selection-start"),
                day: i.querySelector(".calendar-selection-start .calendar-selection-day"),
                month: i.querySelector(".calendar-selection-start .calendar-selection-month"),
                weekday: i.querySelector(".calendar-selection-start .calendar-selection-weekday"),
                empty: i.querySelector(".calendar-selection-start .empty")
              },
              end: (this || _global).options.isRange ? {
                container: i.querySelector(".calendar-selection-end"),
                day: i.querySelector(".calendar-selection-end .calendar-selection-day"),
                month: i.querySelector(".calendar-selection-end .calendar-selection-month"),
                weekday: i.querySelector(".calendar-selection-end .calendar-selection-weekday"),
                empty: i.querySelector(".calendar-selection-start .empty")
              } : void 0
            },
            navigation: {
              container: i.querySelector(".calendar-nav"),
              previous: i.querySelector(".calendar-nav-previous"),
              next: i.querySelector(".calendar-nav-next"),
              month: i.querySelector(".calendar-nav-month"),
              year: i.querySelector(".calendar-nav-year")
            },
            footer: {
              container: i.querySelector(".calendar-footer"),
              validate: i.querySelector(".calendar-footer-validate"),
              today: i.querySelector(".calendar-footer-today"),
              clear: i.querySelector(".calendar-footer-clear"),
              cancel: i.querySelector(".calendar-footer-cancel")
            },
            body: {
              dates: i.querySelector(".calendar-dates"),
              days: i.querySelector(".calendar-days"),
              weekdays: i.querySelector(".calendar-weekdays"),
              months: i.querySelector(".calendar-months"),
              years: i.querySelector(".calendar-years")
            }
          }, (this || _global).options.showHeader || (this || _global)._ui.header.container.classList.add("is-hidden"), (this || _global).options.showFooter || (this || _global)._ui.footer.container.classList.add("is-hidden"), (this || _global).options.todayButton || (this || _global)._ui.footer.todayB.classList.add("is-hidden"), (this || _global).options.clearButton || (this || _global)._ui.footer.clear.classList.add("is-hidden"), "inline" === (this || _global).options.displayMode && (this || _global)._ui.footer.validate && (this || _global)._ui.footer.validate.classList.add("is-hidden"), "inline" === (this || _global).options.displayMode && (this || _global)._ui.footer.cancel && (this || _global)._ui.footer.cancel.classList.add("is-hidden"), (this || _global).options.closeOnSelect && (this || _global)._ui.footer.validate && (this || _global)._ui.footer.validate.classList.add("is-hidden"), "inline" === (this || _global).options.displayMode) {
            var a = document.createElement("div");
            (this || _global).element.parentNode.insertBefore(a, (this || _global).element), a.appendChild((this || _global).element), (this || _global).element.classList.add("is-hidden"), a.appendChild(r), i.classList.remove("datepicker"), this._refreshCalendar();
          } else document.body.appendChild(r);
        }
      }, {
        key: "_bindEvents",
        value: function () {
          var n = this || _global;
          window.addEventListener("scroll", function () {
            "default" === n.options.displayMode && (console("Scroll"), n._adjustPosition());
          }), document.addEventListener("keydown", function (e) {
            if (n._focus) switch (e.keyCode || e.which) {
              case 37:
                n[p](e);
                break;

              case 39:
                n[v](e);
            }
          }), !0 === (this || _global).options.toggleOnInputClick && (this || _global)._clickEvents.forEach(function (e) {
            n.element.addEventListener(e, n[h]);
          }), "dialog" === (this || _global).options.displayMode && (this || _global)._ui.overlay && ((this || _global)._ui.overlay.close && (this || _global)._clickEvents.forEach(function (e) {
            n.this._ui.overlay.close.addEventListener(e, n[m]);
          }), (this || _global).options.closeOnOverlayClick && (this || _global)._ui.overlay.background && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.overlay.background.addEventListener(e, n[m]);
          })), (this || _global)._ui.navigation.previous && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.navigation.previous.addEventListener(e, n[p]);
          }), (this || _global)._ui.navigation.next && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.navigation.next.addEventListener(e, n[v]);
          }), (this || _global)._ui.navigation.month && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.navigation.month.addEventListener(e, n[g]);
          }), (this || _global)._ui.navigation.year && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.navigation.year.addEventListener(e, n[y]);
          }), ((this || _global)._ui.body.months.querySelectorAll(".calendar-month") || []).forEach(function (t) {
            n._clickEvents.forEach(function (e) {
              t.addEventListener(e, n[_]);
            });
          }), ((this || _global)._ui.body.years.querySelectorAll(".calendar-year") || []).forEach(function (t) {
            n._clickEvents.forEach(function (e) {
              t.addEventListener(e, n[b]);
            });
          }), (this || _global)._ui.footer.validate && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.footer.validate.addEventListener(e, n[k]);
          }), (this || _global)._ui.footer.today && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.footer.today.addEventListener(e, n[w]);
          }), (this || _global)._ui.footer.clear && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.footer.clear.addEventListener(e, n[D]);
          }), (this || _global)._ui.footer.cancel && (this || _global)._clickEvents.forEach(function (e) {
            n._ui.footer.cancel.addEventListener(e, n[S]);
          });
        }
      }, {
        key: "_bindDaysEvents",
        value: function () {
          var o = this || _global;
          [].forEach.call((this || _global)._ui.days, function (n) {
            o._clickEvents.forEach(function (e) {
              var t = o._isValidDate(new Date(n.dataset.date), o.minDate, o.maxDate) ? o[x] : null;
              n.addEventListener(e, t);
            }), n.addEventListener("hover", function (e) {
              e.preventDEfault();
            });
          });
        }
      }, {
        key: "_renderDays",
        value: function () {
          var s = this || _global,
              e = c.startOfWeek(c.startOfMonth((this || _global)._visibleDate)),
              t = c.endOfWeek(c.endOfMonth((this || _global)._visibleDate)),
              n = new Array(c.differenceInDays(t, e) + 1).fill(e).map(function (e, t) {
            var n = c.addDays(e, t + s.options.weekStart),
                o = c.isSameMonth(s._visibleDate, n),
                r = s.options.isRange && c.isWithinRange(n, s.startDate, s.endDate),
                i = !!s.maxDate && c.isAfter(n, s.maxDate);
            if (i = s.minDate ? c.isBefore(n, s.minDate) : i, s.options.disabledDates) for (var a = 0; a < s.options.disabledDates.length; a++) c.getTime(n) == c.getTime(s.options.disabledDates[a]) && (i = !0);
            s.options.disabledWeekDays && (u.a(s.options.disabledWeekDays) ? s.options.disabledWeekDays.split(",") : s.options.disabledWeekDays).forEach(function (e) {
              c.getDay(n) == e && (i = !0);
            });
            return {
              date: n,
              isRange: s.options.isRange,
              isToday: c.isToday(n),
              isStartDate: c.isEqual(s.startDate, n),
              isEndDate: c.isEqual(s.endDate, n),
              isDisabled: i,
              isThisMonth: o,
              isInRange: r
            };
          });
          (this || _global)._ui.body.days.appendChild(document.createRange().createContextualFragment(Object(l.a)(n))), (this || _global)._ui.days = (this || _global)._ui.body.days.querySelectorAll(".calendar-date"), this._bindDaysEvents(), this.emit("rendered", this || _global);
        }
      }, {
        key: "_togglePreviousButton",
        value: function () {
          !(0 < arguments.length && void 0 !== arguments[0]) || arguments[0] ? (this || _global)._ui.navigation.previous.removeAttribute("disabled") : (this || _global)._ui.navigation.previous.setAttribute("disabled", "disabled");
        }
      }, {
        key: "_toggleNextButton",
        value: function () {
          !(0 < arguments.length && void 0 !== arguments[0]) || arguments[0] ? (this || _global)._ui.navigation.next.removeAttribute("disabled") : (this || _global)._ui.navigation.next.setAttribute("disabled", "disabled");
        }
      }, {
        key: "_setStartAndEnd",
        value: function (e) {
          var r = this || _global;
          this._snapshot(), (this || _global).options.isRange && (!this._isValidDate((this || _global).startDate) || this._isValidDate((this || _global).startDate) && this._isValidDate((this || _global).endDate)) ? ((this || _global).startDate = new Date(e), (this || _global).endDate = void 0, this.emit("startDate:selected", (this || _global).date, this || _global)) : (this || _global).options.isRange && !this._isValidDate((this || _global).endDate) ? c.isBefore(e, (this || _global).startDate) ? ((this || _global).endDate = (this || _global).startDate, (this || _global).startDate = new Date(e), this.emit("startDate:selected", (this || _global).date, this || _global), this.emit("endDate:selected", (this || _global).date, this || _global)) : c.isAfter(e, (this || _global).startDate) ? ((this || _global).endDate = new Date(e), this.emit("endDate:selected", (this || _global).date, this || _global)) : ((this || _global).startDate = new Date(e), (this || _global).endDate = void 0) : ((this || _global).startDate = new Date(e), (this || _global).endDate = void 0), (this || _global).options.isRange && this._isValidDate((this || _global).startDate) && this._isValidDate((this || _global).endDate) && new Array(c.differenceInDays((this || _global).endDate, (this || _global).startDate) + 1).fill((this || _global).startDate).map(function (e, t) {
            var n = c.addDays(e, t),
                o = r._ui.body.dates.querySelector(".calendar-date[data-date=\"" + n.toString() + "\"]");

            o && (c.isEqual(r.startDate, n) && o.classList.add("calendar-range-start"), c.isEqual(r.endDate, n) && o.classList.add("calendar-range-end"), o.classList.add("calendar-range"));
          });
        }
      }, {
        key: "_clear",
        value: function () {
          (this || _global).startDate = void 0, (this || _global).endDate = void 0, (this || _global).element.value = this.value(), "inline" !== (this || _global).options.displayMode && (this || _global)._open && this.hide(), this._refreshCalendar();
        }
      }, {
        key: "_refreshCalendar",
        value: function () {
          var t = this || _global;
          return (this || _global)._ui.body.days.innerHTML = "", (this || _global).minDate && 0 === c.differenceInMonths((this || _global)._visibleDate, (this || _global).minDate) ? this._togglePreviousButton(!1) : this._togglePreviousButton(), (this || _global).maxDate && 0 === c.differenceInMonths((this || _global)._visibleDate, (this || _global).maxDate) ? this._toggleNextButton(!1) : this._toggleNextButton(), this._refreshCalendarHeader(), (this || _global)._ui.navigation.month.innerHTML = c.format((this || _global)._visibleDate, "MMMM", {
            locale: (this || _global).locale
          }), (this || _global)._ui.navigation.year.innerHTML = c.format((this || _global)._visibleDate, "YYYY", {
            locale: (this || _global).locale
          }), ((this || _global)._ui.body.months.querySelectorAll(".calendar-month") || []).forEach(function (e) {
            e.classList.remove("is-active"), e.dataset.month === c.format(t._visibleDate, "MM", {
              locale: t.locale
            }) && e.classList.add("is-active");
          }), ((this || _global)._ui.body.years.querySelectorAll(".calendar-year") || []).forEach(function (e) {
            e.classList.remove("is-active"), e.dataset.year === c.format(t._visibleDate, "YYYY", {
              locale: t.locale
            }) && e.classList.add("is-active");
          }), this._renderDays(), (this || _global)._ui.body.dates.classList.add("is-active"), (this || _global)._ui.body.months.classList.remove("is-active"), (this || _global)._ui.body.years.classList.remove("is-active"), (this || _global)._ui.navigation.previous.removeAttribute("disabled"), (this || _global)._ui.navigation.next.removeAttribute("disabled"), this || _global;
        }
      }, {
        key: "_refreshCalendarHeader",
        value: function () {
          (this || _global)._ui.header.start.day.innerHTML = this._isValidDate((this || _global).startDate) ? c.getDate((this || _global).startDate) : "&nbsp;", (this || _global)._ui.header.start.weekday.innerHTML = this._isValidDate((this || _global).startDate) ? c.format((this || _global).startDate, "dddd", {
            locale: (this || _global).locale
          }) : "&nbsp;", (this || _global)._ui.header.start.month.innerHTML = this._isValidDate((this || _global).startDate) ? c.format((this || _global).startDate, "MMMM YYYY", {
            locale: (this || _global).locale
          }) : "&nbsp;", (this || _global)._ui.header.end && ((this || _global)._ui.header.end.day.innerHTML = (this || _global).options.isRange && this._isValidDate((this || _global).endDate) ? c.getDate((this || _global).endDate) : "&nbsp;", (this || _global)._ui.header.end.weekday.innerHTML = (this || _global).options.isRange && this._isValidDate((this || _global).endDate) ? c.format((this || _global).endDate, "dddd", {
            locale: (this || _global).locale
          }) : "&nbsp;", (this || _global)._ui.header.end.month.innerHTML = (this || _global).options.isRange && this._isValidDate((this || _global).endDate) ? c.format((this || _global).endDate, "MMMM YYYY", {
            locale: (this || _global).locale
          }) : "&nbsp;");
        }
      }, {
        key: "_adjustPosition",
        value: function () {
          var e = void 0,
              t = void 0,
              n = void 0;
          if ("function" == typeof (this || _global).element.getBoundingClientRect) e = (n = (this || _global).element.getBoundingClientRect()).left + window.pageXOffset, t = n.bottom + window.pageYOffset;else for (e = (this || _global).element.offsetLeft, t = (this || _global).element.offsetTop + (this || _global).element.offsetHeight; (this || _global).element = (this || _global).element.offsetParent;) e += (this || _global).element.offsetLeft, t += (this || _global).element.offsetTop;
          (this || _global)._ui.container.style.position = "absolute", (this || _global)._ui.container.style.left = e + "px", (this || _global)._ui.container.style.top = t + "px";
        }
      }, {
        key: "_isValidDate",
        value: function (e, t, n) {
          try {
            return !!e && !!c.isValid(e) && (!t && !n || (t && n ? c.isWithinRange(e, t, n) : n ? c.isBefore(e, n) || c.isEqual(e, n) : c.isAfter(e, t) || c.isEqual(e, t)));
          } catch (e) {
            return !1;
          }
        }
      }, {
        key: "_snapshot",
        value: function () {
          (this || _global)._snapshots.push(d({}, (this || _global)._date));
        }
      }, {
        key: "id",
        get: function () {
          return (this || _global)._id;
        }
      }, {
        key: "lang",
        get: function () {
          return (this || _global)._lang;
        },
        set: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "en";
          (this || _global)._lang = e, (this || _global)._locale = n(304)("./" + e);
        }
      }, {
        key: "locale",
        get: function () {
          return (this || _global)._locale;
        }
      }, {
        key: "date",
        get: function () {
          return (this || _global)._date || {
            start: void 0,
            end: void 0
          };
        }
      }, {
        key: "startDate",
        get: function () {
          return (this || _global)._date.start;
        },
        set: function (e) {
          (this || _global)._date.start = e ? this._isValidDate(e, (this || _global).minDate, (this || _global).maxDate) ? c.startOfDay(e) : (this || _global)._date.start : void 0;
        }
      }, {
        key: "endDate",
        get: function () {
          return (this || _global)._date.end;
        },
        set: function (e) {
          (this || _global)._date.end = e ? this._isValidDate(e, (this || _global).minDate, (this || _global).maxDate) ? c.startOfDay(e) : (this || _global)._date.end : void 0;
        }
      }, {
        key: "minDate",
        get: function () {
          return (this || _global)._minDate;
        },
        set: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : void 0;
          return (this || _global)._minDate = e ? this._isValidDate(e) ? c.startOfDay(e) : (this || _global)._minDate : void 0, this || _global;
        }
      }, {
        key: "maxDate",
        get: function () {
          return (this || _global)._maxDate;
        },
        set: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          return (this || _global)._maxDate = e ? this._isValidDate(e) ? c.startOfDay(e) : (this || _global)._maxDate : void 0, this || _global;
        }
      }, {
        key: "dateFormat",
        get: function () {
          return (this || _global)._dateFormat;
        },
        set: function (e) {
          return (this || _global)._dateFormat = e, this || _global;
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "input[type=\"date\"]",
              t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
              n = new Array(),
              o = u.a(e) ? document.querySelectorAll(e) : Array.isArray(e) ? e : [e];
          return [].forEach.call(o, function (e) {
            n.push(new r(e, t));
          }), n;
        }
      }]), r;
    }();

    t.a = E;
  }, function (e, t, n) {
    "use strict";

    n.d(t, "a", function () {
      return o;
    });

    var o = function () {
      return (0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "") + ([10000000] + -1000 + -4000 + -8000 + -100000000000).replace(/[018]/g, function (e) {
        return (e ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> e / 4).toString(16);
      });
    };
  }, function (e, t, n) {
    "use strict";

    n.d(t, "a", function () {
      return r;
    });

    var o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    },
        r = function (e) {
      return "string" == typeof e || !!e && "object" === (void 0 === e ? "undefined" : o(e)) && "[object String]" === Object.prototype.toString.call(e);
    };
  }, function (e, t, n) {
    var u = n(0);

    e.exports = function (e, t, n, o) {
      var r = u(e).getTime(),
          i = u(t).getTime(),
          a = u(n).getTime(),
          s = u(o).getTime();
      if (i < r || s < a) throw new Error("The start of the range cannot be after the end of the range");
      return r < s && a < i;
    };
  }, function (e, t, n) {
    var s = n(0);

    e.exports = function (e, t) {
      if (!(t instanceof Array)) throw new TypeError(toString.call(t) + " is not an instance of Array");
      var r,
          i,
          a = s(e).getTime();
      return t.forEach(function (e, t) {
        var n = s(e),
            o = Math.abs(a - n.getTime());
        (void 0 === r || o < i) && (r = t, i = o);
      }), r;
    };
  }, function (e, t, n) {
    var a = n(0);

    e.exports = function (e, t) {
      if (!(t instanceof Array)) throw new TypeError(toString.call(t) + " is not an instance of Array");
      var o,
          r,
          i = a(e).getTime();
      return t.forEach(function (e) {
        var t = a(e),
            n = Math.abs(i - t.getTime());
        (void 0 === o || n < r) && (o = t, r = n);
      }), o;
    };
  }, function (e, t, n) {
    var a = n(3);

    e.exports = function (e, t) {
      var n = a(e),
          o = a(t),
          r = n.getTime() - 60000 * n.getTimezoneOffset(),
          i = o.getTime() - 60000 * o.getTimezoneOffset();
      return Math.round((r - i) / 604800000);
    };
  }, function (e, t, n) {
    var r = n(134),
        i = n(0);

    e.exports = function (e, t) {
      var n = i(e),
          o = i(t);
      return 4 * (n.getFullYear() - o.getFullYear()) + (r(n) - r(o));
    };
  }, function (e, t, n) {
    var s = n(78);

    e.exports = function (e, t, n) {
      var o = s(e, n),
          r = s(t, n),
          i = o.getTime() - 60000 * o.getTimezoneOffset(),
          a = r.getTime() - 60000 * r.getTimezoneOffset();
      return Math.round((i - a) / 604800000);
    };
  }, function (e, t, n) {
    var o = n(81);

    e.exports = function (e, t) {
      var n = o(e, t) / 3600000;
      return 0 < n ? Math.floor(n) : Math.ceil(n);
    };
  }, function (e, t, n) {
    var a = n(0),
        s = n(132),
        u = n(9),
        c = n(137);

    e.exports = function (e, t) {
      var n = a(e),
          o = a(t),
          r = u(n, o),
          i = Math.abs(s(n, o));
      return n = c(n, r * i), r * (i - (u(n, o) === -r));
    };
  }, function (e, t, n) {
    var o = n(81);

    e.exports = function (e, t) {
      var n = o(e, t) / 60000;
      return 0 < n ? Math.floor(n) : Math.ceil(n);
    };
  }, function (e, t, n) {
    var o = n(119);

    e.exports = function (e, t) {
      var n = o(e, t) / 3;
      return 0 < n ? Math.floor(n) : Math.ceil(n);
    };
  }, function (e, t, n) {
    var o = n(136);

    e.exports = function (e, t) {
      var n = o(e, t) / 7;
      return 0 < n ? Math.floor(n) : Math.ceil(n);
    };
  }, function (e, t, n) {
    var a = n(0),
        s = n(135),
        u = n(9);

    e.exports = function (e, t) {
      var n = a(e),
          o = a(t),
          r = u(n, o),
          i = Math.abs(s(n, o));
      return n.setFullYear(n.getFullYear() - r * i), r * (i - (u(n, o) === -r));
    };
  }, function (e, t, n) {
    var p = n(118),
        v = n(0),
        g = n(120),
        _ = n(5);

    e.exports = function (e, t, n) {
      var o = n || {},
          r = p(e, t),
          i = o.locale,
          a = _.distanceInWords.localize;
      i && i.distanceInWords && i.distanceInWords.localize && (a = i.distanceInWords.localize);
      var s,
          u,
          c,
          l = {
        addSuffix: Boolean(o.addSuffix),
        comparison: r
      };
      0 < r ? (s = v(e), u = v(t)) : (s = v(t), u = v(e));
      var d = Math[o.partialMethod ? String(o.partialMethod) : "floor"],
          f = g(u, s),
          h = u.getTimezoneOffset() - s.getTimezoneOffset(),
          m = d(f / 60) - h;
      if ("s" === (c = o.unit ? String(o.unit) : m < 1 ? "s" : m < 60 ? "m" : m < 1440 ? "h" : m < 43200 ? "d" : m < 525600 ? "M" : "Y")) return a("xSeconds", f, l);
      if ("m" === c) return a("xMinutes", m, l);
      if ("h" === c) return a("xHours", d(m / 60), l);
      if ("d" === c) return a("xDays", d(m / 1440), l);
      if ("M" === c) return a("xMonths", d(m / 43200), l);
      if ("Y" === c) return a("xYears", d(m / 525600), l);
      throw new Error("Unknown unit: " + c);
    };
  }, function (e, t, n) {
    var o = n(138);

    e.exports = function (e, t) {
      return o(Date.now(), e, t);
    };
  }, function (e, t, n) {
    var u = n(0);

    e.exports = function (e, t, n) {
      var o = u(e),
          r = void 0 !== n ? n : 1,
          i = u(t).getTime();
      if (o.getTime() > i) throw new Error("The first date cannot be after the second date");
      var a = [],
          s = o;

      for (s.setHours(0, 0, 0, 0); s.getTime() <= i;) a.push(u(s)), s.setDate(s.getDate() + r);

      return a;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setMinutes(59, 59, 999), t;
    };
  }, function (e, t, n) {
    var o = n(139);

    e.exports = function (e) {
      return o(e, {
        weekStartsOn: 1
      });
    };
  }, function (e, t, n) {
    var r = n(2),
        i = n(3);

    e.exports = function (e) {
      var t = r(e),
          n = new Date(0);
      n.setFullYear(t + 1, 0, 4), n.setHours(0, 0, 0, 0);
      var o = i(n);
      return o.setMilliseconds(o.getMilliseconds() - 1), o;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setSeconds(59, 999), t;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e) {
      var t = r(e),
          n = t.getMonth(),
          o = n - n % 3 + 3;
      return t.setMonth(o, 0), t.setHours(23, 59, 59, 999), t;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setMilliseconds(999), t;
    };
  }, function (e, t, n) {
    var o = n(121);

    e.exports = function () {
      return o(new Date());
    };
  }, function (e, t) {
    e.exports = function () {
      var e = new Date(),
          t = e.getFullYear(),
          n = e.getMonth(),
          o = e.getDate(),
          r = new Date(0);
      return r.setFullYear(t, n, o + 1), r.setHours(23, 59, 59, 999), r;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e),
          n = t.getFullYear();
      return t.setFullYear(n + 1, 0, 0), t.setHours(23, 59, 59, 999), t;
    };
  }, function (e, t) {
    e.exports = function () {
      var e = new Date(),
          t = e.getFullYear(),
          n = e.getMonth(),
          o = e.getDate(),
          r = new Date(0);
      return r.setFullYear(t, n, o - 1), r.setHours(23, 59, 59, 999), r;
    };
  }, function (e, t, n) {
    var o = n(141),
        r = n(122),
        i = n(2),
        u = n(0),
        c = n(143),
        l = n(5);
    var d = {
      M: function (e) {
        return e.getMonth() + 1;
      },
      MM: function (e) {
        return s(e.getMonth() + 1, 2);
      },
      Q: function (e) {
        return Math.ceil((e.getMonth() + 1) / 3);
      },
      D: function (e) {
        return e.getDate();
      },
      DD: function (e) {
        return s(e.getDate(), 2);
      },
      DDD: function (e) {
        return o(e);
      },
      DDDD: function (e) {
        return s(o(e), 3);
      },
      d: function (e) {
        return e.getDay();
      },
      E: function (e) {
        return e.getDay() || 7;
      },
      W: function (e) {
        return r(e);
      },
      WW: function (e) {
        return s(r(e), 2);
      },
      YY: function (e) {
        return s(e.getFullYear(), 4).substr(2);
      },
      YYYY: function (e) {
        return s(e.getFullYear(), 4);
      },
      GG: function (e) {
        return String(i(e)).substr(2);
      },
      GGGG: function (e) {
        return i(e);
      },
      H: function (e) {
        return e.getHours();
      },
      HH: function (e) {
        return s(e.getHours(), 2);
      },
      h: function (e) {
        var t = e.getHours();
        return 0 === t ? 12 : 12 < t ? t % 12 : t;
      },
      hh: function (e) {
        return s(d.h(e), 2);
      },
      m: function (e) {
        return e.getMinutes();
      },
      mm: function (e) {
        return s(e.getMinutes(), 2);
      },
      s: function (e) {
        return e.getSeconds();
      },
      ss: function (e) {
        return s(e.getSeconds(), 2);
      },
      S: function (e) {
        return Math.floor(e.getMilliseconds() / 100);
      },
      SS: function (e) {
        return s(Math.floor(e.getMilliseconds() / 10), 2);
      },
      SSS: function (e) {
        return s(e.getMilliseconds(), 3);
      },
      Z: function (e) {
        return a(e.getTimezoneOffset(), ":");
      },
      ZZ: function (e) {
        return a(e.getTimezoneOffset());
      },
      X: function (e) {
        return Math.floor(e.getTime() / 1000);
      },
      x: function (e) {
        return e.getTime();
      }
    };

    function a(e, t) {
      t = t || "";
      var n = 0 < e ? "-" : "+",
          o = Math.abs(e),
          r = o % 60;
      return n + s(Math.floor(o / 60), 2) + t + s(r, 2);
    }

    function s(e, t) {
      for (var n = Math.abs(e).toString(); n.length < t;) n = "0" + n;

      return n;
    }

    e.exports = function (e, t, n) {
      var o = t ? String(t) : "YYYY-MM-DDTHH:mm:ss.SSSZ",
          r = (n || {}).locale,
          i = l.format.formatters,
          a = l.format.formattingTokensRegExp;
      r && r.format && r.format.formatters && (i = r.format.formatters, r.format.formattingTokensRegExp && (a = r.format.formattingTokensRegExp));
      var s = u(e);
      return c(s) ? function (e, t, n) {
        var o,
            r,
            i,
            a = e.match(n),
            s = a.length;

        for (o = 0; o < s; o++) r = t[a[o]] || d[a[o]], a[o] = r || ((i = a[o]).match(/\[[\s\S]/) ? i.replace(/^\[|]$/g, "") : i.replace(/\\/g, ""));

        return function (e) {
          for (var t = "", n = 0; n < s; n++) a[n] instanceof Function ? t += a[n](e, d) : t += a[n];

          return t;
        };
      }(o, i, a)(s) : "Invalid Date";
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getDate();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(144);

    e.exports = function (e) {
      return o(e) ? 366 : 365;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getHours();
    };
  }, function (e, t, n) {
    var o = n(8),
        r = n(117);

    e.exports = function (e) {
      var t = o(e),
          n = o(r(t, 60)).valueOf() - t.valueOf();
      return Math.round(n / 604800000);
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getMilliseconds();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getMinutes();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getMonth();
    };
  }, function (e, t, n) {
    var c = n(0);

    e.exports = function (e, t, n, o) {
      var r = c(e).getTime(),
          i = c(t).getTime(),
          a = c(n).getTime(),
          s = c(o).getTime();
      if (i < r || s < a) throw new Error("The start of the range cannot be after the end of the range");
      if (!(r < s && a < i)) return 0;
      var u = (i < s ? i : s) - (a < r ? r : a);
      return Math.ceil(u / 86400000);
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getSeconds();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getFullYear();
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() > o.getTime();
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() < o.getTime();
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() === o.getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 1 === o(e).getDate();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 5 === o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getTime() > new Date().getTime();
    };
  }, function (e, t, n) {
    var o = n(0),
        r = n(121),
        i = n(140);

    e.exports = function (e) {
      var t = o(e);
      return r(t).getTime() === i(t).getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 1 === o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return o(e).getTime() < new Date().getTime();
    };
  }, function (e, t, n) {
    var r = n(4);

    e.exports = function (e, t) {
      var n = r(e),
          o = r(t);
      return n.getTime() === o.getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 6 === o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 0 === o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(146);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(148);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(149);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(150);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(152);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(153);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(155);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(123);

    e.exports = function (e, t) {
      return o(new Date(), e, t);
    };
  }, function (e, t, n) {
    var o = n(157);

    e.exports = function (e) {
      return o(new Date(), e);
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 4 === o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(4);

    e.exports = function (e) {
      return o(e).getTime() === o(new Date()).getTime();
    };
  }, function (e, t, n) {
    var o = n(4);

    e.exports = function (e) {
      var t = new Date();
      return t.setDate(t.getDate() + 1), o(e).getTime() === o(t).getTime();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 2 === o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      return 3 === o(e).getDay();
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e).getDay();
      return 0 === t || 6 === t;
    };
  }, function (e, t, n) {
    var a = n(0);

    e.exports = function (e, t, n) {
      var o = a(e).getTime(),
          r = a(t).getTime(),
          i = a(n).getTime();
      if (i < r) throw new Error("The start of the range cannot be after the end of the range");
      return r <= o && o <= i;
    };
  }, function (e, t, n) {
    var o = n(4);

    e.exports = function (e) {
      var t = new Date();
      return t.setDate(t.getDate() - 1), o(e).getTime() === o(t).getTime();
    };
  }, function (e, t, n) {
    var o = n(158);

    e.exports = function (e) {
      return o(e, {
        weekStartsOn: 1
      });
    };
  }, function (e, t, n) {
    var r = n(2),
        i = n(3);

    e.exports = function (e) {
      var t = r(e),
          n = new Date(0);
      n.setFullYear(t + 1, 0, 4), n.setHours(0, 0, 0, 0);
      var o = i(n);
      return o.setDate(o.getDate() - 1), o;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e),
          n = t.getMonth();
      return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(0, 0, 0, 0), t;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e) {
      var t = r(e),
          n = t.getMonth(),
          o = n - n % 3 + 3;
      return t.setMonth(o, 0), t.setHours(0, 0, 0, 0), t;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e),
          n = t.getFullYear();
      return t.setFullYear(n + 1, 0, 0), t.setHours(0, 0, 0, 0), t;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function () {
      var e = Array.prototype.slice.call(arguments).map(function (e) {
        return o(e);
      }),
          t = Math.max.apply(null, e);
      return new Date(t);
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function () {
      var e = Array.prototype.slice.call(arguments).map(function (e) {
        return o(e);
      }),
          t = Math.min.apply(null, e);
      return new Date(t);
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setDate(o), n;
    };
  }, function (e, t, n) {
    var s = n(0),
        u = n(6);

    e.exports = function (e, t, n) {
      var o = n && Number(n.weekStartsOn) || 0,
          r = s(e),
          i = Number(t),
          a = r.getDay();
      return u(r, ((i % 7 + 7) % 7 < o ? 7 : 0) + i - a);
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setMonth(0), n.setDate(o), n;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setHours(o), n;
    };
  }, function (e, t, n) {
    var i = n(0),
        a = n(6),
        s = n(145);

    e.exports = function (e, t) {
      var n = i(e),
          o = Number(t),
          r = s(n);
      return a(n, o - r);
    };
  }, function (e, t, n) {
    var i = n(0),
        a = n(122);

    e.exports = function (e, t) {
      var n = i(e),
          o = Number(t),
          r = a(n) - o;
      return n.setDate(n.getDate() - 7 * r), n;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setMilliseconds(o), n;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setMinutes(o), n;
    };
  }, function (e, t, n) {
    var r = n(0),
        i = n(159);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t) - (Math.floor(n.getMonth() / 3) + 1);
      return i(n, n.getMonth() + 3 * o);
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setSeconds(o), n;
    };
  }, function (e, t, n) {
    var r = n(0);

    e.exports = function (e, t) {
      var n = r(e),
          o = Number(t);
      return n.setFullYear(o), n;
    };
  }, function (e, t, n) {
    var o = n(0);

    e.exports = function (e) {
      var t = o(e);
      return t.setDate(1), t.setHours(0, 0, 0, 0), t;
    };
  }, function (e, t, n) {
    var o = n(4);

    e.exports = function () {
      return o(new Date());
    };
  }, function (e, t) {
    e.exports = function () {
      var e = new Date(),
          t = e.getFullYear(),
          n = e.getMonth(),
          o = e.getDate(),
          r = new Date(0);
      return r.setFullYear(t, n, o + 1), r.setHours(0, 0, 0, 0), r;
    };
  }, function (e, t) {
    e.exports = function () {
      var e = new Date(),
          t = e.getFullYear(),
          n = e.getMonth(),
          o = e.getDate(),
          r = new Date(0);
      return r.setFullYear(t, n, o - 1), r.setHours(0, 0, 0, 0), r;
    };
  }, function (e, t, n) {
    var o = n(6);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(125);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(7);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(128);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(80);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(129);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(130);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(117);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    var o = n(131);

    e.exports = function (e, t) {
      var n = Number(t);
      return o(e, -n);
    };
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    var o = {
      startDate: void 0,
      endDate: void 0,
      minDate: null,
      maxDate: null,
      isRange: !1,
      disabledDates: [],
      disabledWeekDays: void 0,
      lang: "en",
      dateFormat: "MM/DD/YYYY",
      displayMode: "default",
      showHeader: !0,
      showFooter: !0,
      todayButton: !0,
      clearButton: !0,
      labelFrom: "",
      labelTo: "",
      weekStart: 0,
      closeOnOverlayClick: !0,
      closeOnSelect: !0,
      toggleOnInputClick: !0,
      icons: {
        previous: "<svg viewBox=\"0 0 50 80\" xml:space=\"preserve\">\n      <polyline fill=\"none\" stroke-width=\".5em\" stroke-linecap=\"round\" stroke-linejoin=\"round\" points=\"45.63,75.8 0.375,38.087 45.63,0.375 \"/>\n    </svg>",
        next: "<svg viewBox=\"0 0 50 80\" xml:space=\"preserve\">\n      <polyline fill=\"none\" stroke-width=\".5em\" stroke-linecap=\"round\" stroke-linejoin=\"round\" points=\"0.375,0.375 45.63,38.087 0.375,75.8 \"/>\n    </svg>"
      }
    };
    t.a = o;
  }, function (e, t, n) {
    "use strict";

    var o = n(124);
    n.n(o);

    t.a = function (n) {
      return "<div id='" + n.id + "' class=\"datepicker " + ("dialog" === n.displayMode ? "modal" : "") + "\">\n    " + ("dialog" === n.displayMode ? "<div class=\"modal-background\"></div>" : "") + "\n    <div class=\"calendar\">\n      <div class=\"calendar-header\">\n        <div class=\"calendar-selection-start\">\n          <div class=\"calendar-selection-from" + ("" === n.labels.from ? " is-hidden" : "") + "\">" + n.labels.from + "</div>\n          <div class=\"calendar-selection-date\">\n            <div class=\"calendar-selection-day\"></div>\n            <div class=\"calendar-selection-details\">\n              <div class=\"calendar-selection-month\"></div>\n              <div class=\"calendar-selection-weekday\"></div>\n            </div>\n          </div>\n        </div>\n  " + (n.isRange ? "<div class=\"calendar-selection-end\">\n          <div class=\"calendar-selection-to" + ("" === n.labels.to ? " is-hidden" : "") + "\">" + n.labels.to + "</div>\n          <div class=\"calendar-selection-date\">\n            <div class=\"calendar-selection-day\"></div>\n            <div class=\"calendar-selection-details\">\n              <div class=\"calendar-selection-month\"></div>\n              <div class=\"calendar-selection-weekday\"></div>\n            </div>\n          </div>\n        </div>" : "") + "\n      </div>\n      <div class=\"calendar-nav\">\n        <button class=\"calendar-nav-previous button is-small is-text\">" + n.icons.previous + "</button>\n        <div class=\"calendar-nav-month-year\">\n          <div class=\"calendar-nav-month\">" + Object(o.format)(n.visibleDate, "MMMM", {
        locale: n.locale
      }) + "</div>\n          &nbsp;\n          <div class=\"calendar-nav-year\">" + Object(o.format)(n.visibleDate, "YYYY", {
        locale: n.locale
      }) + "</div>\n        </div>\n        <button class=\"calendar-nav-next button is-small is-text\">" + n.icons.next + "</button>\n      </div>\n      <div class=\"calendar-body\">\n        <div class=\"calendar-dates is-active\">\n          <div class=\"calendar-weekdays\">\n            " + n.labels.weekdays.map(function (e) {
        return "<div class=\"calendar-date\">" + e + "</div>";
      }).join("") + "\n          </div>\n          <div class=\"calendar-days\"></div>\n        </div>\n        <div class=\"calendar-months\">\n          " + new Array(12).fill(new Date("01/01/1970")).map(function (e, t) {
        return "<div class=\"calendar-month\" data-month=\"" + Object(o.format)(Object(o.addMonths)(e, t), "MM", {
          locale: n.locale
        }) + "\">" + Object(o.format)(Object(o.addMonths)(e, t), "MMM", {
          locale: n.locale
        }) + "</div>";
      }).join("") + "\n        </div>\n        <div class=\"calendar-years\">\n          " + n.years.map(function (e) {
        return "<div class=\"calendar-year" + (e === Object(o.getMonth)(n.visibleDate) ? " is-active" : "") + "\" data-year=\"" + e + "\"><span class=\"item\">" + e + "</span></div>";
      }).join("") + "\n        </div>\n      </div>\n      <div class=\"calendar-footer\">\n        <button class=\"calendar-footer-validate has-text-success button is-small is-text\">" + (n.icons.validate ? n.icons.validate : "") + " Validate</button>\n        <button class=\"calendar-footer-today has-text-warning button is-small is-text\">" + (n.icons.today ? n.icons.today : "") + " Today</button>\n        <button class=\"calendar-footer-clear has-text-danger button is-small is-text\">" + (n.icons.clear ? n.icons.clear : "") + " Clear</button>\n        <button class=\"calendar-footer-cancel button is-small is-text\">" + (n.icons.cancel ? n.icons.cancel : "") + " Cancel</button>\n      </div>\n    </div>\n  </div>";
    };
  }, function (e, t, n) {
    "use strict";

    t.a = function (e) {
      return "" + e.map(function (e) {
        return "<div data-date=\"" + e.date.toString() + "\" class=\"calendar-date" + (e.isThisMonth ? " is-current-month" : "") + (e.isDisabled ? " is-disabled" : "") + (e.isRange && e.isInRange ? " calendar-range" : "") + (e.isStartDate ? " calendar-range-start" : "") + (e.isEndDate ? " calendar-range-end" : "") + "\">\n      <button class=\"date-item" + (e.isToday ? " is-today" : "") + (e.isStartDate ? " is-active" : "") + "\">" + e.date.getDate() + "</button>\n  </div>";
      }).join("");
    };
  }, function (e, t, n) {
    var o = {
      "./_lib/build_formatting_tokens_reg_exp": 1,
      "./_lib/build_formatting_tokens_reg_exp/": 1,
      "./_lib/build_formatting_tokens_reg_exp/index": 1,
      "./_lib/build_formatting_tokens_reg_exp/index.js": 1,
      "./_lib/package": 160,
      "./_lib/package.json": 160,
      "./ar": 82,
      "./ar/": 82,
      "./ar/build_distance_in_words_locale": 12,
      "./ar/build_distance_in_words_locale/": 12,
      "./ar/build_distance_in_words_locale/index": 12,
      "./ar/build_distance_in_words_locale/index.js": 12,
      "./ar/build_format_locale": 13,
      "./ar/build_format_locale/": 13,
      "./ar/build_format_locale/index": 13,
      "./ar/build_format_locale/index.js": 13,
      "./ar/index": 82,
      "./ar/index.js": 82,
      "./ar/package": 161,
      "./ar/package.json": 161,
      "./bg": 83,
      "./bg/": 83,
      "./bg/build_distance_in_words_locale": 14,
      "./bg/build_distance_in_words_locale/": 14,
      "./bg/build_distance_in_words_locale/index": 14,
      "./bg/build_distance_in_words_locale/index.js": 14,
      "./bg/build_format_locale": 15,
      "./bg/build_format_locale/": 15,
      "./bg/build_format_locale/index": 15,
      "./bg/build_format_locale/index.js": 15,
      "./bg/index": 83,
      "./bg/index.js": 83,
      "./bg/package": 162,
      "./bg/package.json": 162,
      "./ca": 84,
      "./ca/": 84,
      "./ca/build_distance_in_words_locale": 16,
      "./ca/build_distance_in_words_locale/": 16,
      "./ca/build_distance_in_words_locale/index": 16,
      "./ca/build_distance_in_words_locale/index.js": 16,
      "./ca/build_format_locale": 17,
      "./ca/build_format_locale/": 17,
      "./ca/build_format_locale/index": 17,
      "./ca/build_format_locale/index.js": 17,
      "./ca/index": 84,
      "./ca/index.js": 84,
      "./ca/package": 163,
      "./ca/package.json": 163,
      "./cs": 85,
      "./cs/": 85,
      "./cs/build_distance_in_words_locale": 18,
      "./cs/build_distance_in_words_locale/": 18,
      "./cs/build_distance_in_words_locale/index": 18,
      "./cs/build_distance_in_words_locale/index.js": 18,
      "./cs/build_format_locale": 19,
      "./cs/build_format_locale/": 19,
      "./cs/build_format_locale/index": 19,
      "./cs/build_format_locale/index.js": 19,
      "./cs/index": 85,
      "./cs/index.js": 85,
      "./cs/package": 164,
      "./cs/package.json": 164,
      "./da": 86,
      "./da/": 86,
      "./da/build_distance_in_words_locale": 20,
      "./da/build_distance_in_words_locale/": 20,
      "./da/build_distance_in_words_locale/index": 20,
      "./da/build_distance_in_words_locale/index.js": 20,
      "./da/build_format_locale": 21,
      "./da/build_format_locale/": 21,
      "./da/build_format_locale/index": 21,
      "./da/build_format_locale/index.js": 21,
      "./da/index": 86,
      "./da/index.js": 86,
      "./da/package": 165,
      "./da/package.json": 165,
      "./de": 87,
      "./de/": 87,
      "./de/build_distance_in_words_locale": 22,
      "./de/build_distance_in_words_locale/": 22,
      "./de/build_distance_in_words_locale/index": 22,
      "./de/build_distance_in_words_locale/index.js": 22,
      "./de/build_format_locale": 23,
      "./de/build_format_locale/": 23,
      "./de/build_format_locale/index": 23,
      "./de/build_format_locale/index.js": 23,
      "./de/index": 87,
      "./de/index.js": 87,
      "./de/package": 166,
      "./de/package.json": 166,
      "./el": 88,
      "./el/": 88,
      "./el/build_distance_in_words_locale": 24,
      "./el/build_distance_in_words_locale/": 24,
      "./el/build_distance_in_words_locale/index": 24,
      "./el/build_distance_in_words_locale/index.js": 24,
      "./el/build_format_locale": 25,
      "./el/build_format_locale/": 25,
      "./el/build_format_locale/index": 25,
      "./el/build_format_locale/index.js": 25,
      "./el/index": 88,
      "./el/index.js": 88,
      "./el/package": 167,
      "./el/package.json": 167,
      "./en": 5,
      "./en/": 5,
      "./en/build_distance_in_words_locale": 10,
      "./en/build_distance_in_words_locale/": 10,
      "./en/build_distance_in_words_locale/index": 10,
      "./en/build_distance_in_words_locale/index.js": 10,
      "./en/build_format_locale": 11,
      "./en/build_format_locale/": 11,
      "./en/build_format_locale/index": 11,
      "./en/build_format_locale/index.js": 11,
      "./en/index": 5,
      "./en/index.js": 5,
      "./en/package": 168,
      "./en/package.json": 168,
      "./eo": 89,
      "./eo/": 89,
      "./eo/build_distance_in_words_locale": 26,
      "./eo/build_distance_in_words_locale/": 26,
      "./eo/build_distance_in_words_locale/index": 26,
      "./eo/build_distance_in_words_locale/index.js": 26,
      "./eo/build_format_locale": 27,
      "./eo/build_format_locale/": 27,
      "./eo/build_format_locale/index": 27,
      "./eo/build_format_locale/index.js": 27,
      "./eo/index": 89,
      "./eo/index.js": 89,
      "./eo/package": 169,
      "./eo/package.json": 169,
      "./es": 90,
      "./es/": 90,
      "./es/build_distance_in_words_locale": 28,
      "./es/build_distance_in_words_locale/": 28,
      "./es/build_distance_in_words_locale/index": 28,
      "./es/build_distance_in_words_locale/index.js": 28,
      "./es/build_format_locale": 29,
      "./es/build_format_locale/": 29,
      "./es/build_format_locale/index": 29,
      "./es/build_format_locale/index.js": 29,
      "./es/index": 90,
      "./es/index.js": 90,
      "./es/package": 170,
      "./es/package.json": 170,
      "./fi": 91,
      "./fi/": 91,
      "./fi/build_distance_in_words_locale": 30,
      "./fi/build_distance_in_words_locale/": 30,
      "./fi/build_distance_in_words_locale/index": 30,
      "./fi/build_distance_in_words_locale/index.js": 30,
      "./fi/build_format_locale": 31,
      "./fi/build_format_locale/": 31,
      "./fi/build_format_locale/index": 31,
      "./fi/build_format_locale/index.js": 31,
      "./fi/index": 91,
      "./fi/index.js": 91,
      "./fi/package": 171,
      "./fi/package.json": 171,
      "./fil": 92,
      "./fil/": 92,
      "./fil/build_distance_in_words_locale": 32,
      "./fil/build_distance_in_words_locale/": 32,
      "./fil/build_distance_in_words_locale/index": 32,
      "./fil/build_distance_in_words_locale/index.js": 32,
      "./fil/build_format_locale": 33,
      "./fil/build_format_locale/": 33,
      "./fil/build_format_locale/index": 33,
      "./fil/build_format_locale/index.js": 33,
      "./fil/index": 92,
      "./fil/index.js": 92,
      "./fil/package": 172,
      "./fil/package.json": 172,
      "./fr": 93,
      "./fr/": 93,
      "./fr/build_distance_in_words_locale": 34,
      "./fr/build_distance_in_words_locale/": 34,
      "./fr/build_distance_in_words_locale/index": 34,
      "./fr/build_distance_in_words_locale/index.js": 34,
      "./fr/build_format_locale": 35,
      "./fr/build_format_locale/": 35,
      "./fr/build_format_locale/index": 35,
      "./fr/build_format_locale/index.js": 35,
      "./fr/index": 93,
      "./fr/index.js": 93,
      "./fr/package": 173,
      "./fr/package.json": 173,
      "./hr": 94,
      "./hr/": 94,
      "./hr/build_distance_in_words_locale": 36,
      "./hr/build_distance_in_words_locale/": 36,
      "./hr/build_distance_in_words_locale/index": 36,
      "./hr/build_distance_in_words_locale/index.js": 36,
      "./hr/build_format_locale": 37,
      "./hr/build_format_locale/": 37,
      "./hr/build_format_locale/index": 37,
      "./hr/build_format_locale/index.js": 37,
      "./hr/index": 94,
      "./hr/index.js": 94,
      "./hr/package": 174,
      "./hr/package.json": 174,
      "./hu": 95,
      "./hu/": 95,
      "./hu/build_distance_in_words_locale": 38,
      "./hu/build_distance_in_words_locale/": 38,
      "./hu/build_distance_in_words_locale/index": 38,
      "./hu/build_distance_in_words_locale/index.js": 38,
      "./hu/build_format_locale": 39,
      "./hu/build_format_locale/": 39,
      "./hu/build_format_locale/index": 39,
      "./hu/build_format_locale/index.js": 39,
      "./hu/index": 95,
      "./hu/index.js": 95,
      "./hu/package": 175,
      "./hu/package.json": 175,
      "./id": 96,
      "./id/": 96,
      "./id/build_distance_in_words_locale": 40,
      "./id/build_distance_in_words_locale/": 40,
      "./id/build_distance_in_words_locale/index": 40,
      "./id/build_distance_in_words_locale/index.js": 40,
      "./id/build_format_locale": 41,
      "./id/build_format_locale/": 41,
      "./id/build_format_locale/index": 41,
      "./id/build_format_locale/index.js": 41,
      "./id/index": 96,
      "./id/index.js": 96,
      "./id/package": 176,
      "./id/package.json": 176,
      "./is": 97,
      "./is/": 97,
      "./is/build_distance_in_words_locale": 42,
      "./is/build_distance_in_words_locale/": 42,
      "./is/build_distance_in_words_locale/index": 42,
      "./is/build_distance_in_words_locale/index.js": 42,
      "./is/build_format_locale": 43,
      "./is/build_format_locale/": 43,
      "./is/build_format_locale/index": 43,
      "./is/build_format_locale/index.js": 43,
      "./is/index": 97,
      "./is/index.js": 97,
      "./is/package": 177,
      "./is/package.json": 177,
      "./it": 98,
      "./it/": 98,
      "./it/build_distance_in_words_locale": 44,
      "./it/build_distance_in_words_locale/": 44,
      "./it/build_distance_in_words_locale/index": 44,
      "./it/build_distance_in_words_locale/index.js": 44,
      "./it/build_format_locale": 45,
      "./it/build_format_locale/": 45,
      "./it/build_format_locale/index": 45,
      "./it/build_format_locale/index.js": 45,
      "./it/index": 98,
      "./it/index.js": 98,
      "./it/package": 178,
      "./it/package.json": 178,
      "./ja": 99,
      "./ja/": 99,
      "./ja/build_distance_in_words_locale": 46,
      "./ja/build_distance_in_words_locale/": 46,
      "./ja/build_distance_in_words_locale/index": 46,
      "./ja/build_distance_in_words_locale/index.js": 46,
      "./ja/build_format_locale": 47,
      "./ja/build_format_locale/": 47,
      "./ja/build_format_locale/index": 47,
      "./ja/build_format_locale/index.js": 47,
      "./ja/index": 99,
      "./ja/index.js": 99,
      "./ja/package": 179,
      "./ja/package.json": 179,
      "./ko": 100,
      "./ko/": 100,
      "./ko/build_distance_in_words_locale": 48,
      "./ko/build_distance_in_words_locale/": 48,
      "./ko/build_distance_in_words_locale/index": 48,
      "./ko/build_distance_in_words_locale/index.js": 48,
      "./ko/build_format_locale": 49,
      "./ko/build_format_locale/": 49,
      "./ko/build_format_locale/index": 49,
      "./ko/build_format_locale/index.js": 49,
      "./ko/index": 100,
      "./ko/index.js": 100,
      "./ko/package": 180,
      "./ko/package.json": 180,
      "./mk": 101,
      "./mk/": 101,
      "./mk/build_distance_in_words_locale": 50,
      "./mk/build_distance_in_words_locale/": 50,
      "./mk/build_distance_in_words_locale/index": 50,
      "./mk/build_distance_in_words_locale/index.js": 50,
      "./mk/build_format_locale": 51,
      "./mk/build_format_locale/": 51,
      "./mk/build_format_locale/index": 51,
      "./mk/build_format_locale/index.js": 51,
      "./mk/index": 101,
      "./mk/index.js": 101,
      "./mk/package": 181,
      "./mk/package.json": 181,
      "./nb": 102,
      "./nb/": 102,
      "./nb/build_distance_in_words_locale": 52,
      "./nb/build_distance_in_words_locale/": 52,
      "./nb/build_distance_in_words_locale/index": 52,
      "./nb/build_distance_in_words_locale/index.js": 52,
      "./nb/build_format_locale": 53,
      "./nb/build_format_locale/": 53,
      "./nb/build_format_locale/index": 53,
      "./nb/build_format_locale/index.js": 53,
      "./nb/index": 102,
      "./nb/index.js": 102,
      "./nb/package": 182,
      "./nb/package.json": 182,
      "./nl": 103,
      "./nl/": 103,
      "./nl/build_distance_in_words_locale": 54,
      "./nl/build_distance_in_words_locale/": 54,
      "./nl/build_distance_in_words_locale/index": 54,
      "./nl/build_distance_in_words_locale/index.js": 54,
      "./nl/build_format_locale": 55,
      "./nl/build_format_locale/": 55,
      "./nl/build_format_locale/index": 55,
      "./nl/build_format_locale/index.js": 55,
      "./nl/index": 103,
      "./nl/index.js": 103,
      "./nl/package": 183,
      "./nl/package.json": 183,
      "./package": 184,
      "./package.json": 184,
      "./pl": 104,
      "./pl/": 104,
      "./pl/build_distance_in_words_locale": 56,
      "./pl/build_distance_in_words_locale/": 56,
      "./pl/build_distance_in_words_locale/index": 56,
      "./pl/build_distance_in_words_locale/index.js": 56,
      "./pl/build_format_locale": 57,
      "./pl/build_format_locale/": 57,
      "./pl/build_format_locale/index": 57,
      "./pl/build_format_locale/index.js": 57,
      "./pl/index": 104,
      "./pl/index.js": 104,
      "./pl/package": 185,
      "./pl/package.json": 185,
      "./pt": 105,
      "./pt/": 105,
      "./pt/build_distance_in_words_locale": 58,
      "./pt/build_distance_in_words_locale/": 58,
      "./pt/build_distance_in_words_locale/index": 58,
      "./pt/build_distance_in_words_locale/index.js": 58,
      "./pt/build_format_locale": 59,
      "./pt/build_format_locale/": 59,
      "./pt/build_format_locale/index": 59,
      "./pt/build_format_locale/index.js": 59,
      "./pt/index": 105,
      "./pt/index.js": 105,
      "./pt/package": 186,
      "./pt/package.json": 186,
      "./ro": 106,
      "./ro/": 106,
      "./ro/build_distance_in_words_locale": 60,
      "./ro/build_distance_in_words_locale/": 60,
      "./ro/build_distance_in_words_locale/index": 60,
      "./ro/build_distance_in_words_locale/index.js": 60,
      "./ro/build_format_locale": 61,
      "./ro/build_format_locale/": 61,
      "./ro/build_format_locale/index": 61,
      "./ro/build_format_locale/index.js": 61,
      "./ro/index": 106,
      "./ro/index.js": 106,
      "./ro/package": 187,
      "./ro/package.json": 187,
      "./ru": 107,
      "./ru/": 107,
      "./ru/build_distance_in_words_locale": 62,
      "./ru/build_distance_in_words_locale/": 62,
      "./ru/build_distance_in_words_locale/index": 62,
      "./ru/build_distance_in_words_locale/index.js": 62,
      "./ru/build_format_locale": 63,
      "./ru/build_format_locale/": 63,
      "./ru/build_format_locale/index": 63,
      "./ru/build_format_locale/index.js": 63,
      "./ru/index": 107,
      "./ru/index.js": 107,
      "./ru/package": 188,
      "./ru/package.json": 188,
      "./sk": 108,
      "./sk/": 108,
      "./sk/build_distance_in_words_locale": 64,
      "./sk/build_distance_in_words_locale/": 64,
      "./sk/build_distance_in_words_locale/index": 64,
      "./sk/build_distance_in_words_locale/index.js": 64,
      "./sk/build_format_locale": 65,
      "./sk/build_format_locale/": 65,
      "./sk/build_format_locale/index": 65,
      "./sk/build_format_locale/index.js": 65,
      "./sk/index": 108,
      "./sk/index.js": 108,
      "./sk/package": 189,
      "./sk/package.json": 189,
      "./sl": 109,
      "./sl/": 109,
      "./sl/build_distance_in_words_locale": 66,
      "./sl/build_distance_in_words_locale/": 66,
      "./sl/build_distance_in_words_locale/index": 66,
      "./sl/build_distance_in_words_locale/index.js": 66,
      "./sl/build_format_locale": 67,
      "./sl/build_format_locale/": 67,
      "./sl/build_format_locale/index": 67,
      "./sl/build_format_locale/index.js": 67,
      "./sl/index": 109,
      "./sl/index.js": 109,
      "./sl/package": 190,
      "./sl/package.json": 190,
      "./sv": 110,
      "./sv/": 110,
      "./sv/build_distance_in_words_locale": 68,
      "./sv/build_distance_in_words_locale/": 68,
      "./sv/build_distance_in_words_locale/index": 68,
      "./sv/build_distance_in_words_locale/index.js": 68,
      "./sv/build_format_locale": 69,
      "./sv/build_format_locale/": 69,
      "./sv/build_format_locale/index": 69,
      "./sv/build_format_locale/index.js": 69,
      "./sv/index": 110,
      "./sv/index.js": 110,
      "./sv/package": 191,
      "./sv/package.json": 191,
      "./th": 111,
      "./th/": 111,
      "./th/build_distance_in_words_locale": 70,
      "./th/build_distance_in_words_locale/": 70,
      "./th/build_distance_in_words_locale/index": 70,
      "./th/build_distance_in_words_locale/index.js": 70,
      "./th/build_format_locale": 71,
      "./th/build_format_locale/": 71,
      "./th/build_format_locale/index": 71,
      "./th/build_format_locale/index.js": 71,
      "./th/index": 111,
      "./th/index.js": 111,
      "./th/package": 192,
      "./th/package.json": 192,
      "./tr": 112,
      "./tr/": 112,
      "./tr/build_distance_in_words_locale": 72,
      "./tr/build_distance_in_words_locale/": 72,
      "./tr/build_distance_in_words_locale/index": 72,
      "./tr/build_distance_in_words_locale/index.js": 72,
      "./tr/build_format_locale": 73,
      "./tr/build_format_locale/": 73,
      "./tr/build_format_locale/index": 73,
      "./tr/build_format_locale/index.js": 73,
      "./tr/index": 112,
      "./tr/index.js": 112,
      "./tr/package": 193,
      "./tr/package.json": 193,
      "./zh_cn": 113,
      "./zh_cn/": 113,
      "./zh_cn/build_distance_in_words_locale": 74,
      "./zh_cn/build_distance_in_words_locale/": 74,
      "./zh_cn/build_distance_in_words_locale/index": 74,
      "./zh_cn/build_distance_in_words_locale/index.js": 74,
      "./zh_cn/build_format_locale": 75,
      "./zh_cn/build_format_locale/": 75,
      "./zh_cn/build_format_locale/index": 75,
      "./zh_cn/build_format_locale/index.js": 75,
      "./zh_cn/index": 113,
      "./zh_cn/index.js": 113,
      "./zh_cn/package": 194,
      "./zh_cn/package.json": 194,
      "./zh_tw": 114,
      "./zh_tw/": 114,
      "./zh_tw/build_distance_in_words_locale": 76,
      "./zh_tw/build_distance_in_words_locale/": 76,
      "./zh_tw/build_distance_in_words_locale/index": 76,
      "./zh_tw/build_distance_in_words_locale/index.js": 76,
      "./zh_tw/build_format_locale": 77,
      "./zh_tw/build_format_locale/": 77,
      "./zh_tw/build_format_locale/index": 77,
      "./zh_tw/build_format_locale/index.js": 77,
      "./zh_tw/index": 114,
      "./zh_tw/index.js": 114,
      "./zh_tw/package": 195,
      "./zh_tw/package.json": 195
    };

    function r(e) {
      return n(i(e));
    }

    function i(e) {
      var t = o[e];
      if (!(t + 1)) throw new Error("Cannot find module '" + e + "'.");
      return t;
    }

    r.keys = function () {
      return Object.keys(o);
    }, r.resolve = i, (e.exports = r).id = 304;
  }, function (e, t, n) {
    "use strict";

    var o = n(306),
        i = n(307),
        a = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }

      return e;
    },
        s = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var u = "carousel:ready",
        c = "carousel:slide:before",
        l = "carousel:slide:after",
        d = Symbol("onSwipeStart"),
        f = Symbol("onSwipeMove"),
        h = Symbol("onSwipeEnd"),
        m = !1;

    try {
      var r = Object.defineProperty({}, "passive", {
        get: function () {
          m = !0;
        }
      });
      window.addEventListener("testPassive", null, r), window.removeEventListener("testPassive", null, r);
    } catch (e) {}

    var p = function (e) {
      function r(e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, r);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (r.__proto__ || Object.getPrototypeOf(r)).call(this || _global));

        if (n.element = "string" == typeof e ? document.querySelector(e) : e, !n.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return n._clickEvents = ["click"], n.options = a({}, i.a, t), n.element.dataset.autoplay && (n.options.autoplay = n.element.dataset.autoplay), n.element.dataset.delay && (n.options.delay = n.element.dataset.delay), n.element.dataset.size && !n.element.classList.contains("carousel-animate-fade") && (n.options.size = n.element.dataset.size), n.element.classList.contains("carousel-animate-fade") && (n.options.size = 1), n.forceHiddenNavigation = !1, n[d] = n[d].bind(n), n[f] = n[f].bind(n), n[h] = n[h].bind(n), n.init(), n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(r, o["a"]), s(r, [{
        key: "init",
        value: function () {
          (this || _global).container = (this || _global).element.querySelector(".carousel-container"), (this || _global).items = (this || _global).element.querySelectorAll(".carousel-item"), (this || _global).currentItem = {
            element: (this || _global).element,
            node: (this || _global).element.querySelector(".carousel-item.is-active"),
            pos: -1
          }, (this || _global).currentItem.pos = (this || _global).currentItem.node ? Array.from((this || _global).items).indexOf((this || _global).currentItem.node) : -1, (this || _global).currentItem.node || ((this || _global).currentItem.node = (this || _global).items[0], (this || _global).currentItem.node.classList.add("is-active"), (this || _global).currentItem.pos = 0), (this || _global).forceHiddenNavigation = (this || _global).items.length <= 1;

          var e = (this || _global).element.querySelectorAll("img");

          [].forEach.call(e, function (e) {
            e.setAttribute("draggable", !1);
          }), this._resize(), this._setOrder(), this._initNavigation(), this._bindEvents(), (this || _global).options.autoplay && this._autoPlay((this || _global).options.delay), this.emit(u, (this || _global).currentItem);
        }
      }, {
        key: "_resize",
        value: function () {
          var t = this || _global,
              e = window.getComputedStyle((this || _global).element),
              n = parseInt(e.getPropertyValue("width"), 10);

          if (1 < (this || _global).options.size && ((this || _global).options.size >= Array.from((this || _global).items).length ? (this || _global).offset = 0 : (this || _global).offset = n / (this || _global).options.size, (this || _global).container.style.left = 0 - (this || _global).offset + "px", (this || _global).container.style.transform = "translateX(" + (this || _global).offset + "px)", [].forEach.call((this || _global).items, function (e) {
            e.style.flexBasis = t.offset + "px";
          })), (this || _global).element.classList.contains("carousel-animate-fade") && (this || _global).items.length) {
            var o = (this || _global).items[0].querySelector("img"),
                r = 1;

            o.naturalWidth ? (r = n / o.naturalWidth, (this || _global).container.style.height = o.naturalHeight * r + "px") : o.onload = function () {
              r = n / o.naturalWidth, t.container.style.height = o.naturalHeight * r + "px";
            };
          }
        }
      }, {
        key: "_bindEvents",
        value: function () {
          var t = this || _global;
          (this || _global).previousControl && (this || _global)._clickEvents.forEach(function (e) {
            t.previousControl.addEventListener(e, function (e) {
              m || e.preventDefault(), t._autoPlayInterval && (clearInterval(t._autoPlayInterval), t._autoPlay(t.optionsdelay)), t._slide("previous");
            }, !!m && {
              passive: !0
            });
          }), (this || _global).nextControl && (this || _global)._clickEvents.forEach(function (e) {
            t.nextControl.addEventListener(e, function (e) {
              m || e.preventDefault(), t._autoPlayInterval && (clearInterval(t._autoPlayInterval), t._autoPlay(t.options.delay)), t._slide("next");
            }, !!m && {
              passive: !0
            });
          }), (this || _global).element.addEventListener("touchstart", (this || _global)[d], !!m && {
            passive: !0
          }), (this || _global).element.addEventListener("mousedown", (this || _global)[d], !!m && {
            passive: !0
          }), (this || _global).element.addEventListener("touchmove", (this || _global)[f], !!m && {
            passive: !0
          }), (this || _global).element.addEventListener("mousemove", (this || _global)[f], !!m && {
            passive: !0
          }), (this || _global).element.addEventListener("touchend", (this || _global)[h], !!m && {
            passive: !0
          }), (this || _global).element.addEventListener("mouseup", (this || _global)[h], !!m && {
            passive: !0
          });
        }
      }, {
        key: "destroy",
        value: function () {
          (this || _global).element.removeEventListener("touchstart", (this || _global)[d], !!m && {
            passive: !0
          }), (this || _global).element.removeEventListener("mousedown", (this || _global)[d], !!m && {
            passive: !0
          }), (this || _global).element.removeEventListener("touchmove", (this || _global)[f], !!m && {
            passive: !0
          }), (this || _global).element.removeEventListener("mousemove", (this || _global)[f], !!m && {
            passive: !0
          }), (this || _global).element.removeEventListener("touchend", (this || _global)[h], !!m && {
            passive: !0
          }), (this || _global).element.removeEventListener("mouseup", (this || _global)[h], !!m && {
            passive: !0
          });
        }
      }, {
        key: d,
        value: function (e) {
          m || e.preventDefault(), e = "changedTouches" in (e = e || window.event) ? e.changedTouches[0] : e, (this || _global)._touch = {
            start: {
              time: new Date().getTime(),
              x: e.pageX,
              y: e.pageY
            },
            dist: {
              x: 0,
              y: 0
            }
          };
        }
      }, {
        key: f,
        value: function (e) {
          m || e.preventDefault();
        }
      }, {
        key: h,
        value: function (e) {
          m || e.preventDefault(), e = "changedTouches" in (e = e || window.event) ? e.changedTouches[0] : e, (this || _global)._touch.dist = {
            x: e.pageX - (this || _global)._touch.start.x,
            y: e.pageY - (this || _global)._touch.start.y
          }, this._handleGesture();
        }
      }, {
        key: "_handleGesture",
        value: function () {
          new Date().getTime() - (this || _global)._touch.start.time <= (this || _global).options.allowedTime && Math.abs((this || _global)._touch.dist.x) >= (this || _global).options.threshold && Math.abs((this || _global)._touch.dist.y) <= (this || _global).options.restraint && ((this || _global)._touch.dist.x < 0 ? this._slide("next") : this._slide("previous"));
        }
      }, {
        key: "_initNavigation",
        value: function () {
          (this || _global).previousControl = (this || _global).element.querySelector(".carousel-nav-left"), (this || _global).nextControl = (this || _global).element.querySelector(".carousel-nav-right"), ((this || _global).items.length <= 1 || (this || _global).forceHiddenNavigation) && ((this || _global).container && ((this || _global).container.style.left = "0"), (this || _global).previousControl && ((this || _global).previousControl.style.display = "none"), (this || _global).nextControl && ((this || _global).nextControl.style.display = "none"));
        }
      }, {
        key: "_setOrder",
        value: function () {
          (this || _global).currentItem.node.style.order = "1", (this || _global).currentItem.node.style.zIndex = "1";
          var e,
              t = (this || _global).currentItem.node,
              n = void 0,
              o = void 0;

          for (n = o = 2, e = Array.from((this || _global).items).length; 2 <= e ? o <= e : e <= o; n = 2 <= e ? ++o : --o) (t = this._next(t)).style.order = "" + n % Array.from((this || _global).items).length, t.style.zIndex = "0";
        }
      }, {
        key: "_next",
        value: function (e) {
          return e.nextElementSibling ? e.nextElementSibling : (this || _global).items[0];
        }
      }, {
        key: "_previous",
        value: function (e) {
          return e.previousElementSibling ? e.previousElementSibling : (this || _global).items[(this || _global).items.length - 1];
        }
      }, {
        key: "_slide",
        value: function () {
          var e = this || _global,
              t = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "next";
          (this || _global).items.length && ((this || _global).oldItemNode = (this || _global).currentItem.node, this.emit(c, (this || _global).currentItem), "previous" === t ? ((this || _global).currentItem.node = this._previous((this || _global).currentItem.node), (this || _global).element.classList.contains("carousel-animate-fade") || ((this || _global).element.classList.add("is-reversing"), (this || _global).container.style.transform = "translateX(" + -Math.abs((this || _global).offset) + "px)")) : ((this || _global).currentItem.node = this._next((this || _global).currentItem.node), (this || _global).element.classList.remove("is-reversing"), (this || _global).container.style.transform = "translateX(" + Math.abs((this || _global).offset) + "px)"), (this || _global).currentItem.node.classList.add("is-active"), (this || _global).oldItemNode.classList.remove("is-active"), (this || _global).element.classList.remove("carousel-animated"), setTimeout(function () {
            e.element.classList.add("carousel-animated");
          }, 50), this._setOrder(), this.emit(l, (this || _global).currentItem));
        }
      }, {
        key: "_autoPlay",
        value: function () {
          var e = this || _global,
              t = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : 5000;
          (this || _global)._autoPlayInterval = setInterval(function () {
            e._slide("next");
          }, t);
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : ".carousel, .hero-carousel",
              t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
              n = new Array(),
              o = document.querySelectorAll(e);
          return [].forEach.call(o, function (e) {
            setTimeout(function () {
              n.push(new r(e, t));
            }, 100);
          }), n;
        }
      }]), r;
    }();

    t.a = p;
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    t.a = {
      size: 1,
      autoplay: !1,
      delay: 5000,
      threshold: 50,
      restraint: 100,
      allowedTime: 500
    };
  }, function (e, t, n) {
    "use strict";

    var o = n(309),
        i = n(310),
        c = function (e, t) {
      if (Array.isArray(e)) return e;
      if (Symbol.iterator in Object(e)) return function (e, t) {
        var n = [],
            o = !0,
            r = !1,
            i = void 0;

        try {
          for (var a, s = e[Symbol.iterator](); !(o = (a = s.next()).done) && (n.push(a.value), !t || n.length !== t); o = !0);
        } catch (e) {
          r = !0, i = e;
        } finally {
          try {
            !o && s.return && s.return();
          } finally {
            if (r) throw i;
          }
        }

        return n;
      }(e, t);
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    },
        a = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }

      return e;
    },
        s = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function (e) {
      function r(e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, r);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (r.__proto__ || Object.getPrototypeOf(r)).call(this || _global));

        if (n.element = "string" == typeof e ? document.querySelector(e) : e, !n.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return n._clickEvents = ["click"], n.options = a({}, i.a, t), n.icons = [], n.id = "iconPicker" + new Date().getTime(), n.init(), n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(r, o["a"]), s(r, [{
        key: "init",
        value: function () {
          var r = this || _global;
          this.createModal(), this.createPreview(), (this || _global).options.iconSets.forEach(function (n) {
            var o;
            o = n.css, new Promise(function (e, t) {
              var n = document.createElement("link");
              n.type = "text/css", n.rel = "stylesheet", n.onload = function () {
                e();
              }, n.href = o, document.querySelector("link[href=\"" + o + "\"]") || document.querySelector("head").append(n);
            }), fetch(n.css, {
              mode: "cors"
            }).then(function (e) {
              return e.text();
            }).then(function (e) {
              r.icons[n.name] = r.parseCSS(e, n.prefix || "fa-", n.displayPrefix || ""), r.modalSetTabs.querySelector("a").click();
              var t = new Event("touchstart");
              r.modalSetTabs.querySelector("a").dispatchEvent(t);
            });
          });
        }
      }, {
        key: "createPreview",
        value: function () {
          var t = this || _global;
          (this || _global).preview = document.createElement("div"), (this || _global).preview.className = "icon is-large", (this || _global).preview.classList.add("iconpicker-preview");
          var n = document.createElement("i");
          (n.className = "iconpicker-icon-preview", (this || _global).element.value.length) && (this || _global).element.value.split(" ").forEach(function (e) {
            n.classList.add(e);
          });
          (this || _global).preview.appendChild(n), (this || _global)._clickEvents.forEach(function (e) {
            t.preview.addEventListener(e, function (e) {
              e.preventDefault(), t.modal.classList.add("is-active");
            });
          }), (this || _global).element.parentNode.insertBefore((this || _global).preview, (this || _global).element.nextSibling);
        }
      }, {
        key: "parseCSS",
        value: function (e) {
          for (var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : "fa-", n = 2 < arguments.length && void 0 !== arguments[2] ? arguments[2] : "", o = new RegExp("\\." + t + "([^\\.!:]*)::?before\\s*{\\s*content:\\s*[\"|']\\\\[^'|\"]*[\"|'];?\\s*}", "g"), r = [], i = void 0, a = void 0; a = o.exec(e);) i = {
            prefix: t,
            selector: t + a[1].trim(":"),
            name: this.ucwords(a[1]).trim(":"),
            filter: a[1].trim(":"),
            displayPrefix: n
          }, r[a[1]] = i;

          return 0 == Object.getOwnPropertyNames((this || _global).icons).length && console.warn("No icons found in CSS file"), r;
        }
      }, {
        key: "ucwords",
        value: function (e) {
          return (e + "").replace(/^(.)|\s+(.)/g, function (e) {
            return e.toUpperCase();
          });
        }
      }, {
        key: "drawIcons",
        value: function (e) {
          if ((this || _global).iconsList.innerHTML = "", e) {
            var t = !0,
                n = !1,
                o = void 0;

            try {
              for (var r, i = Object.entries(e)[Symbol.iterator](); !(t = (r = i.next()).done); t = !0) {
                var a = r.value,
                    s = c(a, 2),
                    u = (s[0], s[1]);

                (this || _global).iconsList.appendChild(this.createIconPreview(u));
              }
            } catch (e) {
              n = !0, o = e;
            } finally {
              try {
                !t && i.return && i.return();
              } finally {
                if (n) throw o;
              }
            }
          }
        }
      }, {
        key: "createIconPreview",
        value: function (e) {
          var t = this || _global,
              n = (1 < arguments.length && void 0 !== arguments[1] && arguments[1], document.createElement("a"));
          n.dataset.title = e.name, n.setAttribute("title", e.name), n.dataset.icon = e.selector, n.dataset.filter = e.filter;
          var o = document.createElement("i");
          return o.className = "iconpicker-icon-preview", e.displayPrefix.length && e.displayPrefix.split(" ").forEach(function (e) {
            o.classList.add(e);
          }), o.classList.add(e.selector), n.appendChild(o), (this || _global)._clickEvents.forEach(function (e) {
            n.addEventListener(e, function (e) {
              e.preventDefault(), t.preview.innerHTML = "", t.element.value = e.target.classList, t.element.dispatchEvent(new Event("change")), t.preview.appendChild(e.target.cloneNode(!0)), t.modal.classList.remove("is-active");
            });
          }), n;
        }
      }, {
        key: "createModal",
        value: function () {
          var o = this || _global;
          (this || _global).modal = document.createElement("div"), (this || _global).modal.className = "modal", (this || _global).modal.classList.add("iconpicker-modal"), (this || _global).modal.id = (this || _global).id;
          var e = document.createElement("div");
          e.className = "modal-background";
          var t = document.createElement("div");
          t.className = "modal-card";
          var n = document.createElement("header");
          n.className = "modal-card-head";
          var r = document.createElement("p");
          r.className = "modal-card-title", r.innerHTML = "iconPicker", (this || _global).modalHeaderSearch = document.createElement("input"), (this || _global).modalHeaderSearch.setAttribute("type", "search"), (this || _global).modalHeaderSearch.setAttribute("placeholder", "Search"), (this || _global).modalHeaderSearch.className = "iconpicker-search", (this || _global).modalHeaderSearch.addEventListener("input", function (e) {
            o.filter(e.target.value);
          });
          var i = document.createElement("button");

          if (i.className = "delete", (this || _global)._clickEvents.forEach(function (e) {
            i.addEventListener(e, function (e) {
              e.preventDefault(), o.modal.classList.remove("is-active");
            });
          }), t.appendChild(n), (this || _global).modalBody = document.createElement("section"), (this || _global).modalBody.className = "modal-card-body", 1 <= (this || _global).options.iconSets.length) {
            var a = document.createElement("div");
            a.className = "iconpicker-sets", a.classList.add("tabs"), (this || _global).modalSetTabs = document.createElement("ul"), (this || _global).options.iconSets.forEach(function (e) {
              var t = document.createElement("li"),
                  n = document.createElement("a");
              n.dataset.iconset = e.name, n.innerHTML = e.name, o._clickEvents.forEach(function (e) {
                n.addEventListener(e, function (e) {
                  e.preventDefault();
                  var t = o.modalSetTabs.querySelectorAll(".is-active");
                  [].forEach.call(t, function (e) {
                    e.classList.remove("is-active");
                  }), e.target.parentNode.classList.add("is-active"), o.drawIcons(o.icons[e.target.dataset.iconset]), o.filter(o.modalHeaderSearch.value);
                });
              }), t.appendChild(n), o.modalSetTabs.appendChild(t);
            }), a.appendChild((this || _global).modalSetTabs), t.appendChild(a);
          }

          (this || _global).iconsList = document.createElement("div"), (this || _global).iconsList.className = "iconpicker-icons", n.appendChild(r), n.appendChild((this || _global).modalHeaderSearch), n.appendChild(i), (this || _global).modalBody.appendChild((this || _global).iconsList), t.appendChild((this || _global).modalBody), (this || _global).modal.appendChild(e), (this || _global).modal.appendChild(t), document.body.appendChild((this || _global).modal);
        }
      }, {
        key: "filter",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "";
          "" !== e ? ((this || _global).iconsList.querySelectorAll("[data-filter]").forEach(function (e) {
            e.classList.remove("is-hidden");
          }), (this || _global).iconsList.querySelectorAll("[data-filter]:not([data-filter*=\"" + e + "\"])").forEach(function (e) {
            e.classList.add("is-hidden");
          })) : (this || _global).iconsList.querySelectorAll("[data-filter]").forEach(function (e) {
            e.classList.remove("is-hidden");
          });
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "[data-action=\"iconPicker\"]",
              t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
              n = new Array(),
              o = document.querySelectorAll(e);
          return [].forEach.call(o, function (e) {
            setTimeout(function () {
              n.push(new r(e, t));
            }, 100);
          }), n;
        }
      }]), r;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    t.a = {
      iconSets: [{
        name: "simpleLine",
        css: "https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.css",
        prefix: "icon-",
        displayPrefix: ""
      }, {
        name: "fontAwesome",
        css: "https://use.fontawesome.com/releases/v5.0.13/css/all.css",
        prefix: "fa-",
        displayPrefix: "fas fa-icon"
      }]
    };
  }, function (e, t, n) {
    "use strict";

    var o = n(312),
        i = n(313),
        a = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }

      return e;
    },
        s = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var u = Symbol("onQuickviewShowClick"),
        c = Symbol("onQuickviewDismissClick"),
        r = function (e) {
      function r(e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, r);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (r.__proto__ || Object.getPrototypeOf(r)).call(this || _global));

        if (n.element = "string" == typeof e ? document.querySelector(e) : e, !n.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return n._clickEvents = ["click"], n.options = a({}, i.a, t), n[u] = n[u].bind(n), n[c] = n[c].bind(n), n.init(), n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(r, o["a"]), s(r, [{
        key: "init",
        value: function () {
          (this || _global).quickview = document.getElementById((this || _global).element.dataset.target), (this || _global).dismissElements = document.querySelectorAll("[data-dismiss=\"quickview\"]"), this._bindEvents(), this.emit("quickview:ready", {
            element: (this || _global).element,
            quickview: (this || _global).quickview
          });
        }
      }, {
        key: "_bindEvents",
        value: function () {
          var n = this || _global;
          (this || _global)._clickEvents.forEach(function (e) {
            n.element.addEventListener(e, n[u], !1);
          }), [].forEach.call((this || _global).dismissElements, function (t) {
            n._clickEvents.forEach(function (e) {
              t.addEventListener(e, n[c], !1);
            });
          });
        }
      }, {
        key: u,
        value: function (e) {
          (this || _global).quickview.classList.add("is-active"), this.emit("quickview:show", {
            element: (this || _global).element,
            quickview: (this || _global).quickview
          });
        }
      }, {
        key: c,
        value: function (e) {
          (this || _global).quickview.classList.remove("is-active"), this.emit("quickview:hide", {
            element: (this || _global).element,
            quickview: (this || _global).quickview
          });
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "[data-show=\"quickview\"]",
              t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
              n = new Array(),
              o = document.querySelectorAll(e);
          return [].forEach.call(o, function (e) {
            setTimeout(function () {
              n.push(new r(e, t));
            }, 100);
          }), n;
        }
      }]), r;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    t.a = {};
  }, function (e, t, n) {
    "use strict";

    var o = n(315),
        i = n(316),
        a = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }

      return e;
    },
        s = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var u = Symbol("onSliderInput"),
        r = function (e) {
      function r(e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, r);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (r.__proto__ || Object.getPrototypeOf(r)).call(this || _global));

        if (n.element = "string" == typeof e ? document.querySelector(e) : e, !n.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return n._clickEvents = ["click"], n.options = a({}, i.a, t), n[u] = n[u].bind(n), n.init(), n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(r, o["a"]), s(r, [{
        key: "init",
        value: function () {
          if ((this || _global)._id = "bulmaSlider" + new Date().getTime() + Math.floor(Math.random() * Math.floor(9999)), (this || _global).output = this._findOutputForSlider(), (this || _global).output && (this || _global).element.classList.contains("has-output-tooltip")) {
            var e = this._getSliderOutputPosition();

            (this || _global).output.style.left = e.position;
          }

          this.emit("bulmaslider:ready", (this || _global).element.value);
        }
      }, {
        key: "_findOutputForSlider",
        value: function () {
          var t = this || _global,
              e = document.getElementsByTagName("output");
          return [].forEach.call(e, function (e) {
            if (e.htmlFor == t.element.getAttribute("id")) return e;
          }), null;
        }
      }, {
        key: "_getSliderOutputPosition",
        value: function () {
          var e,
              t = window.getComputedStyle((this || _global).element, null),
              n = parseInt(t.getPropertyValue("width"), 10);
          e = (this || _global).element.getAttribute("min") ? (this || _global).element.getAttribute("min") : 0;
          var o = ((this || _global).element.value - e) / ((this || _global).element.getAttribute("max") - e);
          return {
            position: (o < 0 ? 0 : 1 < o ? n : n * o) + "px"
          };
        }
      }, {
        key: "_bindEvents",
        value: function () {
          (this || _global).output && (this || _global).element.addEventListener("input", (this || _global)[u], !1);
        }
      }, {
        key: u,
        value: function (e) {
          if (e.preventDefault(), (this || _global).element.classList.contains("has-output-tooltip")) {
            var t = this._getSliderOutputPosition();

            (this || _global).output.style.left = t.position;
          }

          var n = (this || _global).output.hasAttribute("data-prefix") ? (this || _global).output.getAttribute("data-prefix") : "",
              o = (this || _global).output.hasAttribute("data-postfix") ? (this || _global).output.getAttribute("data-postfix") : "";
          (this || _global).output.value = n + (this || _global).element.value + o, this.emit("bulmaslider:ready", (this || _global).element.value);
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "input[type=\"range\"].slider",
              t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
              n = new Array(),
              o = document.querySelectorAll(e);
          return [].forEach.call(o, function (e) {
            setTimeout(function () {
              n.push(new r(e, t));
            }, 100);
          }), n;
        }
      }]), r;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    t.a = {};
  }, function (e, t, n) {
    "use strict";

    var o = n(318),
        i = n(319),
        a = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }

      return e;
    },
        s = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var u = Symbol("onStepsPrevious"),
        c = Symbol("onStepsNext"),
        r = function (e) {
      function r(e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, r);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (r.__proto__ || Object.getPrototypeOf(r)).call(this || _global));

        if (n.element = "string" == typeof e ? document.querySelector(e) : e, !n.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return n._clickEvents = ["click"], n.options = a({}, i.a, t), n[u] = n[u].bind(n), n[c] = n[c].bind(n), n.init(), n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(r, o["a"]), s(r, [{
        key: "init",
        value: function () {
          (this || _global)._id = "bulmaSteps" + new Date().getTime() + Math.floor(Math.random() * Math.floor(9999)), (this || _global).steps = (this || _global).element.querySelectorAll((this || _global).options.selector), (this || _global).contents = (this || _global).element.querySelectorAll((this || _global).options.selector_content), (this || _global).previous_btn = (this || _global).element.querySelector((this || _global).options.previous_selector), (this || _global).next_btn = (this || _global).element.querySelector((this || _global).options.next_selector), [].forEach.call((this || _global).steps, function (e, t) {
            e.setAttribute("data-step-id", t);
          }), (this || _global).steps && (this || _global).steps.length && (this.activate_step(0), this.updateActions((this || _global).steps[0])), this._bindEvents(), this.emit("bulmasteps:ready", (this || _global).element.value);
        }
      }, {
        key: "_bindEvents",
        value: function () {
          var n = this || _global;
          null != (this || _global).previous_btn && (this || _global)._clickEvents.forEach(function (e) {
            n.previous_btn.addEventListener(e, n[u], !1);
          }), null != (this || _global).next_btn && (this || _global)._clickEvents.forEach(function (e) {
            n.next_btn.addEventListener(e, n[c], !1);
          }), (this || _global).options.stepClickable && [].forEach.call((this || _global).steps, function (e, t) {
            n._clickEvents.forEach(function (e) {
              for (; t > n.current_id;) n[c](e);

              for (; t < n.current_id;) n[u](e);
            });
          });
        }
      }, {
        key: u,
        value: function (e) {
          e.preventDefault(), e.target.getAttribute("disabled") || this.previous_step();
        }
      }, {
        key: c,
        value: function (e) {
          e.preventDefault(), e.target.getAttribute("disabled") || this.next_step();
        }
      }, {
        key: "get_current_step_id",
        value: function () {
          for (var e = 0; e < (this || _global).steps.length; e++) {
            var t = (this || _global).steps[e];
            if (t.classList.contains((this || _global).options.active_class)) return parseInt(t.getAttribute("data-step-id"));
          }

          return null;
        }
      }, {
        key: "updateActions",
        value: function (e) {
          var t = parseInt(e.getAttribute("data-step-id"));
          0 == t ? (null != (this || _global).previous_btn && (this || _global).previous_btn.setAttribute("disabled", "disabled"), null != (this || _global).next_btn && (this || _global).next_btn.removeAttribute("disabled", "disabled")) : t == (this || _global).steps.length - 1 ? (null != (this || _global).previous_btn && (this || _global).previous_btn.removeAttribute("disabled", "disabled"), null != (this || _global).next_btn && (this || _global).next_btn.setAttribute("disabled", "disabled")) : (null != (this || _global).previous_btn && (this || _global).previous_btn.removeAttribute("disabled", "disabled"), null != (this || _global).next_btn && (this || _global).next_btn.removeAttribute("disabled", "disabled"));
        }
      }, {
        key: "next_step",
        value: function () {
          var e = this.get_current_step_id();

          if (null != e) {
            var t = e + 1,
                n = [];

            if (void 0 !== (this || _global).options.beforeNext && null != (this || _global).options.beforeNext && (this || _global).options.beforeNext && (n = (this || _global).options.beforeNext(e)), this.emit("bulmasteps:before:next", e), void 0 === n && (n = []), 0 < n.length) {
              this.emit("bulmasteps:errors", n);

              for (var o = 0; o < n.length; o++) void 0 !== (this || _global).options.onError && null != (this || _global).options.onError && (this || _global).options.onError && (this || _global).options.onError(n[o]);
            } else t >= (this || _global).steps.length - 1 && (void 0 !== (this || _global).options.onFinish && null != (this || _global).options.onFinish && (this || _global).options.onFinish && (this || _global).options.onFinish(e), this.emit("bulmasteps:finish", e)), t < (this || _global).steps.length && (this.complete_step(e), this.activate_step(t));
          }
        }
      }, {
        key: "previous_step",
        value: function () {
          var e = this.get_current_step_id();
          null != e && (this.uncomplete_step(e - 1), this.activate_step(e - 1));
        }
      }, {
        key: "activate_step",
        value: function (e) {
          this.updateActions((this || _global).steps[e]);

          for (var t = 0; t < (this || _global).steps.length; t++) {
            (this || _global).steps[t] != (this || _global).steps[e] && this.deactivate_step(t);
          }

          (this || _global).steps[e].classList.add((this || _global).options.active_class), void 0 !== (this || _global).contents[e] && (this || _global).contents[e].classList.add((this || _global).options.active_class), void 0 !== (this || _global).options.onShow && null != (this || _global).options.onShow && (this || _global).options.onShow && (this || _global).options.onShow(e), this.emit("bulmasteps:step:show", e);
        }
      }, {
        key: "complete_step",
        value: function (e) {
          (this || _global).steps[e].classList.add((this || _global).options.completed_class), this.emit("bulmasteps:step:completed", e);
        }
      }, {
        key: "uncomplete_step",
        value: function (e) {
          (this || _global).steps[e].classList.remove((this || _global).options.completed_class), this.emit("bulmasteps:step:uncompleted", e);
        }
      }, {
        key: "deactivate_step",
        value: function (e) {
          (this || _global).steps[e].classList.remove((this || _global).options.active_class), void 0 !== (this || _global).contents[e] && (this || _global).contents[e].classList.remove((this || _global).options.active_class);
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : ".steps",
              t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
              n = new Array(),
              o = document.querySelectorAll(e);
          return [].forEach.call(o, function (e) {
            setTimeout(function () {
              n.push(new r(e, t));
            }, 100);
          }), n;
        }
      }]), r;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    t.a = {
      selector: ".step-item",
      selector_content: ".step-content",
      previous_selector: "[data-nav=\"previous\"]",
      next_selector: "[data-nav=\"next\"]",
      active_class: "is-active",
      completed_class: "is-completed",
      stepClickable: !1,
      beforeNext: null,
      onShow: null,
      onFinish: null,
      onError: null
    };
  }, function (e, t, n) {
    "use strict";

    var o = n(321),
        i = n(322),
        a = n(323),
        s = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }

      return e;
    },
        u = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function (e) {
      function r(e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, r);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (r.__proto__ || Object.getPrototypeOf(r)).call(this || _global));

        if (n.element = a.a(e) ? document.querySelector(e) : e, !n.element) throw new Error("An invalid selector or non-DOM node has been provided.");
        return n._clickEvents = ["click"], n.options = s({}, i.a, t), n.element.dataset.hasOwnProperty("lowercase") && (n.options.lowercase = n.element.dataset("lowercase")), n.element.dataset.hasOwnProperty("uppercase") && (n.options.lowercase = n.element.dataset("uppercase")), n.element.dataset.hasOwnProperty("duplicates") && (n.options.lowercase = n.element.dataset("duplicates")), n.init(), n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(r, o["a"]), u(r, [{
        key: "init",
        value: function () {
          if (!(this || _global).options.disabled) {
            (this || _global).tags = [], (this || _global).container = document.createElement("div"), (this || _global).container.className = "tagsinput", (this || _global).container.classList.add("field"), (this || _global).container.classList.add("is-grouped"), (this || _global).container.classList.add("is-grouped-multiline"), (this || _global).container.classList.add("input");

            var e = (this || _global).element.getAttribute("type");

            e && "tags" !== e || (e = "text"), (this || _global).input = document.createElement("input"), (this || _global).input.setAttribute("type", e), (this || _global).element.getAttribute("placeholder") ? (this || _global).input.setAttribute("placeholder", (this || _global).element.getAttribute("placeholder")) : (this || _global).input.setAttribute("placeholder", "Add a Tag"), (this || _global).container.appendChild((this || _global).input);
            var t = (this || _global).element.nextSibling;
            (this || _global).element.parentNode[t ? "insertBefore" : "appendChild"]((this || _global).container, t), (this || _global).element.style.cssText = "position:absolute;left:0;top:0;width:1px;height:1px;opacity:0.01;", (this || _global).element.tabIndex = -1, this.enable();
          }
        }
      }, {
        key: "enable",
        value: function () {
          var a = this || _global;
          (this || _global).enabled || (this || _global).options.disabled || ((this || _global).element.addEventListener("focus", function () {
            a.container.classList.add("is-focused"), a.select(Array.prototype.slice.call(a.container.querySelectorAll(".tag:not(.is-delete)")).pop());
          }), (this || _global).input.addEventListener("focus", function () {
            a.container.classList.add("is-focused"), a.select(Array.prototype.slice.call(a.container.querySelectorAll(".tag:not(.is-delete)")).pop());
          }), (this || _global).input.addEventListener("blur", function () {
            a.container.classList.remove("is-focused"), a.select(Array.prototype.slice.call(a.container.querySelectorAll(".tag:not(.is-delete)")).pop()), a.savePartial();
          }), (this || _global).input.addEventListener("keydown", function (e) {
            var t = e.charCode || e.keyCode || e.which,
                n = void 0,
                o = a.container.querySelector(".tag.is-active"),
                r = Array.prototype.slice.call(a.container.querySelectorAll(".tag:not(.is-delete)")).pop(),
                i = a.caretAtStart(a.input);

            if (o && (n = a.container.querySelector("[data-tag=\"" + o.innerHTML.trim() + "\"]")), a.setInputWidth(), 13 === t || t === a.options.delimiter.charCodeAt(0) || 188 === t || 9 === t) {
              if (!a.input.value && (t !== a.options.delimiter.charCodeAt(0) || 188 === t)) return;
              a.savePartial();
            } else if (46 === t && n) n.nextSibling ? a.select(n.nextSibling.querySelector(".tag")) : n.previousSibling && a.select(n.previousSibling.querySelector(".tag")), a.container.removeChild(n), a.tags.splice(a.tags.indexOf(n.getAttribute("data-tag")), 1), a.setInputWidth(), a.save();else if (8 === t) {
              if (n) n.previousSibling ? a.select(n.previousSibling.querySelector(".tag")) : n.nextSibling && a.select(n.nextSibling.querySelector(".tag")), a.container.removeChild(n), a.tags.splice(a.tags.indexOf(n.getAttribute("data-tag")), 1), a.setInputWidth(), a.save();else {
                if (!r || !i) return;
                a.select(r);
              }
            } else if (37 === t) {
              if (n) n.previousSibling && a.select(n.previousSibling.querySelector(".tag"));else {
                if (!i) return;
                a.select(r);
              }
            } else {
              if (39 !== t) return a.select();
              if (!n) return;
              a.select(n.nextSibling.querySelector(".tag"));
            }

            return e.preventDefault(), !1;
          }), (this || _global).input.addEventListener("input", function () {
            a.element.value = a.getValue(), a.element.dispatchEvent(new Event("input"));
          }), (this || _global).input.addEventListener("paste", function () {
            return setTimeout(savePartial, 0);
          }), (this || _global).container.addEventListener("mousedown", function (e) {
            a.refocus(e);
          }), (this || _global).container.addEventListener("touchstart", function (e) {
            a.refocus(e);
          }), this.savePartial((this || _global).element.value), (this || _global).enabled = !0);
        }
      }, {
        key: "disable",
        value: function () {
          (this || _global).enabled && !(this || _global).options.disabled && (this.reset(), (this || _global).enabled = !1);
        }
      }, {
        key: "select",
        value: function (e) {
          var t = (this || _global).container.querySelector(".is-active");

          t && t.classList.remove("is-active"), e && e.classList.add("is-active");
        }
      }, {
        key: "addTag",
        value: function (e) {
          var i = this || _global;
          if (~e.indexOf((this || _global).options.delimiter) && (e = e.split((this || _global).options.delimiter)), Array.isArray(e)) return e.forEach(function (e) {
            i.addTag(e);
          });
          var t = e && e.trim();
          if (!t) return !1;

          if ("true" == (this || _global).options.lowercase && (t = t.toLowerCase()), "true" == (this || _global).options.uppercase && (t = t.toUpperCase()), (this || _global).options.duplicates || -1 === (this || _global).tags.indexOf(t)) {
            (this || _global).tags.push(t);

            var n = document.createElement("div");
            n.className = "control", n.setAttribute("data-tag", t);
            var o = document.createElement("div");
            o.className = "tags", o.classList.add("has-addons");
            var r = document.createElement("span");

            if (r.className = "tag", r.classList.add("is-active"), this.select(r), r.innerHTML = t, o.appendChild(r), (this || _global).options.allowDelete) {
              var a = document.createElement("a");
              a.className = "tag", a.classList.add("is-delete"), (this || _global)._clickEvents.forEach(function (e) {
                a.addEventListener(e, function (e) {
                  var t = void 0,
                      n = e.target.parentNode,
                      o = Array.prototype.slice.call(i.container.querySelectorAll(".tag")).pop(),
                      r = i.caretAtStart(i.input);
                  if (n && (t = i.container.querySelector("[data-tag=\"" + n.innerText.trim() + "\"]")), t) i.select(t.previousSibling), i.container.removeChild(t), i.tags.splice(i.tags.indexOf(t.getAttribute("data-tag")), 1), i.setInputWidth(), i.save();else {
                    if (!o || !r) return;
                    i.select(o);
                  }
                });
              }), o.appendChild(a);
            }

            n.appendChild(o), (this || _global).container.insertBefore(n, (this || _global).input);
          }
        }
      }, {
        key: "getValue",
        value: function () {
          return (this || _global).tags.join((this || _global).options.delimiter);
        }
      }, {
        key: "setValue",
        value: function (e) {
          var t = this || _global;
          Array.prototype.slice.call((this || _global).container.querySelectorAll(".tag")).forEach(function (e) {
            t.tags.splice(t.tags.indexOf(e.innerHTML), 1), t.container.removeChild(e);
          }), this.savePartial(e);
        }
      }, {
        key: "setInputWidth",
        value: function () {
          var e = Array.prototype.slice.call((this || _global).container.querySelectorAll(".control")).pop();
          (this || _global).container.offsetWidth && ((this || _global).input.style.width = Math.max((this || _global).container.offsetWidth - (e ? e.offsetLeft + e.offsetWidth : 30) - 30, (this || _global).container.offsetWidth / 4) + "px");
        }
      }, {
        key: "savePartial",
        value: function (e) {
          "string" == typeof e || Array.isArray(e) || (e = (this || _global).input.value), !1 !== this.addTag(e) && ((this || _global).input.value = "", this.save(), this.setInputWidth());
        }
      }, {
        key: "save",
        value: function () {
          (this || _global).element.value = (this || _global).tags.join((this || _global).options.delimiter), (this || _global).element.dispatchEvent(new Event("change"));
        }
      }, {
        key: "caretAtStart",
        value: function (t) {
          try {
            return 0 === t.selectionStart && 0 === t.selectionEnd;
          } catch (e) {
            return "" === t.value;
          }
        }
      }, {
        key: "refocus",
        value: function (e) {
          return e.target.classList.contains("tag") && this.select(e.target), e.target === (this || _global).input ? this.select() : ((this || _global).input.focus(), e.preventDefault(), !1);
        }
      }, {
        key: "reset",
        value: function () {
          (this || _global).tags = [];
        }
      }, {
        key: "destroy",
        value: function () {
          this.disable(), this.reset(), (this || _global).element = null;
        }
      }], [{
        key: "attach",
        value: function () {
          var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : "input[type=\"tags\"]",
              t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
              n = new Array(),
              o = document.querySelectorAll(e);
          return [].forEach.call(o, function (e) {
            setTimeout(function () {
              n.push(new r(e, t));
            }, 100);
          }), n;
        }
      }]), r;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    var o = function () {
      function o(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }

      return function (e, t, n) {
        return t && o(e.prototype, t), n && o(e, n), e;
      };
    }();

    var r = function () {
      function t() {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : [];
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global)._listeners = new Map(e), (this || _global)._middlewares = new Map();
      }

      return o(t, [{
        key: "listenerCount",
        value: function (e) {
          return (this || _global)._listeners.has(e) ? (this || _global)._listeners.get(e).length : 0;
        }
      }, {
        key: "removeListeners",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null,
              n = 1 < arguments.length && void 0 !== arguments[1] && arguments[1];
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeListeners(e, n);
          }) : ((this || _global)._listeners.delete(e), n && this.removeMiddleware(e)) : (this || _global)._listeners = new Map();
        }
      }, {
        key: "middleware",
        value: function (e, t) {
          var n = this || _global;
          Array.isArray(e) ? name.forEach(function (e) {
            return n.middleware(e, t);
          }) : (Array.isArray((this || _global)._middlewares.get(e)) || (this || _global)._middlewares.set(e, []), (this || _global)._middlewares.get(e).push(t));
        }
      }, {
        key: "removeMiddleware",
        value: function () {
          var t = this || _global,
              e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
          null !== e ? Array.isArray(e) ? name.forEach(function (e) {
            return t.removeMiddleware(e);
          }) : (this || _global)._middlewares.delete(e) : (this || _global)._middlewares = new Map();
        }
      }, {
        key: "on",
        value: function (e, t) {
          var n = this || _global,
              o = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          if (Array.isArray(e)) e.forEach(function (e) {
            return n.on(e, t);
          });else {
            var r = (e = e.toString()).split(/,|, | /);
            1 < r.length ? r.forEach(function (e) {
              return n.on(e, t);
            }) : (Array.isArray((this || _global)._listeners.get(e)) || (this || _global)._listeners.set(e, []), (this || _global)._listeners.get(e).push({
              once: o,
              callback: t
            }));
          }
        }
      }, {
        key: "once",
        value: function (e, t) {
          this.on(e, t, !0);
        }
      }, {
        key: "emit",
        value: function (n, o) {
          var r = this || _global,
              i = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
          n = n.toString();

          var a = (this || _global)._listeners.get(n),
              s = null,
              u = 0,
              c = i;

          if (Array.isArray(a)) for (a.forEach(function (e, t) {
            i || (s = r._middlewares.get(n), Array.isArray(s) ? (s.forEach(function (e) {
              e(o, function () {
                var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : null;
                null !== e && (o = e), u++;
              }, n);
            }), u >= s.length && (c = !0)) : c = !0), c && (e.once && (a[t] = null), e.callback(o));
          }); -1 !== a.indexOf(null);) a.splice(a.indexOf(null), 1);
        }
      }]), t;
    }();

    t.a = r;
  }, function (e, t, n) {
    "use strict";

    t.a = {
      disabled: !1,
      delimiter: ",",
      allowDelete: !0,
      lowercase: !1,
      uppercase: !1,
      duplicates: !0
    };
  }, function (e, t, n) {
    "use strict";

    n.d(t, "a", function () {
      return r;
    });

    var o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    },
        r = function (e) {
      return "string" == typeof e || !!e && "object" === (void 0 === e ? "undefined" : o(e)) && "[object String]" === Object.prototype.toString.call(e);
    };
  }]).default;
});
export default exports;
export const bulmaExtensions = exports.bulmaExtensions;